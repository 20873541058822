// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // import React from "react";
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // import EntityManagement from "../../components/formTemplates/EntityManagement";

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // const ServiceProviders = () => {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   const entityName = "Service Provider";
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   const endpoints = {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     list: "/api/service_providers",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     create: "/api/service_providers",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     update: "/api/service_providers",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     delete: "/api/service_providers",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     dropdown: {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       vas: "/view_vas/v0.1",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       merchant: "/view_merchant/v0.1",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       unit_map: "/unit_bec_map_view/v0.1",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       status: "/view_status/v0.1",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       domain: "/view_domain/v0.1",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   };
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   const formFields = [
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       name: "service_provider_name",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       label: "Service Provider Name",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       type: "text",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       required: true,
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       name: "vas_id",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       label: "VAS",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       type: "select",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       required: true,
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       dropdownKey: "vas",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       name: "merchant_id",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       label: "Merchant",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       type: "select",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       required: true,
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       dropdownKey: "merchant",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       name: "unit_map_id",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       label: "Unit Map",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       type: "select",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       required: true,
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       dropdownKey: "unit_map",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       name: "status_id",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       label: "Status",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       type: "select",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       required: true,
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       dropdownKey: "status",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       name: "phone_extension",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       label: "Phone Extension",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       type: "text",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       required: true,
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     { name: "phone_no", label: "Phone No", type: "text", required: true },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     {
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       name: "domain_id",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       label: "Domain",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       type: "select",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       required: true,
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       dropdownKey: "domain",
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   ];
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   const tableColumns = [
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     { field: "service_provider_id", label: "ID" },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     { field: "service_provider_name", label: "Service Provider Name" },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     { field: "vas_name", label: "VAS" },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     { field: "merchant_name", label: "Merchant" },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     { field: "unit_map_name", label: "Unit Map" },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     { field: "status_name", label: "Status" },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     { field: "phone_extension", label: "Phone Extension" },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     { field: "phone_no", label: "Phone No" },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     { field: "workdays", label: "Workdays" },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     { field: "worktime_from", label: "Worktime From" },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     { field: "worktime_to", label: "Worktime To" },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     { field: "domain_name", label: "Domain" },
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   ];

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     <EntityManagement
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       entityName={entityName}
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       endpoints={endpoints}
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       formFields={formFields}
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       tableColumns={tableColumns}
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       showWorkdays={true}
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //       showWorktime={true}
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //     />
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // export default ServiceProviders;

// // // // // // // // // // // // // // // // // // // // // // // // // // // // working fine with local storage

// // // // // // // // // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // // // // // // // // import EntityManagement from "../../components/formTemplates/EntityManagement";
// // // // // // // // // // // // // // // // // // // // // // // // // import EntityTable from "../../components/templateComponents/EntityTable";
// // // // // // // // // // // // // // // // // // // // // // // // // import useDropdownFetch from "../../components/apiFetch/useDropdownFetch";

// // // // // // // // // // // // // // // // // // // // // // // // // const loadFromLocalStorage = (key) => {
// // // // // // // // // // // // // // // // // // // // // // // // //   const savedData = localStorage.getItem(key);
// // // // // // // // // // // // // // // // // // // // // // // // //   return savedData ? JSON.parse(savedData) : null;
// // // // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // // // const saveToLocalStorage = (key, data) => {
// // // // // // // // // // // // // // // // // // // // // // // // //   localStorage.setItem(key, JSON.stringify(data));
// // // // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // // // const ServiceProviders = () => {
// // // // // // // // // // // // // // // // // // // // // // // // //   const [formData, setFormData] = useState({
// // // // // // // // // // // // // // // // // // // // // // // // //     service_provider_name: "",
// // // // // // // // // // // // // // // // // // // // // // // // //     vas_id: "",
// // // // // // // // // // // // // // // // // // // // // // // // //     merchant_id: "",
// // // // // // // // // // // // // // // // // // // // // // // // //     unit_map_id: "",
// // // // // // // // // // // // // // // // // // // // // // // // //     status_id: "",
// // // // // // // // // // // // // // // // // // // // // // // // //     phone_extension: "",
// // // // // // // // // // // // // // // // // // // // // // // // //     phone_no: "",
// // // // // // // // // // // // // // // // // // // // // // // // //     domain_id: "",
// // // // // // // // // // // // // // // // // // // // // // // // //     worktime_from: "",
// // // // // // // // // // // // // // // // // // // // // // // // //     worktime_to: "",
// // // // // // // // // // // // // // // // // // // // // // // // //     workdays: [],
// // // // // // // // // // // // // // // // // // // // // // // // //   });

// // // // // // // // // // // // // // // // // // // // // // // // //   const [entities, setEntities] = useState(
// // // // // // // // // // // // // // // // // // // // // // // // //     () => loadFromLocalStorage("entities") || []
// // // // // // // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // // // // // //   const [selectedWorkdays, setSelectedWorkdays] = useState([]);
// // // // // // // // // // // // // // // // // // // // // // // // //   const [dropdownData, setDropdownData] = useState({
// // // // // // // // // // // // // // // // // // // // // // // // //     vas: [],
// // // // // // // // // // // // // // // // // // // // // // // // //     merchant: [],
// // // // // // // // // // // // // // // // // // // // // // // // //     unit_map: [],
// // // // // // // // // // // // // // // // // // // // // // // // //     status: [],
// // // // // // // // // // // // // // // // // // // // // // // // //     domain: [],
// // // // // // // // // // // // // // // // // // // // // // // // //   });

// // // // // // // // // // // // // // // // // // // // // // // // //   const endpoints = {
// // // // // // // // // // // // // // // // // // // // // // // // //     vas: "http://146.190.11.26:5055/view_vas/v0.1",
// // // // // // // // // // // // // // // // // // // // // // // // //     merchant: "http://146.190.11.26:5055/view_merchant/v0.1",
// // // // // // // // // // // // // // // // // // // // // // // // //     unitMap: "http://146.190.11.26:5055/unit_bec_map_view/v0.1",
// // // // // // // // // // // // // // // // // // // // // // // // //     status: "http://146.190.11.26:5055/view_status/v0.1",
// // // // // // // // // // // // // // // // // // // // // // // // //     domain: "http://146.190.11.26:5055/view_domain/v0.1",
// // // // // // // // // // // // // // // // // // // // // // // // //   };
// // // // // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // // // // //     options: vasOptions,
// // // // // // // // // // // // // // // // // // // // // // // // //     loading: vasLoading,
// // // // // // // // // // // // // // // // // // // // // // // // //     error: vasError,
// // // // // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.vas);
// // // // // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // // // // //     options: merchantOptions,
// // // // // // // // // // // // // // // // // // // // // // // // //     loading: merchantLoading,
// // // // // // // // // // // // // // // // // // // // // // // // //     error: merchantError,
// // // // // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.merchant);
// // // // // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // // // // //     options: unitMapOptions,
// // // // // // // // // // // // // // // // // // // // // // // // //     loading: unitMapLoading,
// // // // // // // // // // // // // // // // // // // // // // // // //     error: unitMapError,
// // // // // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.unitMap);
// // // // // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // // // // //     options: statusOptions,
// // // // // // // // // // // // // // // // // // // // // // // // //     loading: statusLoading,
// // // // // // // // // // // // // // // // // // // // // // // // //     error: statusError,
// // // // // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.status);
// // // // // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // // // // //     options: domainOptions,
// // // // // // // // // // // // // // // // // // // // // // // // //     loading: domainLoading,
// // // // // // // // // // // // // // // // // // // // // // // // //     error: domainError,
// // // // // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.domain);

// // // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // // //     if (vasOptions)
// // // // // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, vas: vasOptions }));
// // // // // // // // // // // // // // // // // // // // // // // // //   }, [vasOptions]);

// // // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // // //     if (merchantOptions)
// // // // // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({
// // // // // // // // // // // // // // // // // // // // // // // // //         ...prevData,
// // // // // // // // // // // // // // // // // // // // // // // // //         merchant: merchantOptions,
// // // // // // // // // // // // // // // // // // // // // // // // //       }));
// // // // // // // // // // // // // // // // // // // // // // // // //   }, [merchantOptions]);

// // // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // // //     if (unitMapOptions)
// // // // // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({
// // // // // // // // // // // // // // // // // // // // // // // // //         ...prevData,
// // // // // // // // // // // // // // // // // // // // // // // // //         unit_map: unitMapOptions,
// // // // // // // // // // // // // // // // // // // // // // // // //       }));
// // // // // // // // // // // // // // // // // // // // // // // // //   }, [unitMapOptions]);

// // // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // // //     if (statusOptions)
// // // // // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, status: statusOptions }));
// // // // // // // // // // // // // // // // // // // // // // // // //   }, [statusOptions]);

// // // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // // //     if (domainOptions)
// // // // // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, domain: domainOptions }));
// // // // // // // // // // // // // // // // // // // // // // // // //   }, [domainOptions]);

// // // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // // //     saveToLocalStorage("entities", entities);
// // // // // // // // // // // // // // // // // // // // // // // // //   }, [entities]);

// // // // // // // // // // // // // // // // // // // // // // // // //   const handleFormSubmit = (e) => {
// // // // // // // // // // // // // // // // // // // // // // // // //     e.preventDefault();
// // // // // // // // // // // // // // // // // // // // // // // // //     console.log("Form Data on Submit:", formData); // Debug statement
// // // // // // // // // // // // // // // // // // // // // // // // //     const newEntity = {
// // // // // // // // // // // // // // // // // // // // // // // // //       id: entities.length + 1,
// // // // // // // // // // // // // // // // // // // // // // // // //       ...formData,
// // // // // // // // // // // // // // // // // // // // // // // // //       workdays: selectedWorkdays,
// // // // // // // // // // // // // // // // // // // // // // // // //     };
// // // // // // // // // // // // // // // // // // // // // // // // //     setEntities([...entities, newEntity]);
// // // // // // // // // // // // // // // // // // // // // // // // //     resetForm();
// // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // //   const resetForm = () => {
// // // // // // // // // // // // // // // // // // // // // // // // //     setFormData({
// // // // // // // // // // // // // // // // // // // // // // // // //       service_provider_name: "",
// // // // // // // // // // // // // // // // // // // // // // // // //       vas_id: "",
// // // // // // // // // // // // // // // // // // // // // // // // //       merchant_id: "",
// // // // // // // // // // // // // // // // // // // // // // // // //       unit_map_id: "",
// // // // // // // // // // // // // // // // // // // // // // // // //       status_id: "",
// // // // // // // // // // // // // // // // // // // // // // // // //       phone_extension: "",
// // // // // // // // // // // // // // // // // // // // // // // // //       phone_no: "",
// // // // // // // // // // // // // // // // // // // // // // // // //       domain_id: "",
// // // // // // // // // // // // // // // // // // // // // // // // //       worktime_from: "",
// // // // // // // // // // // // // // // // // // // // // // // // //       worktime_to: "",
// // // // // // // // // // // // // // // // // // // // // // // // //       workdays: [],
// // // // // // // // // // // // // // // // // // // // // // // // //     });
// // // // // // // // // // // // // // // // // // // // // // // // //     setSelectedWorkdays([]);
// // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // //   const handleEdit = (entityId) => {
// // // // // // // // // // // // // // // // // // // // // // // // //     const entityToEdit = entities.find((entity) => entity.id === entityId);
// // // // // // // // // // // // // // // // // // // // // // // // //     if (entityToEdit) {
// // // // // // // // // // // // // // // // // // // // // // // // //       setFormData({ ...entityToEdit });
// // // // // // // // // // // // // // // // // // // // // // // // //       setSelectedWorkdays(entityToEdit.workdays || []);
// // // // // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // //   const handleDelete = (entityId) => {
// // // // // // // // // // // // // // // // // // // // // // // // //     const updatedEntities = entities.filter((entity) => entity.id !== entityId);
// // // // // // // // // // // // // // // // // // // // // // // // //     setEntities(updatedEntities);
// // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // //   const handleSave = (editedEntity) => {
// // // // // // // // // // // // // // // // // // // // // // // // //     setEntities((prevEntities) =>
// // // // // // // // // // // // // // // // // // // // // // // // //       prevEntities.map((entity) =>
// // // // // // // // // // // // // // // // // // // // // // // // //         entity.id === editedEntity.id ? editedEntity : entity
// // // // // // // // // // // // // // // // // // // // // // // // //       )
// // // // // // // // // // // // // // // // // // // // // // // // //     );
// // // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // // // // // // // //     <div className="service-providers">
// // // // // // // // // // // // // // // // // // // // // // // // //       <EntityManagement
// // // // // // // // // // // // // // // // // // // // // // // // //         entityName="Service Provider"
// // // // // // // // // // // // // // // // // // // // // // // // //         formFields={[
// // // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // // //             name: "service_provider_name",
// // // // // // // // // // // // // // // // // // // // // // // // //             label: "Service Provider Name",
// // // // // // // // // // // // // // // // // // // // // // // // //             type: "text",
// // // // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // // //             name: "vas_id",
// // // // // // // // // // // // // // // // // // // // // // // // //             label: "VAS",
// // // // // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "vas",
// // // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // // //             name: "merchant_id",
// // // // // // // // // // // // // // // // // // // // // // // // //             label: "Merchant",
// // // // // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "merchant",
// // // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // // //             name: "unit_map_id",
// // // // // // // // // // // // // // // // // // // // // // // // //             label: "Unit Map",
// // // // // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "unit_map",
// // // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // // //             name: "status_id",
// // // // // // // // // // // // // // // // // // // // // // // // //             label: "Status",
// // // // // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "status",
// // // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // // //             name: "phone_extension",
// // // // // // // // // // // // // // // // // // // // // // // // //             label: "Phone Extension",
// // // // // // // // // // // // // // // // // // // // // // // // //             type: "text",
// // // // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // // //           { name: "phone_no", label: "Phone No", type: "text", required: true },
// // // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // // //             name: "domain_id",
// // // // // // // // // // // // // // // // // // // // // // // // //             label: "Domain",
// // // // // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "domain",
// // // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // // //             name: "worktime_from",
// // // // // // // // // // // // // // // // // // // // // // // // //             label: "Worktime From",
// // // // // // // // // // // // // // // // // // // // // // // // //             type: "time",
// // // // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // // //             name: "worktime_to",
// // // // // // // // // // // // // // // // // // // // // // // // //             label: "Worktime To",
// // // // // // // // // // // // // // // // // // // // // // // // //             type: "time",
// // // // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // // //         ]}
// // // // // // // // // // // // // // // // // // // // // // // // //         formData={formData}
// // // // // // // // // // // // // // // // // // // // // // // // //         setFormData={setFormData}
// // // // // // // // // // // // // // // // // // // // // // // // //         dropdownData={dropdownData}
// // // // // // // // // // // // // // // // // // // // // // // // //         entities={entities}
// // // // // // // // // // // // // // // // // // // // // // // // //         setEntities={setEntities}
// // // // // // // // // // // // // // // // // // // // // // // // //         showWorkdays={true}
// // // // // // // // // // // // // // // // // // // // // // // // //         selectedWorkdays={selectedWorkdays}
// // // // // // // // // // // // // // // // // // // // // // // // //         setSelectedWorkdays={setSelectedWorkdays}
// // // // // // // // // // // // // // // // // // // // // // // // //         handleFormSubmit={handleFormSubmit}
// // // // // // // // // // // // // // // // // // // // // // // // //         handleEdit={handleEdit}
// // // // // // // // // // // // // // // // // // // // // // // // //         handleDelete={handleDelete}
// // // // // // // // // // // // // // // // // // // // // // // // //       />
// // // // // // // // // // // // // // // // // // // // // // // // //       <EntityTable
// // // // // // // // // // // // // // // // // // // // // // // // //         entities={entities}
// // // // // // // // // // // // // // // // // // // // // // // // //         columns={[
// // // // // // // // // // // // // // // // // // // // // // // // //           { field: "id", label: "ID" },
// // // // // // // // // // // // // // // // // // // // // // // // //           { field: "service_provider_name", label: "Service Provider Name" },
// // // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // // //             field: "vas_id",
// // // // // // // // // // // // // // // // // // // // // // // // //             label: "VAS",
// // // // // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "vas",
// // // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // // //             field: "merchant_id",
// // // // // // // // // // // // // // // // // // // // // // // // //             label: "Merchant",
// // // // // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "merchant",
// // // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // // //             field: "unit_map_id",
// // // // // // // // // // // // // // // // // // // // // // // // //             label: "Unit Map",
// // // // // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "unit_map",
// // // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // // //             field: "status_id",
// // // // // // // // // // // // // // // // // // // // // // // // //             label: "Status",
// // // // // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "status",
// // // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // // //           { field: "phone_extension", label: "Phone Extension" },
// // // // // // // // // // // // // // // // // // // // // // // // //           { field: "phone_no", label: "Phone No" },
// // // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // // //             field: "domain_id",
// // // // // // // // // // // // // // // // // // // // // // // // //             label: "Domain",
// // // // // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "domain",
// // // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // // //           { field: "workdays", label: "Workdays" },
// // // // // // // // // // // // // // // // // // // // // // // // //           { field: "worktime_from", label: "Worktime From" },
// // // // // // // // // // // // // // // // // // // // // // // // //           { field: "worktime_to", label: "Worktime To" },
// // // // // // // // // // // // // // // // // // // // // // // // //         ]}
// // // // // // // // // // // // // // // // // // // // // // // // //         handleEdit={handleEdit}
// // // // // // // // // // // // // // // // // // // // // // // // //         handleDelete={handleDelete}
// // // // // // // // // // // // // // // // // // // // // // // // //         handleSave={handleSave}
// // // // // // // // // // // // // // // // // // // // // // // // //         dropdownData={dropdownData}
// // // // // // // // // // // // // // // // // // // // // // // // //       />
// // // // // // // // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // // // export default ServiceProviders;

// // // // // // // // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // // // // // // // // // import EntityManagement from "../../components/formTemplates/EntityManagement";
// // // // // // // // // // // // // // // // // // // // // // // // import EntityTable from "../../components/templateComponents/EntityTable";
// // // // // // // // // // // // // // // // // // // // // // // // import useDropdownFetch from "../../components/apiFetch/useDropdownFetch";

// // // // // // // // // // // // // // // // // // // // // // // // const loadFromLocalStorage = (key) => {
// // // // // // // // // // // // // // // // // // // // // // // //   const savedData = localStorage.getItem(key);
// // // // // // // // // // // // // // // // // // // // // // // //   return savedData ? JSON.parse(savedData) : null;
// // // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // // const saveToLocalStorage = (key, data) => {
// // // // // // // // // // // // // // // // // // // // // // // //   localStorage.setItem(key, JSON.stringify(data));
// // // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // // const ServiceProviders = () => {
// // // // // // // // // // // // // // // // // // // // // // // //   const [formData, setFormData] = useState({
// // // // // // // // // // // // // // // // // // // // // // // //     service_provider_name: "",
// // // // // // // // // // // // // // // // // // // // // // // //     vas_id: "",
// // // // // // // // // // // // // // // // // // // // // // // //     shop_id: "",
// // // // // // // // // // // // // // // // // // // // // // // //     unit_map_id: "",
// // // // // // // // // // // // // // // // // // // // // // // //     status_id: "",
// // // // // // // // // // // // // // // // // // // // // // // //     phone_extension: "",
// // // // // // // // // // // // // // // // // // // // // // // //     phone_no: "",
// // // // // // // // // // // // // // // // // // // // // // // //     domain_id: "",
// // // // // // // // // // // // // // // // // // // // // // // //     worktime_from: "",
// // // // // // // // // // // // // // // // // // // // // // // //     worktime_to: "",
// // // // // // // // // // // // // // // // // // // // // // // //     workdays: [],
// // // // // // // // // // // // // // // // // // // // // // // //   });

// // // // // // // // // // // // // // // // // // // // // // // //   const [entities, setEntities] = useState(
// // // // // // // // // // // // // // // // // // // // // // // //     () => loadFromLocalStorage("entities") || []
// // // // // // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // // // // //   const [selectedWorkdays, setSelectedWorkdays] = useState([]);
// // // // // // // // // // // // // // // // // // // // // // // //   const [dropdownData, setDropdownData] = useState({
// // // // // // // // // // // // // // // // // // // // // // // //     vas: [],
// // // // // // // // // // // // // // // // // // // // // // // //     shop: [],
// // // // // // // // // // // // // // // // // // // // // // // //     unit_map: [],
// // // // // // // // // // // // // // // // // // // // // // // //     status: [],
// // // // // // // // // // // // // // // // // // // // // // // //     domain: [],
// // // // // // // // // // // // // // // // // // // // // // // //   });

// // // // // // // // // // // // // // // // // // // // // // // //   const endpoints = {
// // // // // // // // // // // // // // // // // // // // // // // //     vas: "http://146.190.11.26:5055/view_vas/v0.1",
// // // // // // // // // // // // // // // // // // // // // // // //     shop: "http://146.190.11.26:5055/shop_view/v0.1",
// // // // // // // // // // // // // // // // // // // // // // // //     unitMap: "http://146.190.11.26:5055/unit_bec_map_view/v0.1",
// // // // // // // // // // // // // // // // // // // // // // // //     status: "http://146.190.11.26:5055/view_status/v0.1",
// // // // // // // // // // // // // // // // // // // // // // // //     domain: "http://146.190.11.26:5055/view_domain/v0.1",
// // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // // // //     options: vasOptions,
// // // // // // // // // // // // // // // // // // // // // // // //     loading: vasLoading,
// // // // // // // // // // // // // // // // // // // // // // // //     error: vasError,
// // // // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.vas);
// // // // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // // // //     options: shopOptions,
// // // // // // // // // // // // // // // // // // // // // // // //     loading: shopLoading,
// // // // // // // // // // // // // // // // // // // // // // // //     error: shopError,
// // // // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.shop);
// // // // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // // // //     options: unitMapOptions,
// // // // // // // // // // // // // // // // // // // // // // // //     loading: unitMapLoading,
// // // // // // // // // // // // // // // // // // // // // // // //     error: unitMapError,
// // // // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.unitMap);
// // // // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // // // //     options: statusOptions,
// // // // // // // // // // // // // // // // // // // // // // // //     loading: statusLoading,
// // // // // // // // // // // // // // // // // // // // // // // //     error: statusError,
// // // // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.status);
// // // // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // // // //     options: domainOptions,
// // // // // // // // // // // // // // // // // // // // // // // //     loading: domainLoading,
// // // // // // // // // // // // // // // // // // // // // // // //     error: domainError,
// // // // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.domain);

// // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // //     if (vasOptions)
// // // // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, vas: vasOptions }));
// // // // // // // // // // // // // // // // // // // // // // // //   }, [vasOptions]);

// // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // //     if (shopOptions)
// // // // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({
// // // // // // // // // // // // // // // // // // // // // // // //         ...prevData,
// // // // // // // // // // // // // // // // // // // // // // // //         shop: shopOptions,
// // // // // // // // // // // // // // // // // // // // // // // //       }));
// // // // // // // // // // // // // // // // // // // // // // // //   }, [shopOptions]);

// // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // //     if (unitMapOptions)
// // // // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({
// // // // // // // // // // // // // // // // // // // // // // // //         ...prevData,
// // // // // // // // // // // // // // // // // // // // // // // //         unit_map: unitMapOptions,
// // // // // // // // // // // // // // // // // // // // // // // //       }));
// // // // // // // // // // // // // // // // // // // // // // // //   }, [unitMapOptions]);

// // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // //     if (statusOptions)
// // // // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, status: statusOptions }));
// // // // // // // // // // // // // // // // // // // // // // // //   }, [statusOptions]);

// // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // //     if (domainOptions)
// // // // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, domain: domainOptions }));
// // // // // // // // // // // // // // // // // // // // // // // //   }, [domainOptions]);

// // // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // // //     saveToLocalStorage("entities", entities);
// // // // // // // // // // // // // // // // // // // // // // // //   }, [entities]);

// // // // // // // // // // // // // // // // // // // // // // // //   const handleFormSubmit = async (e) => {
// // // // // // // // // // // // // // // // // // // // // // // //     e.preventDefault();
// // // // // // // // // // // // // // // // // // // // // // // //     console.log("Form Data on Submit:", formData); // Debug statement

// // // // // // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // // // // // //       const response = await axios.post(
// // // // // // // // // // // // // // // // // // // // // // // //         "http://146.190.11.26:5055/service_provider_reg/v0.1",
// // // // // // // // // // // // // // // // // // // // // // // //         {
// // // // // // // // // // // // // // // // // // // // // // // //           ...formData,
// // // // // // // // // // // // // // // // // // // // // // // //           workdays: selectedWorkdays,
// // // // // // // // // // // // // // // // // // // // // // // //           added_date: new Date().toISOString(),
// // // // // // // // // // // // // // // // // // // // // // // //           updated_date: new Date().toISOString(),
// // // // // // // // // // // // // // // // // // // // // // // //         }
// // // // // // // // // // // // // // // // // // // // // // // //       );

// // // // // // // // // // // // // // // // // // // // // // // //       console.log("Response from API:", response.data); // Debug statement

// // // // // // // // // // // // // // // // // // // // // // // //       const newEntity = {
// // // // // // // // // // // // // // // // // // // // // // // //         id: entities.length + 1,
// // // // // // // // // // // // // // // // // // // // // // // //         ...formData,
// // // // // // // // // // // // // // // // // // // // // // // //         workdays: selectedWorkdays,
// // // // // // // // // // // // // // // // // // // // // // // //       };

// // // // // // // // // // // // // // // // // // // // // // // //       setEntities([...entities, newEntity]);
// // // // // // // // // // // // // // // // // // // // // // // //       resetForm();
// // // // // // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // // // // // //       console.error("Error saving data:", error); // Error handling
// // // // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // //   const resetForm = () => {
// // // // // // // // // // // // // // // // // // // // // // // //     setFormData({
// // // // // // // // // // // // // // // // // // // // // // // //       service_provider_name: "",
// // // // // // // // // // // // // // // // // // // // // // // //       vas_id: "",
// // // // // // // // // // // // // // // // // // // // // // // //       shop_id: "",
// // // // // // // // // // // // // // // // // // // // // // // //       unit_map_id: "",
// // // // // // // // // // // // // // // // // // // // // // // //       status_id: "",
// // // // // // // // // // // // // // // // // // // // // // // //       phone_extension: "",
// // // // // // // // // // // // // // // // // // // // // // // //       phone_no: "",
// // // // // // // // // // // // // // // // // // // // // // // //       domain_id: "",
// // // // // // // // // // // // // // // // // // // // // // // //       worktime_from: "",
// // // // // // // // // // // // // // // // // // // // // // // //       worktime_to: "",
// // // // // // // // // // // // // // // // // // // // // // // //       workdays: [],
// // // // // // // // // // // // // // // // // // // // // // // //     });
// // // // // // // // // // // // // // // // // // // // // // // //     setSelectedWorkdays([]);
// // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // //   const handleEdit = (entityId) => {
// // // // // // // // // // // // // // // // // // // // // // // //     const entityToEdit = entities.find((entity) => entity.id === entityId);
// // // // // // // // // // // // // // // // // // // // // // // //     if (entityToEdit) {
// // // // // // // // // // // // // // // // // // // // // // // //       setFormData({ ...entityToEdit });
// // // // // // // // // // // // // // // // // // // // // // // //       setSelectedWorkdays(entityToEdit.workdays || []);
// // // // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // //   const handleDelete = (entityId) => {
// // // // // // // // // // // // // // // // // // // // // // // //     const updatedEntities = entities.filter((entity) => entity.id !== entityId);
// // // // // // // // // // // // // // // // // // // // // // // //     setEntities(updatedEntities);
// // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // //   // const handleSave = (editedEntity) => {
// // // // // // // // // // // // // // // // // // // // // // // //   //   setEntities((prevEntities) =>
// // // // // // // // // // // // // // // // // // // // // // // //   //     prevEntities.map((entity) =>
// // // // // // // // // // // // // // // // // // // // // // // //   //       entity.id === editedEntity.id ? editedEntity : entity
// // // // // // // // // // // // // // // // // // // // // // // //   //     )
// // // // // // // // // // // // // // // // // // // // // // // //   //   );
// // // // // // // // // // // // // // // // // // // // // // // //   // };

// // // // // // // // // // // // // // // // // // // // // // // //   const handleSave = async (editedEntity) => {
// // // // // // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // // // // // //       // Convert the workdays array to a comma-separated string
// // // // // // // // // // // // // // // // // // // // // // // //       const workdaysString = editedEntity.workdays.join(", ");

// // // // // // // // // // // // // // // // // // // // // // // //       // Prepare the data excluding the `id` field from the `SET` clause
// // // // // // // // // // // // // // // // // // // // // // // //       const { id, ...updateData } = editedEntity;

// // // // // // // // // // // // // // // // // // // // // // // //       // Send the request to update the service provider
// // // // // // // // // // // // // // // // // // // // // // // //       const response = await axios.post(
// // // // // // // // // // // // // // // // // // // // // // // //         "http://146.190.11.26:5055/service_provider_update/v0.1",
// // // // // // // // // // // // // // // // // // // // // // // //         {
// // // // // // // // // // // // // // // // // // // // // // // //           service_provider_id: id, // ID is used in the WHERE clause
// // // // // // // // // // // // // // // // // // // // // // // //           ...updateData,
// // // // // // // // // // // // // // // // // // // // // // // //           workdays: workdaysString, // Convert workdays to a string
// // // // // // // // // // // // // // // // // // // // // // // //           updated_date: new Date().toISOString(),
// // // // // // // // // // // // // // // // // // // // // // // //         }
// // // // // // // // // // // // // // // // // // // // // // // //       );

// // // // // // // // // // // // // // // // // // // // // // // //       console.log("Update Response from API:", response.data); // Debug statement

// // // // // // // // // // // // // // // // // // // // // // // //       // Update the local entities state with the edited entity
// // // // // // // // // // // // // // // // // // // // // // // //       setEntities((prevEntities) =>
// // // // // // // // // // // // // // // // // // // // // // // //         prevEntities.map((entity) =>
// // // // // // // // // // // // // // // // // // // // // // // //           entity.id === id ? { ...updateData, id } : entity
// // // // // // // // // // // // // // // // // // // // // // // //         )
// // // // // // // // // // // // // // // // // // // // // // // //       );
// // // // // // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // // // // // //       console.error("Error updating data:", error); // Error handling
// // // // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // // // // // // //     <div className="service-providers">
// // // // // // // // // // // // // // // // // // // // // // // //       <EntityManagement
// // // // // // // // // // // // // // // // // // // // // // // //         entityName="Service Provider"
// // // // // // // // // // // // // // // // // // // // // // // //         formFields={[
// // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // //             name: "service_provider_name",
// // // // // // // // // // // // // // // // // // // // // // // //             label: "Service Provider Name",
// // // // // // // // // // // // // // // // // // // // // // // //             type: "text",
// // // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // //             name: "vas_id",
// // // // // // // // // // // // // // // // // // // // // // // //             label: "VAS",
// // // // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "vas",
// // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // //             name: "shop_id",
// // // // // // // // // // // // // // // // // // // // // // // //             label: "Shop",
// // // // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "shop",
// // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // //             name: "unit_map_id",
// // // // // // // // // // // // // // // // // // // // // // // //             label: "Unit Map",
// // // // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "unit_map",
// // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // //             name: "status_id",
// // // // // // // // // // // // // // // // // // // // // // // //             label: "Status",
// // // // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "status",
// // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // //             name: "phone_extension",
// // // // // // // // // // // // // // // // // // // // // // // //             label: "Phone Extension",
// // // // // // // // // // // // // // // // // // // // // // // //             type: "text",
// // // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // //           { name: "phone_no", label: "Phone No", type: "text", required: true },
// // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // //             name: "domain_id",
// // // // // // // // // // // // // // // // // // // // // // // //             label: "Domain",
// // // // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "domain",
// // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // //             name: "worktime_from",
// // // // // // // // // // // // // // // // // // // // // // // //             label: "Worktime From",
// // // // // // // // // // // // // // // // // // // // // // // //             type: "time",
// // // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // //             name: "worktime_to",
// // // // // // // // // // // // // // // // // // // // // // // //             label: "Worktime To",
// // // // // // // // // // // // // // // // // // // // // // // //             type: "time",
// // // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // //         ]}
// // // // // // // // // // // // // // // // // // // // // // // //         formData={formData}
// // // // // // // // // // // // // // // // // // // // // // // //         setFormData={setFormData}
// // // // // // // // // // // // // // // // // // // // // // // //         dropdownData={dropdownData}
// // // // // // // // // // // // // // // // // // // // // // // //         entities={entities}
// // // // // // // // // // // // // // // // // // // // // // // //         setEntities={setEntities}
// // // // // // // // // // // // // // // // // // // // // // // //         showWorkdays={true}
// // // // // // // // // // // // // // // // // // // // // // // //         selectedWorkdays={selectedWorkdays}
// // // // // // // // // // // // // // // // // // // // // // // //         setSelectedWorkdays={setSelectedWorkdays}
// // // // // // // // // // // // // // // // // // // // // // // //         handleFormSubmit={handleFormSubmit}
// // // // // // // // // // // // // // // // // // // // // // // //         handleEdit={handleEdit}
// // // // // // // // // // // // // // // // // // // // // // // //         handleDelete={handleDelete}
// // // // // // // // // // // // // // // // // // // // // // // //       />
// // // // // // // // // // // // // // // // // // // // // // // //       <EntityTable
// // // // // // // // // // // // // // // // // // // // // // // //         entities={entities}
// // // // // // // // // // // // // // // // // // // // // // // //         columns={[
// // // // // // // // // // // // // // // // // // // // // // // //           { field: "id", label: "ID" },
// // // // // // // // // // // // // // // // // // // // // // // //           { field: "service_provider_name", label: "Service Provider Name" },
// // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // //             field: "vas_id",
// // // // // // // // // // // // // // // // // // // // // // // //             label: "VAS",
// // // // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "vas",
// // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // //             field: "shop_id",
// // // // // // // // // // // // // // // // // // // // // // // //             label: "Shop",
// // // // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "shop",
// // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // //             field: "unit_map_id",
// // // // // // // // // // // // // // // // // // // // // // // //             label: "Unit Map",
// // // // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "unit_map",
// // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // //             field: "status_id",
// // // // // // // // // // // // // // // // // // // // // // // //             label: "Status",
// // // // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "status",
// // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // //           { field: "phone_extension", label: "Phone Extension" },
// // // // // // // // // // // // // // // // // // // // // // // //           { field: "phone_no", label: "Phone No" },
// // // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // // //             field: "domain_id",
// // // // // // // // // // // // // // // // // // // // // // // //             label: "Domain",
// // // // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "domain",
// // // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // // //           { field: "workdays", label: "Workdays" },
// // // // // // // // // // // // // // // // // // // // // // // //           { field: "worktime_from", label: "Worktime From" },
// // // // // // // // // // // // // // // // // // // // // // // //           { field: "worktime_to", label: "Worktime To" },
// // // // // // // // // // // // // // // // // // // // // // // //         ]}
// // // // // // // // // // // // // // // // // // // // // // // //         handleEdit={handleEdit}
// // // // // // // // // // // // // // // // // // // // // // // //         handleDelete={handleDelete}
// // // // // // // // // // // // // // // // // // // // // // // //         handleSave={handleSave}
// // // // // // // // // // // // // // // // // // // // // // // //         dropdownData={dropdownData}
// // // // // // // // // // // // // // // // // // // // // // // //       />
// // // // // // // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // // export default ServiceProviders;

// // // // // // // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // // // // // // // // import EntityManagement from "../../components/formTemplates/EntityManagement";
// // // // // // // // // // // // // // // // // // // // // // // import EntityTable from "../../components/templateComponents/EntityTable";
// // // // // // // // // // // // // // // // // // // // // // // import useDropdownFetch from "../../components/apiFetch/useDropdownFetch";

// // // // // // // // // // // // // // // // // // // // // // // const loadFromLocalStorage = (key) => {
// // // // // // // // // // // // // // // // // // // // // // //   const savedData = localStorage.getItem(key);
// // // // // // // // // // // // // // // // // // // // // // //   return savedData ? JSON.parse(savedData) : [];
// // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // const saveToLocalStorage = (key, data) => {
// // // // // // // // // // // // // // // // // // // // // // //   localStorage.setItem(key, JSON.stringify(data));
// // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // const ServiceProviders = () => {
// // // // // // // // // // // // // // // // // // // // // // //   const [formData, setFormData] = useState({
// // // // // // // // // // // // // // // // // // // // // // //     service_provider_name: "",
// // // // // // // // // // // // // // // // // // // // // // //     vas_id: "",
// // // // // // // // // // // // // // // // // // // // // // //     shop_id: "",
// // // // // // // // // // // // // // // // // // // // // // //     unit_map_id: "",
// // // // // // // // // // // // // // // // // // // // // // //     status_id: "",
// // // // // // // // // // // // // // // // // // // // // // //     phone_extension: "",
// // // // // // // // // // // // // // // // // // // // // // //     phone_no: "",
// // // // // // // // // // // // // // // // // // // // // // //     domain_id: "",
// // // // // // // // // // // // // // // // // // // // // // //     worktime_from: "",
// // // // // // // // // // // // // // // // // // // // // // //     worktime_to: "",
// // // // // // // // // // // // // // // // // // // // // // //     workdays: [],
// // // // // // // // // // // // // // // // // // // // // // //   });

// // // // // // // // // // // // // // // // // // // // // // //   const [entities, setEntities] = useState(loadFromLocalStorage("entities"));
// // // // // // // // // // // // // // // // // // // // // // //   const [selectedWorkdays, setSelectedWorkdays] = useState([]);
// // // // // // // // // // // // // // // // // // // // // // //   const [dropdownData, setDropdownData] = useState({
// // // // // // // // // // // // // // // // // // // // // // //     vas: [],
// // // // // // // // // // // // // // // // // // // // // // //     shop: [],
// // // // // // // // // // // // // // // // // // // // // // //     unit_map: [],
// // // // // // // // // // // // // // // // // // // // // // //     status: [],
// // // // // // // // // // // // // // // // // // // // // // //     domain: [],
// // // // // // // // // // // // // // // // // // // // // // //   });

// // // // // // // // // // // // // // // // // // // // // // //   const endpoints = {
// // // // // // // // // // // // // // // // // // // // // // //     vas: "http://146.190.11.26:5055/view_vas/v0.1",
// // // // // // // // // // // // // // // // // // // // // // //     shop: "http://146.190.11.26:5055/shop_view/v0.1",
// // // // // // // // // // // // // // // // // // // // // // //     unitMap: "http://146.190.11.26:5055/unit_bec_map_view/v0.1",
// // // // // // // // // // // // // // // // // // // // // // //     status: "http://146.190.11.26:5055/view_status/v0.1",
// // // // // // // // // // // // // // // // // // // // // // //     domain: "http://146.190.11.26:5055/view_domain/v0.1",
// // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // // //     options: vasOptions,
// // // // // // // // // // // // // // // // // // // // // // //     loading: vasLoading,
// // // // // // // // // // // // // // // // // // // // // // //     error: vasError,
// // // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.vas);

// // // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // // //     options: shopOptions,
// // // // // // // // // // // // // // // // // // // // // // //     loading: shopLoading,
// // // // // // // // // // // // // // // // // // // // // // //     error: shopError,
// // // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.shop);

// // // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // // //     options: unitMapOptions,
// // // // // // // // // // // // // // // // // // // // // // //     loading: unitMapLoading,
// // // // // // // // // // // // // // // // // // // // // // //     error: unitMapError,
// // // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.unitMap);

// // // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // // //     options: statusOptions,
// // // // // // // // // // // // // // // // // // // // // // //     loading: statusLoading,
// // // // // // // // // // // // // // // // // // // // // // //     error: statusError,
// // // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.status);

// // // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // // //     options: domainOptions,
// // // // // // // // // // // // // // // // // // // // // // //     loading: domainLoading,
// // // // // // // // // // // // // // // // // // // // // // //     error: domainError,
// // // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.domain);

// // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // //     if (vasOptions)
// // // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, vas: vasOptions }));
// // // // // // // // // // // // // // // // // // // // // // //   }, [vasOptions]);

// // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // //     if (shopOptions)
// // // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, shop: shopOptions }));
// // // // // // // // // // // // // // // // // // // // // // //   }, [shopOptions]);

// // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // //     if (unitMapOptions)
// // // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({
// // // // // // // // // // // // // // // // // // // // // // //         ...prevData,
// // // // // // // // // // // // // // // // // // // // // // //         unit_map: unitMapOptions,
// // // // // // // // // // // // // // // // // // // // // // //       }));
// // // // // // // // // // // // // // // // // // // // // // //   }, [unitMapOptions]);

// // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // //     if (statusOptions)
// // // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, status: statusOptions }));
// // // // // // // // // // // // // // // // // // // // // // //   }, [statusOptions]);

// // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // //     if (domainOptions)
// // // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, domain: domainOptions }));
// // // // // // // // // // // // // // // // // // // // // // //   }, [domainOptions]);

// // // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // // //     saveToLocalStorage("entities", entities);
// // // // // // // // // // // // // // // // // // // // // // //   }, [entities]);

// // // // // // // // // // // // // // // // // // // // // // //   const handleFormSubmit = async (e) => {
// // // // // // // // // // // // // // // // // // // // // // //     e.preventDefault();

// // // // // // // // // // // // // // // // // // // // // // //     const formattedFormData = {
// // // // // // // // // // // // // // // // // // // // // // //       ...formData,
// // // // // // // // // // // // // // // // // // // // // // //       vas_id: parseInt(formData.vas_id, 10),
// // // // // // // // // // // // // // // // // // // // // // //       shop_id: parseInt(formData.shop_id, 10),
// // // // // // // // // // // // // // // // // // // // // // //       unit_map_id: parseInt(formData.unit_map_id, 10),
// // // // // // // // // // // // // // // // // // // // // // //       status_id: parseInt(formData.status_id, 10),
// // // // // // // // // // // // // // // // // // // // // // //       domain_id: parseInt(formData.domain_id, 10),
// // // // // // // // // // // // // // // // // // // // // // //       workdays: selectedWorkdays.map((day) => day.toString()), // Ensure workdays is a list of strings
// // // // // // // // // // // // // // // // // // // // // // //       added_date: new Date().toISOString(),
// // // // // // // // // // // // // // // // // // // // // // //       updated_date: new Date().toISOString(),
// // // // // // // // // // // // // // // // // // // // // // //     };

// // // // // // // // // // // // // // // // // // // // // // //     console.log("Formatted Form Data on Submit:", formattedFormData); // Debug statement

// // // // // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // // // // //       const response = await axios.post(
// // // // // // // // // // // // // // // // // // // // // // //         "http://146.190.11.26:5055/service_provider_reg/v0.1",
// // // // // // // // // // // // // // // // // // // // // // //         formattedFormData
// // // // // // // // // // // // // // // // // // // // // // //       );

// // // // // // // // // // // // // // // // // // // // // // //       console.log("Response from API:", response.data); // Debug statement

// // // // // // // // // // // // // // // // // // // // // // //       const newEntity = {
// // // // // // // // // // // // // // // // // // // // // // //         id: entities.length + 1,
// // // // // // // // // // // // // // // // // // // // // // //         ...formattedFormData,
// // // // // // // // // // // // // // // // // // // // // // //         workdays: selectedWorkdays,
// // // // // // // // // // // // // // // // // // // // // // //       };

// // // // // // // // // // // // // // // // // // // // // // //       setEntities([...entities, newEntity]);
// // // // // // // // // // // // // // // // // // // // // // //       resetForm();
// // // // // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // // // // //       console.error("Error saving data:", error); // Error handling
// // // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // //   const resetForm = () => {
// // // // // // // // // // // // // // // // // // // // // // //     setFormData({
// // // // // // // // // // // // // // // // // // // // // // //       service_provider_name: "",
// // // // // // // // // // // // // // // // // // // // // // //       vas_id: "",
// // // // // // // // // // // // // // // // // // // // // // //       shop_id: "",
// // // // // // // // // // // // // // // // // // // // // // //       unit_map_id: "",
// // // // // // // // // // // // // // // // // // // // // // //       status_id: "",
// // // // // // // // // // // // // // // // // // // // // // //       phone_extension: "",
// // // // // // // // // // // // // // // // // // // // // // //       phone_no: "",
// // // // // // // // // // // // // // // // // // // // // // //       domain_id: "",
// // // // // // // // // // // // // // // // // // // // // // //       worktime_from: "",
// // // // // // // // // // // // // // // // // // // // // // //       worktime_to: "",
// // // // // // // // // // // // // // // // // // // // // // //       workdays: [],
// // // // // // // // // // // // // // // // // // // // // // //     });
// // // // // // // // // // // // // // // // // // // // // // //     setSelectedWorkdays([]);
// // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // //   const handleEdit = (entityId) => {
// // // // // // // // // // // // // // // // // // // // // // //     const entityToEdit = entities.find((entity) => entity.id === entityId);
// // // // // // // // // // // // // // // // // // // // // // //     if (entityToEdit) {
// // // // // // // // // // // // // // // // // // // // // // //       setFormData({ ...entityToEdit });
// // // // // // // // // // // // // // // // // // // // // // //       setSelectedWorkdays(entityToEdit.workdays || []);
// // // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // //   const handleDelete = (entityId) => {
// // // // // // // // // // // // // // // // // // // // // // //     const updatedEntities = entities.filter((entity) => entity.id !== entityId);
// // // // // // // // // // // // // // // // // // // // // // //     setEntities(updatedEntities);
// // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // //   const handleSave = async (editedEntity) => {
// // // // // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // // // // //       const workdaysString = editedEntity.workdays.join(", ");
// // // // // // // // // // // // // // // // // // // // // // //       const { id, ...updateData } = editedEntity;

// // // // // // // // // // // // // // // // // // // // // // //       const response = await axios.post(
// // // // // // // // // // // // // // // // // // // // // // //         "http://146.190.11.26:5055/service_provider_update/v0.1",
// // // // // // // // // // // // // // // // // // // // // // //         {
// // // // // // // // // // // // // // // // // // // // // // //           service_provider_id: id,
// // // // // // // // // // // // // // // // // // // // // // //           ...updateData,
// // // // // // // // // // // // // // // // // // // // // // //           workdays: workdaysString, // Convert workdays to a string
// // // // // // // // // // // // // // // // // // // // // // //           updated_date: new Date().toISOString(),
// // // // // // // // // // // // // // // // // // // // // // //         }
// // // // // // // // // // // // // // // // // // // // // // //       );

// // // // // // // // // // // // // // // // // // // // // // //       console.log("Update Response from API:", response.data); // Debug statement

// // // // // // // // // // // // // // // // // // // // // // //       setEntities((prevEntities) =>
// // // // // // // // // // // // // // // // // // // // // // //         prevEntities.map((entity) =>
// // // // // // // // // // // // // // // // // // // // // // //           entity.id === id ? { ...updateData, id } : entity
// // // // // // // // // // // // // // // // // // // // // // //         )
// // // // // // // // // // // // // // // // // // // // // // //       );
// // // // // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // // // // //       console.error("Error updating data:", error); // Error handling
// // // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // // // // // //     <div className="service-providers">
// // // // // // // // // // // // // // // // // // // // // // //       <EntityManagement
// // // // // // // // // // // // // // // // // // // // // // //         entityName="Service Provider"
// // // // // // // // // // // // // // // // // // // // // // //         formFields={[
// // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // //             name: "service_provider_name",
// // // // // // // // // // // // // // // // // // // // // // //             label: "Service Provider Name",
// // // // // // // // // // // // // // // // // // // // // // //             type: "text",
// // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // //             name: "vas_id",
// // // // // // // // // // // // // // // // // // // // // // //             label: "VAS",
// // // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "vas",
// // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // //             name: "shop_id",
// // // // // // // // // // // // // // // // // // // // // // //             label: "Shop",
// // // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "shop",
// // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // //             name: "unit_map_id",
// // // // // // // // // // // // // // // // // // // // // // //             label: "Unit Map",
// // // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "unit_map",
// // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // //             name: "status_id",
// // // // // // // // // // // // // // // // // // // // // // //             label: "Status",
// // // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "status",
// // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // //             name: "phone_extension",
// // // // // // // // // // // // // // // // // // // // // // //             label: "Phone Extension",
// // // // // // // // // // // // // // // // // // // // // // //             type: "text",
// // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // //           { name: "phone_no", label: "Phone No", type: "text", required: true },
// // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // //             name: "domain_id",
// // // // // // // // // // // // // // // // // // // // // // //             label: "Domain",
// // // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "domain",
// // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // //             name: "worktime_from",
// // // // // // // // // // // // // // // // // // // // // // //             label: "Worktime From",
// // // // // // // // // // // // // // // // // // // // // // //             type: "time",
// // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // //             name: "worktime_to",
// // // // // // // // // // // // // // // // // // // // // // //             label: "Worktime To",
// // // // // // // // // // // // // // // // // // // // // // //             type: "time",
// // // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // //         ]}
// // // // // // // // // // // // // // // // // // // // // // //         formData={formData}
// // // // // // // // // // // // // // // // // // // // // // //         setFormData={setFormData}
// // // // // // // // // // // // // // // // // // // // // // //         dropdownData={dropdownData}
// // // // // // // // // // // // // // // // // // // // // // //         entities={entities}
// // // // // // // // // // // // // // // // // // // // // // //         setEntities={setEntities}
// // // // // // // // // // // // // // // // // // // // // // //         showWorkdays={true}
// // // // // // // // // // // // // // // // // // // // // // //         selectedWorkdays={selectedWorkdays}
// // // // // // // // // // // // // // // // // // // // // // //         setSelectedWorkdays={setSelectedWorkdays}
// // // // // // // // // // // // // // // // // // // // // // //         handleFormSubmit={handleFormSubmit}
// // // // // // // // // // // // // // // // // // // // // // //         handleEdit={handleEdit}
// // // // // // // // // // // // // // // // // // // // // // //         handleDelete={handleDelete}
// // // // // // // // // // // // // // // // // // // // // // //       />
// // // // // // // // // // // // // // // // // // // // // // //       <EntityTable
// // // // // // // // // // // // // // // // // // // // // // //         entities={entities}
// // // // // // // // // // // // // // // // // // // // // // //         columns={[
// // // // // // // // // // // // // // // // // // // // // // //           { field: "id", label: "ID" },
// // // // // // // // // // // // // // // // // // // // // // //           { field: "service_provider_name", label: "Service Provider Name" },
// // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // //             field: "vas_id",
// // // // // // // // // // // // // // // // // // // // // // //             label: "VAS",
// // // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "vas",
// // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // //             field: "shop_id",
// // // // // // // // // // // // // // // // // // // // // // //             label: "Shop",
// // // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "shop",
// // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // //             field: "unit_map_id",
// // // // // // // // // // // // // // // // // // // // // // //             label: "Unit Map",
// // // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "unit_map",
// // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // //             field: "status_id",
// // // // // // // // // // // // // // // // // // // // // // //             label: "Status",
// // // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "status",
// // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // //           { field: "phone_extension", label: "Phone Extension" },
// // // // // // // // // // // // // // // // // // // // // // //           { field: "phone_no", label: "Phone No" },
// // // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // // //             field: "domain_id",
// // // // // // // // // // // // // // // // // // // // // // //             label: "Domain",
// // // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "domain",
// // // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // // //           { field: "workdays", label: "Workdays" },
// // // // // // // // // // // // // // // // // // // // // // //           { field: "worktime_from", label: "Worktime From" },
// // // // // // // // // // // // // // // // // // // // // // //           { field: "worktime_to", label: "Worktime To" },
// // // // // // // // // // // // // // // // // // // // // // //         ]}
// // // // // // // // // // // // // // // // // // // // // // //         onEdit={handleEdit}
// // // // // // // // // // // // // // // // // // // // // // //         onDelete={handleDelete}
// // // // // // // // // // // // // // // // // // // // // // // handleSave={handleSave}
// // // // // // // // // // // // // // // // // // // // // // // dropdownData={dropdownData}
// // // // // // // // // // // // // // // // // // // // // // //       />
// // // // // // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // // export default ServiceProviders;

// // // // // // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // // // // // // // import EntityManagement from "../../components/formTemplates/EntityManagement";
// // // // // // // // // // // // // // // // // // // // // // import EntityTable from "../../components/templateComponents/EntityTable";
// // // // // // // // // // // // // // // // // // // // // // import useDropdownFetch from "../../components/apiFetch/useDropdownFetch";

// // // // // // // // // // // // // // // // // // // // // // const loadFromLocalStorage = (key) => {
// // // // // // // // // // // // // // // // // // // // // //   const savedData = localStorage.getItem(key);
// // // // // // // // // // // // // // // // // // // // // //   return savedData ? JSON.parse(savedData) : [];
// // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // const saveToLocalStorage = (key, data) => {
// // // // // // // // // // // // // // // // // // // // // //   localStorage.setItem(key, JSON.stringify(data));
// // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // const ServiceProviders = () => {
// // // // // // // // // // // // // // // // // // // // // //   const [formData, setFormData] = useState({
// // // // // // // // // // // // // // // // // // // // // //     service_provider_name: "",
// // // // // // // // // // // // // // // // // // // // // //     vas_id: "",
// // // // // // // // // // // // // // // // // // // // // //     shop_id: "",
// // // // // // // // // // // // // // // // // // // // // //     unit_map_id: "",
// // // // // // // // // // // // // // // // // // // // // //     status_id: "",
// // // // // // // // // // // // // // // // // // // // // //     phone_extension: "",
// // // // // // // // // // // // // // // // // // // // // //     phone_no: "",
// // // // // // // // // // // // // // // // // // // // // //     domain_id: "",
// // // // // // // // // // // // // // // // // // // // // //     worktime_from: "",
// // // // // // // // // // // // // // // // // // // // // //     worktime_to: "",
// // // // // // // // // // // // // // // // // // // // // //     workdays: [],
// // // // // // // // // // // // // // // // // // // // // //   });

// // // // // // // // // // // // // // // // // // // // // //   const [entities, setEntities] = useState(loadFromLocalStorage("entities"));
// // // // // // // // // // // // // // // // // // // // // //   const [selectedWorkdays, setSelectedWorkdays] = useState([]);
// // // // // // // // // // // // // // // // // // // // // //   const [dropdownData, setDropdownData] = useState({
// // // // // // // // // // // // // // // // // // // // // //     vas: [],
// // // // // // // // // // // // // // // // // // // // // //     shop: [],
// // // // // // // // // // // // // // // // // // // // // //     unit_map: [],
// // // // // // // // // // // // // // // // // // // // // //     status: [],
// // // // // // // // // // // // // // // // // // // // // //     domain: [],
// // // // // // // // // // // // // // // // // // // // // //   });

// // // // // // // // // // // // // // // // // // // // // //   const endpoints = {
// // // // // // // // // // // // // // // // // // // // // //     vas: "http://146.190.11.26:5055/view_vas/v0.1",
// // // // // // // // // // // // // // // // // // // // // //     shop: "http://146.190.11.26:5055/shop_view/v0.1",
// // // // // // // // // // // // // // // // // // // // // //     unitMap: "http://146.190.11.26:5055/unit_bec_map_view/v0.1",
// // // // // // // // // // // // // // // // // // // // // //     status: "http://146.190.11.26:5055/view_status/v0.1",
// // // // // // // // // // // // // // // // // // // // // //     domain: "http://146.190.11.26:5055/view_domain/v0.1",
// // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // //     options: vasOptions,
// // // // // // // // // // // // // // // // // // // // // //     loading: vasLoading,
// // // // // // // // // // // // // // // // // // // // // //     error: vasError,
// // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.vas);

// // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // //     options: shopOptions,
// // // // // // // // // // // // // // // // // // // // // //     loading: shopLoading,
// // // // // // // // // // // // // // // // // // // // // //     error: shopError,
// // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.shop);

// // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // //     options: unitMapOptions,
// // // // // // // // // // // // // // // // // // // // // //     loading: unitMapLoading,
// // // // // // // // // // // // // // // // // // // // // //     error: unitMapError,
// // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.unitMap);

// // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // //     options: statusOptions,
// // // // // // // // // // // // // // // // // // // // // //     loading: statusLoading,
// // // // // // // // // // // // // // // // // // // // // //     error: statusError,
// // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.status);

// // // // // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // // // // //     options: domainOptions,
// // // // // // // // // // // // // // // // // // // // // //     loading: domainLoading,
// // // // // // // // // // // // // // // // // // // // // //     error: domainError,
// // // // // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.domain);

// // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // //     if (vasOptions)
// // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, vas: vasOptions }));
// // // // // // // // // // // // // // // // // // // // // //   }, [vasOptions]);

// // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // //     if (shopOptions)
// // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, shop: shopOptions }));
// // // // // // // // // // // // // // // // // // // // // //   }, [shopOptions]);

// // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // //     if (unitMapOptions)
// // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({
// // // // // // // // // // // // // // // // // // // // // //         ...prevData,
// // // // // // // // // // // // // // // // // // // // // //         unit_map: unitMapOptions,
// // // // // // // // // // // // // // // // // // // // // //       }));
// // // // // // // // // // // // // // // // // // // // // //   }, [unitMapOptions]);

// // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // //     if (statusOptions)
// // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, status: statusOptions }));
// // // // // // // // // // // // // // // // // // // // // //   }, [statusOptions]);

// // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // //     if (domainOptions)
// // // // // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, domain: domainOptions }));
// // // // // // // // // // // // // // // // // // // // // //   }, [domainOptions]);

// // // // // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // // // // //     saveToLocalStorage("entities", entities);
// // // // // // // // // // // // // // // // // // // // // //   }, [entities]);

// // // // // // // // // // // // // // // // // // // // // //   const handleFormSubmit = async (e) => {
// // // // // // // // // // // // // // // // // // // // // //     e.preventDefault();

// // // // // // // // // // // // // // // // // // // // // //     const formattedFormData = {
// // // // // // // // // // // // // // // // // // // // // //       ...formData,
// // // // // // // // // // // // // // // // // // // // // //       vas_id: parseInt(formData.vas_id, 10),
// // // // // // // // // // // // // // // // // // // // // //       shop_id: parseInt(formData.shop_id, 10),
// // // // // // // // // // // // // // // // // // // // // //       unit_map_id: parseInt(formData.unit_map_id, 10),
// // // // // // // // // // // // // // // // // // // // // //       status_id: parseInt(formData.status_id, 10),
// // // // // // // // // // // // // // // // // // // // // //       domain_id: parseInt(formData.domain_id, 10),
// // // // // // // // // // // // // // // // // // // // // //       workdays: selectedWorkdays.map((day) => day.toString()), // Ensure workdays is a list of strings
// // // // // // // // // // // // // // // // // // // // // //       added_date: new Date().toISOString(),
// // // // // // // // // // // // // // // // // // // // // //       updated_date: new Date().toISOString(),
// // // // // // // // // // // // // // // // // // // // // //     };

// // // // // // // // // // // // // // // // // // // // // //     console.log("Formatted Form Data on Submit:", formattedFormData); // Debug statement

// // // // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // // // //       const response = await axios.post(
// // // // // // // // // // // // // // // // // // // // // //         "http://146.190.11.26:5055/service_provider_reg/v0.1",
// // // // // // // // // // // // // // // // // // // // // //         formattedFormData
// // // // // // // // // // // // // // // // // // // // // //       );

// // // // // // // // // // // // // // // // // // // // // //       console.log("Response from API:", response.data); // Debug statement

// // // // // // // // // // // // // // // // // // // // // //       const newEntity = {
// // // // // // // // // // // // // // // // // // // // // //         id: entities.length + 1,
// // // // // // // // // // // // // // // // // // // // // //         ...formattedFormData,
// // // // // // // // // // // // // // // // // // // // // //         workdays: selectedWorkdays,
// // // // // // // // // // // // // // // // // // // // // //       };

// // // // // // // // // // // // // // // // // // // // // //       setEntities([...entities, newEntity]);
// // // // // // // // // // // // // // // // // // // // // //       resetForm();
// // // // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // // // //       console.error("Error saving data:", error); // Error handling
// // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // //   const resetForm = () => {
// // // // // // // // // // // // // // // // // // // // // //     setFormData({
// // // // // // // // // // // // // // // // // // // // // //       service_provider_name: "",
// // // // // // // // // // // // // // // // // // // // // //       vas_id: "",
// // // // // // // // // // // // // // // // // // // // // //       shop_id: "",
// // // // // // // // // // // // // // // // // // // // // //       unit_map_id: "",
// // // // // // // // // // // // // // // // // // // // // //       status_id: "",
// // // // // // // // // // // // // // // // // // // // // //       phone_extension: "",
// // // // // // // // // // // // // // // // // // // // // //       phone_no: "",
// // // // // // // // // // // // // // // // // // // // // //       domain_id: "",
// // // // // // // // // // // // // // // // // // // // // //       worktime_from: "",
// // // // // // // // // // // // // // // // // // // // // //       worktime_to: "",
// // // // // // // // // // // // // // // // // // // // // //       workdays: [],
// // // // // // // // // // // // // // // // // // // // // //     });
// // // // // // // // // // // // // // // // // // // // // //     setSelectedWorkdays([]);
// // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // //   const handleEdit = async (entityId) => {
// // // // // // // // // // // // // // // // // // // // // //     const entityToEdit = entities.find((entity) => entity.id === entityId);
// // // // // // // // // // // // // // // // // // // // // //     if (entityToEdit) {
// // // // // // // // // // // // // // // // // // // // // //       setFormData({ ...entityToEdit });
// // // // // // // // // // // // // // // // // // // // // //       setSelectedWorkdays(entityToEdit.workdays || []);
// // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // //   const handleDelete = (entityId) => {
// // // // // // // // // // // // // // // // // // // // // //     const updatedEntities = entities.filter((entity) => entity.id !== entityId);
// // // // // // // // // // // // // // // // // // // // // //     setEntities(updatedEntities);
// // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // //   const handleSave = async (editedEntity) => {
// // // // // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // // // // //       const { id, ...updateData } = editedEntity;

// // // // // // // // // // // // // // // // // // // // // //       const response = await axios.post(
// // // // // // // // // // // // // // // // // // // // // //         "http://146.190.11.26:5055/service_provider_update/v0.1",
// // // // // // // // // // // // // // // // // // // // // //         {
// // // // // // // // // // // // // // // // // // // // // //           service_provider_id: id,
// // // // // // // // // // // // // // // // // // // // // //           ...updateData,
// // // // // // // // // // // // // // // // // // // // // //           workdays: editedEntity.workdays, // Send workdays as an array
// // // // // // // // // // // // // // // // // // // // // //           updated_date: new Date().toISOString(),
// // // // // // // // // // // // // // // // // // // // // //         }
// // // // // // // // // // // // // // // // // // // // // //       );

// // // // // // // // // // // // // // // // // // // // // //       console.log("Update Response from API:", response.data); // Debug statement

// // // // // // // // // // // // // // // // // // // // // //       setEntities((prevEntities) =>
// // // // // // // // // // // // // // // // // // // // // //         prevEntities.map((entity) =>
// // // // // // // // // // // // // // // // // // // // // //           entity.id === id ? { ...updateData, id } : entity
// // // // // // // // // // // // // // // // // // // // // //         )
// // // // // // // // // // // // // // // // // // // // // //       );
// // // // // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // // // // //       console.error("Error updating data:", error); // Error handling
// // // // // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // // // // //     <div className="service-providers">
// // // // // // // // // // // // // // // // // // // // // //       <EntityManagement
// // // // // // // // // // // // // // // // // // // // // //         entityName="Service Provider"
// // // // // // // // // // // // // // // // // // // // // //         formFields={[
// // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // //             name: "service_provider_name",
// // // // // // // // // // // // // // // // // // // // // //             label: "Service Provider Name",
// // // // // // // // // // // // // // // // // // // // // //             type: "text",
// // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // //             name: "vas_id",
// // // // // // // // // // // // // // // // // // // // // //             label: "VAS",
// // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "vas",
// // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // //             name: "shop_id",
// // // // // // // // // // // // // // // // // // // // // //             label: "Shop",
// // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "shop",
// // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // //             name: "unit_map_id",
// // // // // // // // // // // // // // // // // // // // // //             label: "Unit Map",
// // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "unit_map",
// // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // //             name: "status_id",
// // // // // // // // // // // // // // // // // // // // // //             label: "Status",
// // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "status",
// // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // //             name: "phone_extension",
// // // // // // // // // // // // // // // // // // // // // //             label: "Phone Extension",
// // // // // // // // // // // // // // // // // // // // // //             type: "text",
// // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // //           { name: "phone_no", label: "Phone No", type: "text", required: true },
// // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // //             name: "domain_id",
// // // // // // // // // // // // // // // // // // // // // //             label: "Domain",
// // // // // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "domain",
// // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // //             name: "worktime_from",
// // // // // // // // // // // // // // // // // // // // // //             label: "Worktime From",
// // // // // // // // // // // // // // // // // // // // // //             type: "time",
// // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // //             name: "worktime_to",
// // // // // // // // // // // // // // // // // // // // // //             label: "Worktime To",
// // // // // // // // // // // // // // // // // // // // // //             type: "time",
// // // // // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // //         ]}
// // // // // // // // // // // // // // // // // // // // // //         formData={formData}
// // // // // // // // // // // // // // // // // // // // // //         setFormData={setFormData}
// // // // // // // // // // // // // // // // // // // // // //         dropdownData={dropdownData}
// // // // // // // // // // // // // // // // // // // // // //         entities={entities}
// // // // // // // // // // // // // // // // // // // // // //         setEntities={setEntities}
// // // // // // // // // // // // // // // // // // // // // //         showWorkdays={true}
// // // // // // // // // // // // // // // // // // // // // //         selectedWorkdays={selectedWorkdays}
// // // // // // // // // // // // // // // // // // // // // //         setSelectedWorkdays={setSelectedWorkdays}
// // // // // // // // // // // // // // // // // // // // // //         handleFormSubmit={handleFormSubmit}
// // // // // // // // // // // // // // // // // // // // // //         handleEdit={handleEdit}
// // // // // // // // // // // // // // // // // // // // // //         handleDelete={handleDelete}
// // // // // // // // // // // // // // // // // // // // // //       />
// // // // // // // // // // // // // // // // // // // // // //       <EntityTable
// // // // // // // // // // // // // // // // // // // // // //         entities={entities}
// // // // // // // // // // // // // // // // // // // // // //         columns={[
// // // // // // // // // // // // // // // // // // // // // //           { field: "id", label: "ID" },
// // // // // // // // // // // // // // // // // // // // // //           { field: "service_provider_name", label: "Service Provider Name" },
// // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // //             field: "vas_id",
// // // // // // // // // // // // // // // // // // // // // //             label: "VAS",
// // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "vas",
// // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // //             field: "shop_id",
// // // // // // // // // // // // // // // // // // // // // //             label: "Shop",
// // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "shop",
// // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // //             field: "unit_map_id",
// // // // // // // // // // // // // // // // // // // // // //             label: "Unit Map",
// // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "unit_map",
// // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // //             field: "status_id",
// // // // // // // // // // // // // // // // // // // // // //             label: "Status",
// // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "status",
// // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // //           { field: "phone_extension", label: "Phone Extension" },
// // // // // // // // // // // // // // // // // // // // // //           { field: "phone_no", label: "Phone No" },
// // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // //             field: "domain_id",
// // // // // // // // // // // // // // // // // // // // // //             label: "Domain",
// // // // // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // // // // //             dropdownKey: "domain",
// // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // //           { field: "worktime_from", label: "Worktime From" },
// // // // // // // // // // // // // // // // // // // // // //           { field: "worktime_to", label: "Worktime To" },
// // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // //             field: "workdays",
// // // // // // // // // // // // // // // // // // // // // //             label: "Workdays",
// // // // // // // // // // // // // // // // // // // // // //             type: "array",
// // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // // // // //             field: "actions",
// // // // // // // // // // // // // // // // // // // // // //             label: "Actions",
// // // // // // // // // // // // // // // // // // // // // //             type: "actions",
// // // // // // // // // // // // // // // // // // // // // //             actions: [
// // // // // // // // // // // // // // // // // // // // // //               { type: "edit", onClick: handleEdit },
// // // // // // // // // // // // // // // // // // // // // //               { type: "delete", onClick: handleDelete },
// // // // // // // // // // // // // // // // // // // // // //             ],
// // // // // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // // // // //         ]}
// // // // // // // // // // // // // // // // // // // // // //         handleEdit={handleEdit}
// // // // // // // // // // // // // // // // // // // // // //         handleDelete={handleDelete}
// // // // // // // // // // // // // // // // // // // // // //         handleSave={handleSave}
// // // // // // // // // // // // // // // // // // // // // //         dropdownData={dropdownData}
// // // // // // // // // // // // // // // // // // // // // //       />
// // // // // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // // // export default ServiceProviders;

// // // // // // // // // // // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // // // // // // // // // // import axios from "axios";
// // // // // // // // // // // // // // // // // // // import EntityManagement from "../../components/formTemplates/EntityManagement";
// // // // // // // // // // // // // // // // // // // import EntityTable from "../../components/templateComponents/EntityTable";
// // // // // // // // // // // // // // // // // // // import useDropdownFetch from "../../components/apiFetch/useDropdownFetch";

// // // // // // // // // // // // // // // // // // // const loadFromLocalStorage = (key) => {
// // // // // // // // // // // // // // // // // // //   const savedData = localStorage.getItem(key);
// // // // // // // // // // // // // // // // // // //   return savedData ? JSON.parse(savedData) : [];
// // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // const saveToLocalStorage = (key, data) => {
// // // // // // // // // // // // // // // // // // //   localStorage.setItem(key, JSON.stringify(data));
// // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // const ServiceProviders = () => {
// // // // // // // // // // // // // // // // // // //   const [formData, setFormData] = useState({
// // // // // // // // // // // // // // // // // // //     service_provider_name: "",
// // // // // // // // // // // // // // // // // // //     vas_id: "",
// // // // // // // // // // // // // // // // // // //     shop_id: "",
// // // // // // // // // // // // // // // // // // //     unit_map_id: "",
// // // // // // // // // // // // // // // // // // //     status_id: "",
// // // // // // // // // // // // // // // // // // //     phone_extension: "",
// // // // // // // // // // // // // // // // // // //     phone_no: "",
// // // // // // // // // // // // // // // // // // //     domain_id: "",
// // // // // // // // // // // // // // // // // // //     worktime_from: "",
// // // // // // // // // // // // // // // // // // //     worktime_to: "",
// // // // // // // // // // // // // // // // // // //     workdays: [],
// // // // // // // // // // // // // // // // // // //   });

// // // // // // // // // // // // // // // // // // //   const [entities, setEntities] = useState(loadFromLocalStorage("entities"));
// // // // // // // // // // // // // // // // // // //   const [selectedWorkdays, setSelectedWorkdays] = useState([]);
// // // // // // // // // // // // // // // // // // //   const [dropdownData, setDropdownData] = useState({
// // // // // // // // // // // // // // // // // // //     vas: [],
// // // // // // // // // // // // // // // // // // //     shop: [],
// // // // // // // // // // // // // // // // // // //     unit_map: [],
// // // // // // // // // // // // // // // // // // //     status: [],
// // // // // // // // // // // // // // // // // // //     domain: [],
// // // // // // // // // // // // // // // // // // //   });

// // // // // // // // // // // // // // // // // // //   const endpoints = {
// // // // // // // // // // // // // // // // // // //     vas: "http://146.190.11.26:5055/view_vas/v0.1",
// // // // // // // // // // // // // // // // // // //     shop: "http://146.190.11.26:5055/shop_view/v0.1",
// // // // // // // // // // // // // // // // // // //     unitMap: "http://146.190.11.26:5055/unit_bec_map_view/v0.1",
// // // // // // // // // // // // // // // // // // //     status: "http://146.190.11.26:5055/view_status/v0.1",
// // // // // // // // // // // // // // // // // // //     domain: "http://146.190.11.26:5055/view_domain/v0.1",
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // //     options: vasOptions,
// // // // // // // // // // // // // // // // // // //     loading: vasLoading,
// // // // // // // // // // // // // // // // // // //     error: vasError,
// // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.vas);

// // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // //     options: shopOptions,
// // // // // // // // // // // // // // // // // // //     loading: shopLoading,
// // // // // // // // // // // // // // // // // // //     error: shopError,
// // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.shop);

// // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // //     options: unitMapOptions,
// // // // // // // // // // // // // // // // // // //     loading: unitMapLoading,
// // // // // // // // // // // // // // // // // // //     error: unitMapError,
// // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.unitMap);

// // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // //     options: statusOptions,
// // // // // // // // // // // // // // // // // // //     loading: statusLoading,
// // // // // // // // // // // // // // // // // // //     error: statusError,
// // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.status);

// // // // // // // // // // // // // // // // // // //   const {
// // // // // // // // // // // // // // // // // // //     options: domainOptions,
// // // // // // // // // // // // // // // // // // //     loading: domainLoading,
// // // // // // // // // // // // // // // // // // //     error: domainError,
// // // // // // // // // // // // // // // // // // //   } = useDropdownFetch(endpoints.domain);

// // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // //     if (vasOptions)
// // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, vas: vasOptions }));
// // // // // // // // // // // // // // // // // // //   }, [vasOptions]);

// // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // //     if (shopOptions)
// // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, shop: shopOptions }));
// // // // // // // // // // // // // // // // // // //   }, [shopOptions]);

// // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // //     if (unitMapOptions)
// // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({
// // // // // // // // // // // // // // // // // // //         ...prevData,
// // // // // // // // // // // // // // // // // // //         unit_map: unitMapOptions,
// // // // // // // // // // // // // // // // // // //       }));
// // // // // // // // // // // // // // // // // // //   }, [unitMapOptions]);

// // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // //     if (statusOptions)
// // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, status: statusOptions }));
// // // // // // // // // // // // // // // // // // //   }, [statusOptions]);

// // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // //     if (domainOptions)
// // // // // // // // // // // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, domain: domainOptions }));
// // // // // // // // // // // // // // // // // // //   }, [domainOptions]);

// // // // // // // // // // // // // // // // // // //   useEffect(() => {
// // // // // // // // // // // // // // // // // // //     saveToLocalStorage("entities", entities);
// // // // // // // // // // // // // // // // // // //   }, [entities]);

// // // // // // // // // // // // // // // // // // //   const handleFormSubmit = async (e) => {
// // // // // // // // // // // // // // // // // // //     e.preventDefault();

// // // // // // // // // // // // // // // // // // //     const { added_date, updated_date, ...formattedFormData } = {
// // // // // // // // // // // // // // // // // // //       ...formData,
// // // // // // // // // // // // // // // // // // //       vas_id: parseInt(formData.vas_id, 10),
// // // // // // // // // // // // // // // // // // //       shop_id: parseInt(formData.shop_id, 10),
// // // // // // // // // // // // // // // // // // //       unit_map_id: parseInt(formData.unit_map_id, 10),
// // // // // // // // // // // // // // // // // // //       status_id: parseInt(formData.status_id, 10),
// // // // // // // // // // // // // // // // // // //       domain_id: parseInt(formData.domain_id, 10),
// // // // // // // // // // // // // // // // // // //       workdays: selectedWorkdays.map((day) => day.toString()), // Ensure workdays is a list of strings
// // // // // // // // // // // // // // // // // // //     };

// // // // // // // // // // // // // // // // // // //     console.log("Formatted Form Data on Submit:", formattedFormData); // Debug statement

// // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // //       const response = await axios.post(
// // // // // // // // // // // // // // // // // // //         "http://146.190.11.26:5055/service_provider_reg/v0.1",
// // // // // // // // // // // // // // // // // // //         formattedFormData
// // // // // // // // // // // // // // // // // // //       );

// // // // // // // // // // // // // // // // // // //       console.log("Response from API:", response.data); // Debug statement

// // // // // // // // // // // // // // // // // // //       const newEntity = {
// // // // // // // // // // // // // // // // // // //         id: entities.length + 1,
// // // // // // // // // // // // // // // // // // //         ...formattedFormData,
// // // // // // // // // // // // // // // // // // //         workdays: selectedWorkdays,
// // // // // // // // // // // // // // // // // // //       };

// // // // // // // // // // // // // // // // // // //       setEntities([...entities, newEntity]);
// // // // // // // // // // // // // // // // // // //       resetForm();
// // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // //       console.error("Error saving data:", error); // Error handling
// // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   const resetForm = () => {
// // // // // // // // // // // // // // // // // // //     setFormData({
// // // // // // // // // // // // // // // // // // //       service_provider_name: "",
// // // // // // // // // // // // // // // // // // //       vas_id: "",
// // // // // // // // // // // // // // // // // // //       shop_id: "",
// // // // // // // // // // // // // // // // // // //       unit_map_id: "",
// // // // // // // // // // // // // // // // // // //       status_id: "",
// // // // // // // // // // // // // // // // // // //       phone_extension: "",
// // // // // // // // // // // // // // // // // // //       phone_no: "",
// // // // // // // // // // // // // // // // // // //       domain_id: "",
// // // // // // // // // // // // // // // // // // //       worktime_from: "",
// // // // // // // // // // // // // // // // // // //       worktime_to: "",
// // // // // // // // // // // // // // // // // // //       workdays: [],
// // // // // // // // // // // // // // // // // // //     });
// // // // // // // // // // // // // // // // // // //     setSelectedWorkdays([]);
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   const handleEdit = async (entityId) => {
// // // // // // // // // // // // // // // // // // //     const entityToEdit = entities.find((entity) => entity.id === entityId);
// // // // // // // // // // // // // // // // // // //     if (entityToEdit) {
// // // // // // // // // // // // // // // // // // //       setFormData({ ...entityToEdit });
// // // // // // // // // // // // // // // // // // //       setSelectedWorkdays(entityToEdit.workdays || []);
// // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   const handleDelete = (entityId) => {
// // // // // // // // // // // // // // // // // // //     const updatedEntities = entities.filter((entity) => entity.id !== entityId);
// // // // // // // // // // // // // // // // // // //     setEntities(updatedEntities);
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   const handleSave = async (editedEntity) => {
// // // // // // // // // // // // // // // // // // //     try {
// // // // // // // // // // // // // // // // // // //       const { id, updated_date, added_date, ...updateData } = editedEntity;

// // // // // // // // // // // // // // // // // // //       const response = await axios.post(
// // // // // // // // // // // // // // // // // // //         "http://146.190.11.26:5055/service_provider_update/v0.1",
// // // // // // // // // // // // // // // // // // //         {
// // // // // // // // // // // // // // // // // // //           service_provider_id: id,
// // // // // // // // // // // // // // // // // // //           ...updateData,
// // // // // // // // // // // // // // // // // // //           workdays: editedEntity.workdays, // Send workdays as an array
// // // // // // // // // // // // // // // // // // //         }
// // // // // // // // // // // // // // // // // // //       );

// // // // // // // // // // // // // // // // // // //       console.log("Update Response from API:", response.data); // Debug statement

// // // // // // // // // // // // // // // // // // //       if (response.data.Status === "Success") {
// // // // // // // // // // // // // // // // // // //         setEntities((prevEntities) =>
// // // // // // // // // // // // // // // // // // //           prevEntities.map((entity) =>
// // // // // // // // // // // // // // // // // // //             entity.id === id ? { ...updateData, id } : entity
// // // // // // // // // // // // // // // // // // //           )
// // // // // // // // // // // // // // // // // // //         );
// // // // // // // // // // // // // // // // // // //       } else {
// // // // // // // // // // // // // // // // // // //         console.error("Failed to update entity:", response.data);
// // // // // // // // // // // // // // // // // // //       }
// // // // // // // // // // // // // // // // // // //     } catch (error) {
// // // // // // // // // // // // // // // // // // //       console.error("Error updating data:", error); // Error handling
// // // // // // // // // // // // // // // // // // //     }
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // //     <div className="service-providers">
// // // // // // // // // // // // // // // // // // //       <EntityManagement
// // // // // // // // // // // // // // // // // // //         entityName="Service Provider"
// // // // // // // // // // // // // // // // // // //         formFields={[
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             name: "service_provider_name",
// // // // // // // // // // // // // // // // // // //             label: "Service Provider Name",
// // // // // // // // // // // // // // // // // // //             type: "text",
// // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             name: "vas_id",
// // // // // // // // // // // // // // // // // // //             label: "VAS",
// // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // //             dropdownKey: "vas",
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             name: "shop_id",
// // // // // // // // // // // // // // // // // // //             label: "Shop",
// // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // //             dropdownKey: "shop",
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             name: "unit_map_id",
// // // // // // // // // // // // // // // // // // //             label: "Unit Map",
// // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // //             dropdownKey: "unit_map",
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             name: "status_id",
// // // // // // // // // // // // // // // // // // //             label: "Status",
// // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // //             dropdownKey: "status",
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             name: "phone_extension",
// // // // // // // // // // // // // // // // // // //             label: "Phone Extension",
// // // // // // // // // // // // // // // // // // //             type: "text",
// // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //           { name: "phone_no", label: "Phone No", type: "text", required: true },
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             name: "domain_id",
// // // // // // // // // // // // // // // // // // //             label: "Domain",
// // // // // // // // // // // // // // // // // // //             type: "select",
// // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // //             dropdownKey: "domain",
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             name: "worktime_from",
// // // // // // // // // // // // // // // // // // //             label: "Worktime From",
// // // // // // // // // // // // // // // // // // //             type: "time",
// // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             name: "worktime_to",
// // // // // // // // // // // // // // // // // // //             label: "Worktime To",
// // // // // // // // // // // // // // // // // // //             type: "time",
// // // // // // // // // // // // // // // // // // //             required: true,
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //         ]}
// // // // // // // // // // // // // // // // // // //         formData={formData}
// // // // // // // // // // // // // // // // // // //         setFormData={setFormData}
// // // // // // // // // // // // // // // // // // //         dropdownData={dropdownData}
// // // // // // // // // // // // // // // // // // //         entities={entities}
// // // // // // // // // // // // // // // // // // //         setEntities={setEntities}
// // // // // // // // // // // // // // // // // // //         showWorkdays={true}
// // // // // // // // // // // // // // // // // // //         selectedWorkdays={selectedWorkdays}
// // // // // // // // // // // // // // // // // // //         setSelectedWorkdays={setSelectedWorkdays}
// // // // // // // // // // // // // // // // // // //         handleFormSubmit={handleFormSubmit}
// // // // // // // // // // // // // // // // // // //         handleEdit={handleEdit}
// // // // // // // // // // // // // // // // // // //         handleDelete={handleDelete}
// // // // // // // // // // // // // // // // // // //       />
// // // // // // // // // // // // // // // // // // //       <EntityTable
// // // // // // // // // // // // // // // // // // //         entities={entities}
// // // // // // // // // // // // // // // // // // //         columns={[
// // // // // // // // // // // // // // // // // // //           { field: "id", label: "ID" },
// // // // // // // // // // // // // // // // // // //           { field: "service_provider_name", label: "Service Provider Name" },
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             field: "vas_id",
// // // // // // // // // // // // // // // // // // //             label: "VAS",
// // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // //             dropdownKey: "vas",
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             field: "shop_id",
// // // // // // // // // // // // // // // // // // //             label: "Shop",
// // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // //             dropdownKey: "shop",
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             field: "unit_map_id",
// // // // // // // // // // // // // // // // // // //             label: "Unit Map",
// // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // //             dropdownKey: "unit_map",
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             field: "status_id",
// // // // // // // // // // // // // // // // // // //             label: "Status",
// // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // //             dropdownKey: "status",
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             field: "phone_extension",
// // // // // // // // // // // // // // // // // // //             label: "Phone Extension",
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             field: "phone_no",
// // // // // // // // // // // // // // // // // // //             label: "Phone No",
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             field: "domain_id",
// // // // // // // // // // // // // // // // // // //             label: "Domain",
// // // // // // // // // // // // // // // // // // //             type: "dropdown",
// // // // // // // // // // // // // // // // // // //             dropdownKey: "domain",
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             field: "worktime_from",
// // // // // // // // // // // // // // // // // // //             label: "Worktime From",
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             field: "worktime_to",
// // // // // // // // // // // // // // // // // // //             label: "Worktime To",
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //           {
// // // // // // // // // // // // // // // // // // //             field: "workdays",
// // // // // // // // // // // // // // // // // // //             label: "Workdays",
// // // // // // // // // // // // // // // // // // //             render: (workdays) => workdays.join(", "), // Convert workdays to a comma-separated string
// // // // // // // // // // // // // // // // // // //           },
// // // // // // // // // // // // // // // // // // //         ]}
// // // // // // // // // // // // // // // // // // //         handleEdit={handleEdit}
// // // // // // // // // // // // // // // // // // //         handleDelete={handleDelete}
// // // // // // // // // // // // // // // // // // //         handleSave={handleSave}
// // // // // // // // // // // // // // // // // // //         dropdownData={dropdownData}
// // // // // // // // // // // // // // // // // // //       />
// // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // export default ServiceProviders;

// // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // import axios from "axios";
// // // // // // // // // // import EntityManagement from "../../components/formTemplates/EntityManagement";
// // // // // // // // // // import EntityTable from "../../components/templateComponents/EntityTable";
// // // // // // // // // // import useDropdownFetch from "../../components/apiFetch/useDropdownFetch";

// // // // // // // // // // const ServiceProviders = () => {
// // // // // // // // // //   const [formData, setFormData] = useState({
// // // // // // // // // //     service_provider_name: "",
// // // // // // // // // //     vas_id: "",
// // // // // // // // // //     shop_id: "",
// // // // // // // // // //     status_id: "",
// // // // // // // // // //     phone_extension: "",
// // // // // // // // // //     phone_no: "",
// // // // // // // // // //     domain_id: "",
// // // // // // // // // //     worktime_from: "",
// // // // // // // // // //     worktime_to: "",
// // // // // // // // // //     workdays: [],
// // // // // // // // // //   });

// // // // // // // // // //   const [entities, setEntities] = useState([]);
// // // // // // // // // //   const [selectedWorkdays, setSelectedWorkdays] = useState([]);
// // // // // // // // // //   const [dropdownData, setDropdownData] = useState({
// // // // // // // // // //     vas: [],
// // // // // // // // // //     shop: [],
// // // // // // // // // //     status: [],
// // // // // // // // // //     domain: [],
// // // // // // // // // //   });

// // // // // // // // // //   const endpoints = {
// // // // // // // // // //     vas: "http://146.190.11.26:5055/view_vas/v0.1",
// // // // // // // // // //     shop: "http://146.190.11.26:5055/shop_view/v0.1",
// // // // // // // // // //     status: "http://146.190.11.26:5055/view_status/v0.1",
// // // // // // // // // //     domain: "http://146.190.11.26:5055/view_domain/v0.1",
// // // // // // // // // //     serviceProviders:
// // // // // // // // // //       "http://146.190.11.26:5055/all_view_service_provider/v0.1",
// // // // // // // // // //   };

// // // // // // // // // //   const { options: vasOptions } = useDropdownFetch(endpoints.vas);
// // // // // // // // // //   const { options: shopOptions } = useDropdownFetch(endpoints.shop);
// // // // // // // // // //   const { options: statusOptions } = useDropdownFetch(endpoints.status);
// // // // // // // // // //   const { options: domainOptions } = useDropdownFetch(endpoints.domain);

// // // // // // // // // //   useEffect(() => {
// // // // // // // // // //     if (vasOptions)
// // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, vas: vasOptions }));
// // // // // // // // // //   }, [vasOptions]);

// // // // // // // // // //   useEffect(() => {
// // // // // // // // // //     if (shopOptions)
// // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, shop: shopOptions }));
// // // // // // // // // //   }, [shopOptions]);

// // // // // // // // // //   useEffect(() => {
// // // // // // // // // //     if (statusOptions)
// // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, status: statusOptions }));
// // // // // // // // // //   }, [statusOptions]);

// // // // // // // // // //   useEffect(() => {
// // // // // // // // // //     if (domainOptions)
// // // // // // // // // //       setDropdownData((prevData) => ({ ...prevData, domain: domainOptions }));
// // // // // // // // // //   }, [domainOptions]);

// // // // // // // // // //   useEffect(() => {
// // // // // // // // // //     const fetchEntities = async () => {
// // // // // // // // // //       try {
// // // // // // // // // //         const response = await axios.get(endpoints.serviceProviders);
// // // // // // // // // //         const fetchedEntities = response.data.Data || [];
// // // // // // // // // //         const formattedEntities = fetchedEntities.map((entity) => ({
// // // // // // // // // //           ...entity,
// // // // // // // // // //           workdays: entity.workdays ? entity.workdays.split(", ") : [], // Convert to array
// // // // // // // // // //           vas_name: entity.vas_name || "", // Add default values if necessary
// // // // // // // // // //           shop_name: entity.shop_name || "",
// // // // // // // // // //           domain_name: entity.domain_name || "",
// // // // // // // // // //           status_name: entity.status_name || "",
// // // // // // // // // //         }));
// // // // // // // // // //         setEntities(formattedEntities);
// // // // // // // // // //       } catch (error) {
// // // // // // // // // //         console.error("Error fetching service providers:", error);
// // // // // // // // // //       }
// // // // // // // // // //     };

// // // // // // // // // //     fetchEntities();
// // // // // // // // // //   }, [endpoints.serviceProviders]);

// // // // // // // // // //   const handleFormSubmit = async (e) => {
// // // // // // // // // //     e.preventDefault();
// // // // // // // // // //     const { added_date, updated_date, ...formattedFormData } = {
// // // // // // // // // //       ...formData,
// // // // // // // // // //       vas_id: parseInt(formData.vas_id, 10),
// // // // // // // // // //       shop_id: parseInt(formData.shop_id, 10),
// // // // // // // // // //       status_id: parseInt(formData.status_id, 10),
// // // // // // // // // //       domain_id: parseInt(formData.domain_id, 10),
// // // // // // // // // //       workdays: selectedWorkdays.map((day) => day.toString()),
// // // // // // // // // //     };

// // // // // // // // // //     try {
// // // // // // // // // //       const response = await axios.post(
// // // // // // // // // //         "http://146.190.11.26:5055/service_provider_reg/v0.1",
// // // // // // // // // //         formattedFormData
// // // // // // // // // //       );

// // // // // // // // // //       const newEntity = {
// // // // // // // // // //         id: entities.length + 1,
// // // // // // // // // //         ...formattedFormData,
// // // // // // // // // //         workdays: selectedWorkdays,
// // // // // // // // // //       };

// // // // // // // // // //       setEntities([...entities, newEntity]);
// // // // // // // // // //       resetForm();
// // // // // // // // // //     } catch (error) {
// // // // // // // // // //       console.error("Error saving data:", error);
// // // // // // // // // //     }
// // // // // // // // // //   };

// // // // // // // // // //   const resetForm = () => {
// // // // // // // // // //     setFormData({
// // // // // // // // // //       service_provider_name: "",
// // // // // // // // // //       vas_id: "",
// // // // // // // // // //       shop_id: "",
// // // // // // // // // //       status_id: "",
// // // // // // // // // //       phone_extension: "",
// // // // // // // // // //       phone_no: "",
// // // // // // // // // //       domain_id: "",
// // // // // // // // // //       worktime_from: "",
// // // // // // // // // //       worktime_to: "",
// // // // // // // // // //       workdays: [],
// // // // // // // // // //     });
// // // // // // // // // //     setSelectedWorkdays([]);
// // // // // // // // // //   };

// // // // // // // // // //   const handleEdit = async (entityId) => {
// // // // // // // // // //     const entityToEdit = entities.find(
// // // // // // // // // //       (entity) => entity.service_provider_id === entityId
// // // // // // // // // //     );
// // // // // // // // // //     if (entityToEdit) {
// // // // // // // // // //       setFormData({ ...entityToEdit });
// // // // // // // // // //       setSelectedWorkdays(entityToEdit.workdays || []);
// // // // // // // // // //     }
// // // // // // // // // //   };

// // // // // // // // // //   const handleDelete = (entityId) => {
// // // // // // // // // //     const updatedEntities = entities.filter(
// // // // // // // // // //       (entity) => entity.service_provider_id !== entityId
// // // // // // // // // //     );
// // // // // // // // // //     setEntities(updatedEntities);
// // // // // // // // // //   };

// // // // // // // // // //   const handleSave = async (editedEntity) => {
// // // // // // // // // //     try {
// // // // // // // // // //       const { service_provider_id, updated_date, added_date, ...updateData } =
// // // // // // // // // //         editedEntity;
// // // // // // // // // //       const response = await axios.post(
// // // // // // // // // //         "http://146.190.11.26:5055/service_provider_update/v0.1",
// // // // // // // // // //         {
// // // // // // // // // //           service_provider_id,
// // // // // // // // // //           ...updateData,
// // // // // // // // // //           workdays: editedEntity.workdays,
// // // // // // // // // //         }
// // // // // // // // // //       );

// // // // // // // // // //       if (response.data.Status === "Success") {
// // // // // // // // // //         setEntities((prevEntities) =>
// // // // // // // // // //           prevEntities.map((entity) =>
// // // // // // // // // //             entity.service_provider_id === service_provider_id
// // // // // // // // // //               ? { ...updateData, service_provider_id }
// // // // // // // // // //               : entity
// // // // // // // // // //           )
// // // // // // // // // //         );
// // // // // // // // // //       } else {
// // // // // // // // // //         console.error("Failed to update entity:", response.data);
// // // // // // // // // //       }
// // // // // // // // // //     } catch (error) {
// // // // // // // // // //       console.error("Error updating data:", error);
// // // // // // // // // //     }
// // // // // // // // // //   };

// // // // // // // // // //   const formFields = [
// // // // // // // // // //     { name: "service_provider_name", label: "Name", type: "text" },
// // // // // // // // // //     { name: "vas_id", label: "VAS", type: "select", dropdownKey: "vas" },
// // // // // // // // // //     { name: "shop_id", label: "Shop", type: "select", dropdownKey: "shop" },
// // // // // // // // // //     {
// // // // // // // // // //       name: "status_id",
// // // // // // // // // //       label: "Status",
// // // // // // // // // //       type: "select",
// // // // // // // // // //       dropdownKey: "status",
// // // // // // // // // //     },
// // // // // // // // // //     { name: "phone_extension", label: "Phone Extension", type: "text" },
// // // // // // // // // //     { name: "phone_no", label: "Phone No", type: "text" },
// // // // // // // // // //     {
// // // // // // // // // //       name: "domain_id",
// // // // // // // // // //       label: "Domain",
// // // // // // // // // //       type: "select",
// // // // // // // // // //       dropdownKey: "domain",
// // // // // // // // // //     },
// // // // // // // // // //     { name: "worktime_from", label: "Work Time From", type: "time" },
// // // // // // // // // //     { name: "worktime_to", label: "Work Time To", type: "time" },
// // // // // // // // // //     { name: "workdays", label: "Work Days", type: "checkbox" },
// // // // // // // // // //   ];

// // // // // // // // // //   const columns = [
// // // // // // // // // //     { name: "service_provider_name", label: "Name" },
// // // // // // // // // //     { name: "vas_id", label: "VAS" },
// // // // // // // // // //     { name: "shop_id", label: "Shop" },
// // // // // // // // // //     { name: "status_id", label: "Status" },
// // // // // // // // // //     { name: "phone_extension", label: "Phone Extension" },
// // // // // // // // // //     { name: "phone_no", label: "Phone No" },
// // // // // // // // // //     { name: "domain_id", label: "Domain" },
// // // // // // // // // //     { name: "worktime_from", label: "Work Time From" },
// // // // // // // // // //     { name: "worktime_to", label: "Work Time To" },
// // // // // // // // // //     { name: "workdays", label: "Work Days" },
// // // // // // // // // //   ];

// // // // // // // // // //   return (
// // // // // // // // // //     <div className="service-providers">
// // // // // // // // // //       <EntityManagement
// // // // // // // // // //         entityName="Service Provider"
// // // // // // // // // //         formFields={formFields}
// // // // // // // // // //         formData={formData}
// // // // // // // // // //         setFormData={setFormData}
// // // // // // // // // //         handleFormSubmit={handleFormSubmit}
// // // // // // // // // //         dropdownData={dropdownData}
// // // // // // // // // //         showWorkdays={true}
// // // // // // // // // //         selectedWorkdays={selectedWorkdays}
// // // // // // // // // //         setSelectedWorkdays={setSelectedWorkdays}
// // // // // // // // // //       />
// // // // // // // // // //       <EntityTable
// // // // // // // // // //         data={entities}
// // // // // // // // // //         columns={[
// // // // // // // // // //           { name: "service_provider_name", label: "Name", type: "text" },
// // // // // // // // // //           {
// // // // // // // // // //             name: "vas_name",
// // // // // // // // // //             label: "VAS",
// // // // // // // // // //             type: "select",
// // // // // // // // // //             dropdownKey: "vas",
// // // // // // // // // //           },
// // // // // // // // // //           {
// // // // // // // // // //             name: "shop_name",
// // // // // // // // // //             label: "Shop",
// // // // // // // // // //             type: "select",
// // // // // // // // // //             dropdownKey: "shop",
// // // // // // // // // //           },
// // // // // // // // // //           {
// // // // // // // // // //             name: "status_name",
// // // // // // // // // //             label: "Status",
// // // // // // // // // //             type: "select",
// // // // // // // // // //             dropdownKey: "status",
// // // // // // // // // //           },
// // // // // // // // // //           { name: "phone_extension", label: "Phone Extension", type: "text" },
// // // // // // // // // //           { name: "phone_no", label: "Phone No", type: "text" },
// // // // // // // // // //           {
// // // // // // // // // //             name: "domain_name",
// // // // // // // // // //             label: "Domain",
// // // // // // // // // //             type: "select",
// // // // // // // // // //             dropdownKey: "domain",
// // // // // // // // // //           },
// // // // // // // // // //           { name: "worktime_from", label: "Work Time From", type: "time" },
// // // // // // // // // //           { name: "worktime_to", label: "Work Time To", type: "time" },
// // // // // // // // // //           { name: "workdays", label: "Work Days", type: "checkbox-group" },
// // // // // // // // // //         ]}
// // // // // // // // // //         onEdit={handleEdit}
// // // // // // // // // //         onDelete={handleDelete}
// // // // // // // // // //         onSave={handleSave}
// // // // // // // // // //         dropdownData={dropdownData}
// // // // // // // // // //         primaryKey="service_provider_id"
// // // // // // // // // //         renderActions={(row, handleEdit, handleDeleteClick) => (
// // // // // // // // // //           <>
// // // // // // // // // //             <button onClick={() => handleEdit(row)}>Edit</button>
// // // // // // // // // //             <button onClick={() => handleDeleteClick(row.service_provider_id)}>
// // // // // // // // // //               Delete
// // // // // // // // // //             </button>
// // // // // // // // // //           </>
// // // // // // // // // //         )}
// // // // // // // // // //       />
// // // // // // // // // //     </div>
// // // // // // // // // //   );
// // // // // // // // // // };

// // // // // // // // // // export default ServiceProviders;

// // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // import axios from "axios";
// // // // // // // // import EntityManagement from "../../components/formTemplates/EntityManagement";
// // // // // // // // import EntityTable from "../../components/templateComponents/EntityTable";
// // // // // // // // import useDropdownFetch from "../../components/apiFetch/useDropdownFetch";

// // // // // // // // const ServiceProviders = () => {
// // // // // // // //   const [formData, setFormData] = useState({
// // // // // // // //     service_provider_name: "",
// // // // // // // //     vas_id: "",
// // // // // // // //     shop_id: "",
// // // // // // // //     status_id: "",
// // // // // // // //     phone_extension: "",
// // // // // // // //     phone_no: "",
// // // // // // // //     domain_id: "",
// // // // // // // //     worktime_from: "",
// // // // // // // //     worktime_to: "",
// // // // // // // //     workdays: [],
// // // // // // // //   });

// // // // // // // //   const [entities, setEntities] = useState([]);
// // // // // // // //   const [selectedWorkdays, setSelectedWorkdays] = useState([]);
// // // // // // // //   const [dropdownData, setDropdownData] = useState({
// // // // // // // //     vas: [],
// // // // // // // //     shop: [],
// // // // // // // //     status: [],
// // // // // // // //     domain: [],
// // // // // // // //   });

// // // // // // // //   const [editMode, setEditMode] = useState(false);
// // // // // // // //   const [editingEntityId, setEditingEntityId] = useState(null);

// // // // // // // //   const endpoints = {
// // // // // // // //     vas: "http://146.190.11.26:5055/view_vas/v0.1",
// // // // // // // //     shop: "http://146.190.11.26:5055/shop_view/v0.1",
// // // // // // // //     status: "http://146.190.11.26:5055/view_status/v0.1",
// // // // // // // //     domain: "http://146.190.11.26:5055/view_domain/v0.1",
// // // // // // // //     serviceProviders:
// // // // // // // //       "http://146.190.11.26:5055/all_view_service_provider/v0.1",
// // // // // // // //   };

// // // // // // // //   const { options: vasOptions } = useDropdownFetch(endpoints.vas);
// // // // // // // //   const { options: shopOptions } = useDropdownFetch(endpoints.shop);
// // // // // // // //   const { options: statusOptions } = useDropdownFetch(endpoints.status);
// // // // // // // //   const { options: domainOptions } = useDropdownFetch(endpoints.domain);

// // // // // // // //   useEffect(() => {
// // // // // // // //     if (vasOptions)
// // // // // // // //       setDropdownData((prevData) => ({ ...prevData, vas: vasOptions }));
// // // // // // // //   }, [vasOptions]);

// // // // // // // //   useEffect(() => {
// // // // // // // //     if (shopOptions)
// // // // // // // //       setDropdownData((prevData) => ({ ...prevData, shop: shopOptions }));
// // // // // // // //   }, [shopOptions]);

// // // // // // // //   useEffect(() => {
// // // // // // // //     if (statusOptions)
// // // // // // // //       setDropdownData((prevData) => ({ ...prevData, status: statusOptions }));
// // // // // // // //   }, [statusOptions]);

// // // // // // // //   useEffect(() => {
// // // // // // // //     if (domainOptions)
// // // // // // // //       setDropdownData((prevData) => ({ ...prevData, domain: domainOptions }));
// // // // // // // //   }, [domainOptions]);

// // // // // // // //   useEffect(() => {
// // // // // // // //     const fetchEntities = async () => {
// // // // // // // //       try {
// // // // // // // //         const response = await axios.get(endpoints.serviceProviders);
// // // // // // // //         const fetchedEntities = response.data.Data || [];
// // // // // // // //         const formattedEntities = fetchedEntities.map((entity) => ({
// // // // // // // //           ...entity,
// // // // // // // //           workdays: entity.workdays ? entity.workdays.split(", ") : [], // Convert to array
// // // // // // // //         }));
// // // // // // // //         setEntities(formattedEntities);
// // // // // // // //       } catch (error) {
// // // // // // // //         console.error("Error fetching service providers:", error);
// // // // // // // //       }
// // // // // // // //     };

// // // // // // // //     fetchEntities();
// // // // // // // //   }, [endpoints.serviceProviders]);

// // // // // // // //   const handleUpdate = async (entityId, updatedData) => {
// // // // // // // //     const currentEntity = entities.find(
// // // // // // // //       (entity) => entity.service_provider_id === entityId
// // // // // // // //     );

// // // // // // // //     // Log previous and updated values
// // // // // // // //     console.log("Previous Values:", currentEntity);
// // // // // // // //     console.log("Updated Values:", updatedData);

// // // // // // // //     try {
// // // // // // // //       const response = await axios.post(
// // // // // // // //         "http://146.190.11.26:5055/service_provider_update/v0.1",
// // // // // // // //         {
// // // // // // // //           service_provider_id: entityId,
// // // // // // // //           ...updatedData,
// // // // // // // //         }
// // // // // // // //       );

// // // // // // // //       if (response.data.Status === "Success") {
// // // // // // // //         setEntities((prevEntities) =>
// // // // // // // //           prevEntities.map((entity) =>
// // // // // // // //             entity.service_provider_id === entityId
// // // // // // // //               ? { ...updatedData, service_provider_id: entityId }
// // // // // // // //               : entity
// // // // // // // //           )
// // // // // // // //         );
// // // // // // // //         resetForm();
// // // // // // // //       } else {
// // // // // // // //         console.error("Failed to update entity:", response.data);
// // // // // // // //       }
// // // // // // // //     } catch (error) {
// // // // // // // //       console.error("Error updating entity:", error);
// // // // // // // //     }
// // // // // // // //   };

// // // // // // // //   const handleFormSubmit = async (e) => {
// // // // // // // //     e.preventDefault();
// // // // // // // //     const { added_date, updated_date, ...formattedFormData } = {
// // // // // // // //       ...formData,
// // // // // // // //       vas_id: parseInt(formData.vas_id, 10),
// // // // // // // //       shop_id: parseInt(formData.shop_id, 10),
// // // // // // // //       status_id: parseInt(formData.status_id, 10),
// // // // // // // //       domain_id: parseInt(formData.domain_id, 10),
// // // // // // // //       workdays: selectedWorkdays.map((day) => day.toString()),
// // // // // // // //     };

// // // // // // // //     if (editMode) {
// // // // // // // //       // Update entity
// // // // // // // //       await handleUpdate(editingEntityId, formattedFormData);
// // // // // // // //     } else {
// // // // // // // //       // Add new entity
// // // // // // // //       try {
// // // // // // // //         const response = await axios.post(
// // // // // // // //           "http://146.190.11.26:5055/service_provider_reg/v0.1",
// // // // // // // //           formattedFormData
// // // // // // // //         );

// // // // // // // //         if (response.data.Status === "Success") {
// // // // // // // //           const newEntity = {
// // // // // // // //             id: entities.length + 1,
// // // // // // // //             ...formattedFormData,
// // // // // // // //             workdays: selectedWorkdays,
// // // // // // // //           };

// // // // // // // //           setEntities([...entities, newEntity]);
// // // // // // // //           resetForm();
// // // // // // // //         } else {
// // // // // // // //           console.error("Failed to add new entity:", response.data);
// // // // // // // //         }
// // // // // // // //       } catch (error) {
// // // // // // // //         console.error("Error saving data:", error);
// // // // // // // //       }
// // // // // // // //     }
// // // // // // // //   };

// // // // // // // //   const resetForm = () => {
// // // // // // // //     setFormData({
// // // // // // // //       service_provider_name: "",
// // // // // // // //       vas_id: "",
// // // // // // // //       shop_id: "",
// // // // // // // //       status_id: "",
// // // // // // // //       phone_extension: "",
// // // // // // // //       phone_no: "",
// // // // // // // //       domain_id: "",
// // // // // // // //       worktime_from: "",
// // // // // // // //       worktime_to: "",
// // // // // // // //       workdays: [],
// // // // // // // //     });
// // // // // // // //     setSelectedWorkdays([]);
// // // // // // // //     setEditMode(false);
// // // // // // // //     setEditingEntityId(null);
// // // // // // // //   };

// // // // // // // //   const handleEdit = (entityId) => {
// // // // // // // //     const entityToEdit = entities.find(
// // // // // // // //       (entity) => entity.service_provider_id === entityId
// // // // // // // //     );
// // // // // // // //     if (entityToEdit) {
// // // // // // // //       setFormData({
// // // // // // // //         service_provider_name: entityToEdit.service_provider_name,
// // // // // // // //         vas_id: entityToEdit.vas_id,
// // // // // // // //         shop_id: entityToEdit.shop_id,
// // // // // // // //         status_id: entityToEdit.status_id,
// // // // // // // //         phone_extension: entityToEdit.phone_extension,
// // // // // // // //         phone_no: entityToEdit.phone_no,
// // // // // // // //         domain_id: entityToEdit.domain_id,
// // // // // // // //         worktime_from: entityToEdit.worktime_from,
// // // // // // // //         worktime_to: entityToEdit.worktime_to,
// // // // // // // //         workdays: entityToEdit.workdays || [],
// // // // // // // //       });
// // // // // // // //       setSelectedWorkdays(entityToEdit.workdays || []);
// // // // // // // //       setEditMode(true);
// // // // // // // //       setEditingEntityId(entityId);
// // // // // // // //     }
// // // // // // // //   };

// // // // // // // //   const handleDelete = (entityId) => {
// // // // // // // //     const updatedEntities = entities.filter(
// // // // // // // //       (entity) => entity.service_provider_id !== entityId
// // // // // // // //     );
// // // // // // // //     setEntities(updatedEntities);
// // // // // // // //   };

// // // // // // // //   const formFields = [
// // // // // // // //     { name: "service_provider_name", label: "Name", type: "text" },
// // // // // // // //     { name: "vas_id", label: "VAS", type: "select", dropdownKey: "vas" },
// // // // // // // //     { name: "shop_id", label: "Shop", type: "select", dropdownKey: "shop" },
// // // // // // // //     {
// // // // // // // //       name: "status_id",
// // // // // // // //       label: "Status",
// // // // // // // //       type: "select",
// // // // // // // //       dropdownKey: "status",
// // // // // // // //     },
// // // // // // // //     { name: "phone_extension", label: "Phone Extension", type: "text" },
// // // // // // // //     { name: "phone_no", label: "Phone No", type: "text" },
// // // // // // // //     {
// // // // // // // //       name: "domain_id",
// // // // // // // //       label: "Domain",
// // // // // // // //       type: "select",
// // // // // // // //       dropdownKey: "domain",
// // // // // // // //     },
// // // // // // // //     { name: "worktime_from", label: "Work Time From", type: "time" },
// // // // // // // //     { name: "worktime_to", label: "Work Time To", type: "time" },
// // // // // // // //     { name: "workdays", label: "Work Days", type: "checkbox" },
// // // // // // // //   ];

// // // // // // // //   const columns = [
// // // // // // // //     { name: "service_provider_id", label: "ID" }, // ID column
// // // // // // // //     { name: "service_provider_name", label: "Name" },
// // // // // // // //     { name: "vas_id", label: "VAS" },
// // // // // // // //     { name: "shop_id", label: "Shop" },
// // // // // // // //     { name: "status_id", label: "Status" },
// // // // // // // //     { name: "phone_extension", label: "Phone Extension" },
// // // // // // // //     { name: "phone_no", label: "Phone No" },
// // // // // // // //     { name: "domain_id", label: "Domain" },
// // // // // // // //     { name: "worktime_from", label: "Work Time From" },
// // // // // // // //     { name: "worktime_to", label: "Work Time To" },
// // // // // // // //     { name: "workdays", label: "Work Days" },
// // // // // // // //   ];

// // // // // // // //   return (
// // // // // // // //     <div className="service-providers">
// // // // // // // //       <EntityManagement
// // // // // // // //         entityName="Service Provider"
// // // // // // // //         formFields={formFields}
// // // // // // // //         formData={formData}
// // // // // // // //         setFormData={setFormData}
// // // // // // // //         handleFormSubmit={handleFormSubmit}
// // // // // // // //         dropdownData={dropdownData}
// // // // // // // //         showWorkdays={true}
// // // // // // // //         selectedWorkdays={selectedWorkdays}
// // // // // // // //         setSelectedWorkdays={setSelectedWorkdays}
// // // // // // // //       />
// // // // // // // //       <EntityTable
// // // // // // // //         data={entities}
// // // // // // // //         columns={[
// // // // // // // //           { name: "service_provider_name", label: "Name", type: "text" },
// // // // // // // //           {
// // // // // // // //             name: "vas_name",
// // // // // // // //             label: "VAS",
// // // // // // // //             type: "select",
// // // // // // // //             dropdownKey: "vas",
// // // // // // // //           },
// // // // // // // //           {
// // // // // // // //             name: "shop_name",
// // // // // // // //             label: "Shop",
// // // // // // // //             type: "select",
// // // // // // // //             dropdownKey: "shop",
// // // // // // // //           },
// // // // // // // //           {
// // // // // // // //             name: "status_name",
// // // // // // // //             label: "Status",
// // // // // // // //             type: "select",
// // // // // // // //             dropdownKey: "status",
// // // // // // // //           },
// // // // // // // //           { name: "phone_extension", label: "Phone Extension", type: "text" },
// // // // // // // //           { name: "phone_no", label: "Phone No", type: "text" },
// // // // // // // //           {
// // // // // // // //             name: "domain_name",
// // // // // // // //             label: "Domain",
// // // // // // // //             type: "select",
// // // // // // // //             dropdownKey: "domain",
// // // // // // // //           },
// // // // // // // //           { name: "worktime_from", label: "Work Time From", type: "time" },
// // // // // // // //           { name: "worktime_to", label: "Work Time To", type: "time" },
// // // // // // // //           { name: "workdays", label: "Work Days", type: "checkbox" },
// // // // // // // //         ]}
// // // // // // // //         onEdit={handleEdit}
// // // // // // // //         onDelete={handleDelete}
// // // // // // // //       />
// // // // // // // //     </div>
// // // // // // // //   );
// // // // // // // // };

// // // // // // // // export default ServiceProviders;

// // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // import axios from "axios";
// // // // // // // // import EntityManagement from "../../components/formTemplates/EntityManagement";
// // // // // // // // import EntityTable from "../../components/templateComponents/EntityTable";
// // // // // // // // import useDropdownFetch from "../../components/apiFetch/useDropdownFetch";

// // // // // // // // const ServiceProviders = () => {
// // // // // // // //   const [formData, setFormData] = useState({
// // // // // // // //     service_provider_name: "",
// // // // // // // //     vas_id: "",
// // // // // // // //     shop_id: "",
// // // // // // // //     status_id: "",
// // // // // // // //     phone_extension: "",
// // // // // // // //     phone_no: "",
// // // // // // // //     domain_id: "",
// // // // // // // //     worktime_from: "",
// // // // // // // //     worktime_to: "",
// // // // // // // //     workdays: [],
// // // // // // // //   });

// // // // // // // //   const [entities, setEntities] = useState([]);
// // // // // // // //   const [selectedWorkdays, setSelectedWorkdays] = useState([]);
// // // // // // // //   const [dropdownData, setDropdownData] = useState({
// // // // // // // //     vas: [],
// // // // // // // //     shop: [],
// // // // // // // //     status: [],
// // // // // // // //     domain: [],
// // // // // // // //   });

// // // // // // // //   const [editMode, setEditMode] = useState(false);
// // // // // // // //   const [editingEntityId, setEditingEntityId] = useState(null);

// // // // // // // //   const endpoints = {
// // // // // // // //     vas: "http://146.190.11.26:5055/view_vas/v0.1",
// // // // // // // //     shop: "http://146.190.11.26:5055/shop_view/v0.1",
// // // // // // // //     status: "http://146.190.11.26:5055/view_status/v0.1",
// // // // // // // //     domain: "http://146.190.11.26:5055/view_domain/v0.1",
// // // // // // // //     serviceProviders:
// // // // // // // //       "http://146.190.11.26:5055/all_view_service_provider/v0.1",
// // // // // // // //   };

// // // // // // // //   const { options: vasOptions } = useDropdownFetch(endpoints.vas);
// // // // // // // //   const { options: shopOptions } = useDropdownFetch(endpoints.shop);
// // // // // // // //   const { options: statusOptions } = useDropdownFetch(endpoints.status);
// // // // // // // //   const { options: domainOptions } = useDropdownFetch(endpoints.domain);

// // // // // // // //   useEffect(() => {
// // // // // // // //     if (vasOptions)
// // // // // // // //       setDropdownData((prevData) => ({ ...prevData, vas: vasOptions }));
// // // // // // // //   }, [vasOptions]);

// // // // // // // //   useEffect(() => {
// // // // // // // //     if (shopOptions)
// // // // // // // //       setDropdownData((prevData) => ({ ...prevData, shop: shopOptions }));
// // // // // // // //   }, [shopOptions]);

// // // // // // // //   useEffect(() => {
// // // // // // // //     if (statusOptions)
// // // // // // // //       setDropdownData((prevData) => ({ ...prevData, status: statusOptions }));
// // // // // // // //   }, [statusOptions]);

// // // // // // // //   useEffect(() => {
// // // // // // // //     if (domainOptions)
// // // // // // // //       setDropdownData((prevData) => ({ ...prevData, domain: domainOptions }));
// // // // // // // //   }, [domainOptions]);

// // // // // // // //   useEffect(() => {
// // // // // // // //     const fetchEntities = async () => {
// // // // // // // //       try {
// // // // // // // //         const response = await axios.get(endpoints.serviceProviders);
// // // // // // // //         const fetchedEntities = response.data.Data || [];
// // // // // // // //         const formattedEntities = fetchedEntities.map((entity) => ({
// // // // // // // //           ...entity,
// // // // // // // //           workdays: entity.workdays ? entity.workdays.split(", ") : [], // Convert to array
// // // // // // // //         }));
// // // // // // // //         setEntities(formattedEntities);
// // // // // // // //       } catch (error) {
// // // // // // // //         console.error("Error fetching service providers:", error);
// // // // // // // //       }
// // // // // // // //     };

// // // // // // // //     fetchEntities();
// // // // // // // //   }, [endpoints.serviceProviders]);

// // // // // // // //   const handleUpdate = async (entityId, updatedData) => {
// // // // // // // //     const currentEntity = entities.find(
// // // // // // // //       (entity) => entity.service_provider_id === entityId
// // // // // // // //     );

// // // // // // // //     // Log previous and updated values
// // // // // // // //     console.log("Previous Values:", currentEntity);
// // // // // // // //     console.log("Updated Values:", updatedData);

// // // // // // // //     try {
// // // // // // // //       const response = await axios.post(
// // // // // // // //         "http://146.190.11.26:5055/service_provider_update/v0.1",
// // // // // // // //         {
// // // // // // // //           service_provider_id: entityId,
// // // // // // // //           ...updatedData,
// // // // // // // //         }
// // // // // // // //       );

// // // // // // // //       if (response.data.Status === "Success") {
// // // // // // // //         setEntities((prevEntities) =>
// // // // // // // //           prevEntities.map((entity) =>
// // // // // // // //             entity.service_provider_id === entityId
// // // // // // // //               ? { ...updatedData, service_provider_id: entityId }
// // // // // // // //               : entity
// // // // // // // //           )
// // // // // // // //         );
// // // // // // // //         resetForm();
// // // // // // // //       } else {
// // // // // // // //         console.error("Failed to update entity:", response.data);
// // // // // // // //       }
// // // // // // // //     } catch (error) {
// // // // // // // //       console.error("Error updating entity:", error);
// // // // // // // //     }
// // // // // // // //   };

// // // // // // // //   const handleFormSubmit = async (e) => {
// // // // // // // //     e.preventDefault();
// // // // // // // //     const { added_date, updated_date, ...formattedFormData } = {
// // // // // // // //       ...formData,
// // // // // // // //       vas_id: parseInt(formData.vas_id, 10),
// // // // // // // //       shop_id: parseInt(formData.shop_id, 10),
// // // // // // // //       status_id: parseInt(formData.status_id, 10),
// // // // // // // //       domain_id: parseInt(formData.domain_id, 10),
// // // // // // // //       workdays: selectedWorkdays.map((day) => day.toString()),
// // // // // // // //     };

// // // // // // // //     if (editMode) {
// // // // // // // //       // Update entity
// // // // // // // //       await handleUpdate(editingEntityId, formattedFormData);
// // // // // // // //     } else {
// // // // // // // //       // Add new entity
// // // // // // // //       try {
// // // // // // // //         const response = await axios.post(
// // // // // // // //           "http://146.190.11.26:5055/service_provider_reg/v0.1",
// // // // // // // //           formattedFormData
// // // // // // // //         );

// // // // // // // //         if (response.data.Status === "Success") {
// // // // // // // //           const newEntity = {
// // // // // // // //             service_provider_id: response.data.service_provider_id, // Assume the response includes the new entity ID
// // // // // // // //             ...formattedFormData,
// // // // // // // //             workdays: selectedWorkdays,
// // // // // // // //           };

// // // // // // // //           setEntities([...entities, newEntity]);
// // // // // // // //           resetForm();
// // // // // // // //         } else {
// // // // // // // //           console.error("Failed to add new entity:", response.data);
// // // // // // // //         }
// // // // // // // //       } catch (error) {
// // // // // // // //         console.error("Error saving data:", error);
// // // // // // // //       }
// // // // // // // //     }
// // // // // // // //   };

// // // // // // // //   const resetForm = () => {
// // // // // // // //     setFormData({
// // // // // // // //       service_provider_name: "",
// // // // // // // //       vas_id: "",
// // // // // // // //       shop_id: "",
// // // // // // // //       status_id: "",
// // // // // // // //       phone_extension: "",
// // // // // // // //       phone_no: "",
// // // // // // // //       domain_id: "",
// // // // // // // //       worktime_from: "",
// // // // // // // //       worktime_to: "",
// // // // // // // //       workdays: [],
// // // // // // // //     });
// // // // // // // //     setSelectedWorkdays([]);
// // // // // // // //     setEditMode(false);
// // // // // // // //     setEditingEntityId(null);
// // // // // // // //   };

// // // // // // // //   const handleEdit = (entityId) => {
// // // // // // // //     const entityToEdit = entities.find(
// // // // // // // //       (entity) => entity.service_provider_id === entityId
// // // // // // // //     );
// // // // // // // //     if (entityToEdit) {
// // // // // // // //       setFormData({
// // // // // // // //         service_provider_name: entityToEdit.service_provider_name,
// // // // // // // //         vas_id: entityToEdit.vas_id,
// // // // // // // //         shop_id: entityToEdit.shop_id,
// // // // // // // //         status_id: entityToEdit.status_id,
// // // // // // // //         phone_extension: entityToEdit.phone_extension,
// // // // // // // //         phone_no: entityToEdit.phone_no,
// // // // // // // //         domain_id: entityToEdit.domain_id,
// // // // // // // //         worktime_from: entityToEdit.worktime_from,
// // // // // // // //         worktime_to: entityToEdit.worktime_to,
// // // // // // // //         workdays: entityToEdit.workdays || [],
// // // // // // // //       });
// // // // // // // //       setSelectedWorkdays(entityToEdit.workdays || []);
// // // // // // // //       setEditMode(true);
// // // // // // // //       setEditingEntityId(entityId);
// // // // // // // //     }
// // // // // // // //   };

// // // // // // // //   const handleDelete = (entityId) => {
// // // // // // // //     setEntities((prevEntities) =>
// // // // // // // //       prevEntities.filter((entity) => entity.service_provider_id !== entityId)
// // // // // // // //     );
// // // // // // // //   };

// // // // // // // //   const formFields = [
// // // // // // // //     { name: "service_provider_name", label: "Name", type: "text" },
// // // // // // // //     { name: "vas_id", label: "VAS", type: "select", dropdownKey: "vas" },
// // // // // // // //     { name: "shop_id", label: "Shop", type: "select", dropdownKey: "shop" },
// // // // // // // //     {
// // // // // // // //       name: "status_id",
// // // // // // // //       label: "Status",
// // // // // // // //       type: "select",
// // // // // // // //       dropdownKey: "status",
// // // // // // // //     },
// // // // // // // //     { name: "phone_extension", label: "Phone Extension", type: "text" },
// // // // // // // //     { name: "phone_no", label: "Phone No", type: "text" },
// // // // // // // //     {
// // // // // // // //       name: "domain_id",
// // // // // // // //       label: "Domain",
// // // // // // // //       type: "select",
// // // // // // // //       dropdownKey: "domain",
// // // // // // // //     },
// // // // // // // //     { name: "worktime_from", label: "Work Time From", type: "time" },
// // // // // // // //     { name: "worktime_to", label: "Work Time To", type: "time" },
// // // // // // // //     { name: "workdays", label: "Work Days", type: "checkbox" },
// // // // // // // //   ];

// // // // // // // //   const columns = [
// // // // // // // //     // { name: "service_provider_id", label: "ID" },
// // // // // // // //     { name: "service_provider_name", label: "Name" },
// // // // // // // //     { name: "vas_name", label: "VAS" },
// // // // // // // //     { name: "shop_name", label: "Shop" },
// // // // // // // //     { name: "status_name", label: "Status" },
// // // // // // // //     { name: "phone_extension", label: "Phone Extension" },
// // // // // // // //     { name: "phone_no", label: "Phone No" },
// // // // // // // //     { name: "domain_name", label: "Domain" },
// // // // // // // //     { name: "worktime_from", label: "Work Time From" },
// // // // // // // //     { name: "worktime_to", label: "Work Time To" },
// // // // // // // //     { name: "workdays", label: "Work Days" },
// // // // // // // //   ];

// // // // // // // //   return (
// // // // // // // //     <div className="service-providers">
// // // // // // // //       <EntityManagement
// // // // // // // //         entityName="Service Provider"
// // // // // // // //         formFields={formFields}
// // // // // // // //         formData={formData}
// // // // // // // //         setFormData={setFormData}
// // // // // // // //         handleFormSubmit={handleFormSubmit}
// // // // // // // //         dropdownData={dropdownData}
// // // // // // // //         showWorkdays={true}
// // // // // // // //         selectedWorkdays={selectedWorkdays}
// // // // // // // //         setSelectedWorkdays={setSelectedWorkdays}
// // // // // // // //       />
// // // // // // // //       <EntityTable
// // // // // // // //         data={entities}
// // // // // // // //         columns={columns}
// // // // // // // //         onEdit={handleEdit}
// // // // // // // //         onDelete={handleDelete}
// // // // // // // //       />
// // // // // // // //     </div>
// // // // // // // //   );
// // // // // // // // };

// // // // // // // // export default ServiceProviders;

// // // // import React, { useState, useEffect } from "react";
// // // // import axios from "axios";
// // // // import EntityManagement from "../../components/formTemplates/EntityManagement";
// // // // import EntityTable from "../../components/templateComponents/EntityTable";
// // // // import useDropdownFetch from "../../components/apiFetch/useDropdownFetch";

// // // // const ServiceProviders = () => {
// // // //   const [formData, setFormData] = useState({
// // // //     service_provider_name: "",
// // // //     vas_id: "",
// // // //     shop_id: "",
// // // //     status_id: "",
// // // //     phone_extension: "",
// // // //     phone_no: "",
// // // //     domain_id: "",
// // // //     worktime_from: "",
// // // //     worktime_to: "",
// // // //     workdays: [],
// // // //   });

// // // //   const [entities, setEntities] = useState([]);
// // // //   const [selectedWorkdays, setSelectedWorkdays] = useState([]);
// // // //   const [dropdownData, setDropdownData] = useState({
// // // //     vas: [],
// // // //     shop: [],
// // // //     status: [],
// // // //     domain: [],
// // // //   });

// // // //   const [editMode, setEditMode] = useState(false);
// // // //   const [editingEntityId, setEditingEntityId] = useState(null);

// // // //   const endpoints = {
// // // //     vas: "http://146.190.11.26:5055/view_vas/v0.1",
// // // //     shop: "http://146.190.11.26:5055/shop_view/v0.1",
// // // //     status: "http://146.190.11.26:5055/view_status/v0.1",
// // // //     domain: "http://146.190.11.26:5055/view_domain/v0.1",
// // // //     serviceProviders: "http://146.190.11.26:5055/view_service_provider/v0.1",
// // // //     updateServiceProvider:
// // // //       "http://146.190.11.26:5055/service_provider_update/v0.1",
// // // //   };

// // // //   const { options: vasOptions } = useDropdownFetch(endpoints.vas);
// // // //   const { options: shopOptions } = useDropdownFetch(endpoints.shop);
// // // //   const { options: statusOptions } = useDropdownFetch(endpoints.status);
// // // //   const { options: domainOptions } = useDropdownFetch(endpoints.domain);

// // // //   useEffect(() => {
// // // //     if (vasOptions)
// // // //       setDropdownData((prevData) => ({ ...prevData, vas: vasOptions }));
// // // //   }, [vasOptions]);

// // // //   useEffect(() => {
// // // //     if (shopOptions)
// // // //       setDropdownData((prevData) => ({ ...prevData, shop: shopOptions }));
// // // //   }, [shopOptions]);

// // // //   useEffect(() => {
// // // //     if (statusOptions)
// // // //       setDropdownData((prevData) => ({ ...prevData, status: statusOptions }));
// // // //   }, [statusOptions]);

// // // //   useEffect(() => {
// // // //     if (domainOptions)
// // // //       setDropdownData((prevData) => ({ ...prevData, domain: domainOptions }));
// // // //   }, [domainOptions]);

// // // //   useEffect(() => {
// // // //     const fetchEntities = async () => {
// // // //       try {
// // // //         const response = await axios.post(endpoints.serviceProviders, {
// // // //           view_type: "view all",
// // // //         });
// // // //         const fetchedEntities = response.data.Data || [];
// // // //         const formattedEntities = fetchedEntities.map((entity) => ({
// // // //           ...entity,
// // // //           workdays: entity.workdays ? entity.workdays.split(", ") : [], // Convert to array
// // // //         }));
// // // //         setEntities(formattedEntities);
// // // //       } catch (error) {
// // // //         console.error("Error fetching service providers:", error);
// // // //       }
// // // //     };

// // // //     fetchEntities();
// // // //   }, [endpoints.serviceProviders]);

// // // //   const handleUpdate = async (entityId, updatedData) => {
// // // //     const currentEntity = entities.find(
// // // //       (entity) => entity.service_provider_id === entityId
// // // //     );

// // // //     // Log previous and updated values
// // // //     console.log("Previous Values:", currentEntity);
// // // //     console.log("Updated Values:", updatedData);

// // // //     try {
// // // //       const response = await axios.post(endpoints.updateServiceProvider, {
// // // //         service_provider_id: entityId,
// // // //         ...updatedData,
// // // //       });

// // // //       if (response.data.Status === "Success") {
// // // //         setEntities((prevEntities) =>
// // // //           prevEntities.map((entity) =>
// // // //             entity.service_provider_id === entityId
// // // //               ? { ...updatedData, service_provider_id: entityId }
// // // //               : entity
// // // //           )
// // // //         );
// // // //         resetForm();
// // // //       } else {
// // // //         console.error("Failed to update entity:", response.data);
// // // //       }
// // // //     } catch (error) {
// // // //       console.error("Error updating entity:", error);
// // // //     }
// // // //   };

// // // //   const handleFormSubmit = async (e) => {
// // // //     e.preventDefault();
// // // //     const { added_date, updated_date, ...formattedFormData } = {
// // // //       ...formData,
// // // //       vas_id: parseInt(formData.vas_id, 10),
// // // //       shop_id: parseInt(formData.shop_id, 10),
// // // //       status_id: parseInt(formData.status_id, 10),
// // // //       domain_id: parseInt(formData.domain_id, 10),
// // // //       workdays: selectedWorkdays.map((day) => day.toString()),
// // // //     };

// // // //     if (editMode) {
// // // //       // Update entity
// // // //       await handleUpdate(editingEntityId, formattedFormData);
// // // //     } else {
// // // //       // Add new entity
// // // //       try {
// // // //         const response = await axios.post(
// // // //           "http://146.190.11.26:5055/service_provider_reg/v0.1",
// // // //           formattedFormData
// // // //         );

// // // //         if (response.data.Status === "Success") {
// // // //           const newEntity = {
// // // //             service_provider_id: response.data.service_provider_id, // Assume the response includes the new entity ID
// // // //             ...formattedFormData,
// // // //             workdays: selectedWorkdays,
// // // //           };

// // // //           setEntities([...entities, newEntity]);
// // // //           resetForm();
// // // //         } else {
// // // //           console.error("Failed to add new entity:", response.data);
// // // //         }
// // // //       } catch (error) {
// // // //         console.error("Error saving data:", error);
// // // //       }
// // // //     }
// // // //   };

// // // //   const resetForm = () => {
// // // //     setFormData({
// // // //       service_provider_name: "",
// // // //       vas_id: "",
// // // //       shop_id: "",
// // // //       status_id: "",
// // // //       phone_extension: "",
// // // //       phone_no: "",
// // // //       domain_id: "",
// // // //       worktime_from: "",
// // // //       worktime_to: "",
// // // //       workdays: [],
// // // //     });
// // // //     setSelectedWorkdays([]);
// // // //     setEditMode(false);
// // // //     setEditingEntityId(null);
// // // //   };

// // // //   const handleEdit = (entityId) => {
// // // //     const entityToEdit = entities.find(
// // // //       (entity) => entity.service_provider_id === entityId
// // // //     );
// // // //     if (entityToEdit) {
// // // //       setFormData({
// // // //         service_provider_name: entityToEdit.service_provider_name,
// // // //         vas_id: entityToEdit.vas_id,
// // // //         shop_id: entityToEdit.shop_id,
// // // //         status_id: entityToEdit.status_id,
// // // //         phone_extension: entityToEdit.phone_extension,
// // // //         phone_no: entityToEdit.phone_no,
// // // //         domain_id: entityToEdit.domain_id,
// // // //         worktime_from: entityToEdit.worktime_from,
// // // //         worktime_to: entityToEdit.worktime_to,
// // // //         workdays: entityToEdit.workdays || [],
// // // //       });
// // // //       setSelectedWorkdays(entityToEdit.workdays || []);
// // // //       setEditMode(true);
// // // //       setEditingEntityId(entityId);
// // // //     }
// // // //   };

// // // //   const handleDelete = async (entityId) => {
// // // //     try {
// // // //       // Find the entity to update
// // // //       const entityToUpdate = entities.find(
// // // //         (entity) => entity.service_provider_id === entityId
// // // //       );

// // // //       if (!entityToUpdate) {
// // // //         console.error("Entity not found");
// // // //         return;
// // // //       }

// // // //       // Prepare the data for the update
// // // //       const updatedData = {
// // // //         service_provider_id: entityId,
// // // //         status_id: 3, // Set status_id to 3 to mark as deleted
// // // //         vas_id: entityToUpdate.vas_id, // Include vas_id in the update request
// // // //         service_provider_name: entityToUpdate.service_provider_name,
// // // //         shop_id: entityToUpdate.shop_id,
// // // //         phone_extension: entityToUpdate.phone_extension,
// // // //         phone_no: entityToUpdate.phone_no,
// // // //         domain_id: entityToUpdate.domain_id,
// // // //         worktime_from: entityToUpdate.worktime_from,
// // // //         worktime_to: entityToUpdate.worktime_to,
// // // //         workdays: entityToUpdate.workdays,
// // // //         // Exclude unnecessary fields like vas_name and status_name
// // // //       };

// // // //       // Make the request to update the entity
// // // //       const response = await axios.post(
// // // //         endpoints.updateServiceProvider,
// // // //         updatedData
// // // //       );

// // // //       if (response.data.Status === "Success") {
// // // //         // Update the local state to reflect the change
// // // //         setEntities((prevEntities) =>
// // // //           prevEntities.map((entity) =>
// // // //             entity.service_provider_id === entityId
// // // //               ? { ...entity, status_id: 3 }
// // // //               : entity
// // // //           )
// // // //         );
// // // //       } else {
// // // //         console.error("Failed to delete entity:", response.data);
// // // //       }
// // // //     } catch (error) {
// // // //       console.error("Error deleting entity:", error);
// // // //     }
// // // //   };

// // // //   const formFields = [
// // // //     { name: "service_provider_name", label: "Name", type: "text" },
// // // //     { name: "vas_id", label: "VAS", type: "select", dropdownKey: "vas" },
// // // //     { name: "shop_id", label: "Shop", type: "select", dropdownKey: "shop" },
// // // //     {
// // // //       name: "status_id",
// // // //       label: "Status",
// // // //       type: "select",
// // // //       dropdownKey: "status",
// // // //     },
// // // //     { name: "phone_extension", label: "Phone Extension", type: "text" },
// // // //     { name: "phone_no", label: "Phone No", type: "text" },
// // // //     {
// // // //       name: "domain_id",
// // // //       label: "Domain",
// // // //       type: "select",
// // // //       dropdownKey: "domain",
// // // //     },
// // // //     { name: "worktime_from", label: "Work Time From", type: "time" },
// // // //     { name: "worktime_to", label: "Work Time To", type: "time" },
// // // //     // { name: "workdays", label: "Work Days", type: "checkbox" },
// // // //   ];

// // // //   const columns = [
// // // //     // { name: "service_provider_id", label: "ID" },
// // // //     { name: "service_provider_name", label: "Name" },
// // // //     { name: "vas_name", label: "VAS" },
// // // //     { name: "shop_name", label: "Shop" },
// // // //     { name: "status_name", label: "Status" },
// // // //     { name: "phone_extension", label: "Phone Extension" },
// // // //     { name: "phone_no", label: "Phone No" },
// // // //     { name: "domain_name", label: "Domain" },
// // // //     { name: "worktime_from", label: "Work Time From" },
// // // //     { name: "worktime_to", label: "Work Time To" },
// // // //     { name: "workdays", label: "Work Days" },
// // // //   ];

// // // //   return (
// // // //     <div className="service-providers">
// // // //       <EntityManagement
// // // //         entityName="Service Provider"
// // // //         formFields={formFields}
// // // //         formData={formData}
// // // //         setFormData={setFormData}
// // // //         handleFormSubmit={handleFormSubmit}
// // // //         dropdownData={dropdownData}
// // // //         showWorkdays={true}
// // // //         selectedWorkdays={selectedWorkdays}
// // // //         setSelectedWorkdays={setSelectedWorkdays}
// // // //       />
// // // //       <EntityTable
// // // //         data={entities}
// // // //         columns={columns}
// // // //         onEdit={handleEdit}
// // // //         onDelete={handleDelete}
// // // //         keyField="service_provider_id" // Specify the keyField here
// // // //       />
// // // //     </div>
// // // //   );
// // // // };

// // // // export default ServiceProviders;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import EntityManagement from "../../components/formTemplates/EntityManagement";
// import EntityTable from "../../components/templateComponents/EntityTable";
// import useDropdownFetch from "../../components/apiFetch/useDropdownFetch";

// const ServiceProviders = () => {
//   const [formData, setFormData] = useState({
//     service_provider_name: "",
//     vas_id: "",
//     shop_id: "",
//     status_id: "",
//     phone_extension: "",
//     phone_no: "",
//     domain_id: "",
//     worktime_from: "",
//     worktime_to: "",
//     workdays: [],
//   });

//   const [entities, setEntities] = useState([]);
//   const [selectedWorkdays, setSelectedWorkdays] = useState([]);
//   const [dropdownData, setDropdownData] = useState({
//     vas: [],
//     shop: [],
//     status: [],
//     domain: [],
//   });

//   const [editMode, setEditMode] = useState(false);
//   const [editingEntityId, setEditingEntityId] = useState(null);
//   const [isTableModalOpen, setIsTableModalOpen] = useState(false);

//   const endpoints = {
//     vas: "http://146.190.11.26:5055/view_vas/v0.1",
//     shop: "http://146.190.11.26:5055/shop_view/v0.1",
//     status: "http://146.190.11.26:5055/view_status/v0.1",
//     domain: "http://146.190.11.26:5055/view_domain/v0.1",
//     serviceProviders: "http://146.190.11.26:5055/view_service_provider/v0.1",
//     updateServiceProvider:
//       "http://146.190.11.26:5055/service_provider_update/v0.1",
//   };

//   const { options: vasOptions } = useDropdownFetch(endpoints.vas);
//   const { options: shopOptions } = useDropdownFetch(endpoints.shop);
//   const { options: statusOptions } = useDropdownFetch(endpoints.status);
//   const { options: domainOptions } = useDropdownFetch(endpoints.domain);

//   useEffect(() => {
//     if (vasOptions)
//       setDropdownData((prevData) => ({ ...prevData, vas: vasOptions }));
//   }, [vasOptions]);

//   useEffect(() => {
//     if (shopOptions)
//       setDropdownData((prevData) => ({ ...prevData, shop: shopOptions }));
//   }, [shopOptions]);

//   useEffect(() => {
//     if (statusOptions)
//       setDropdownData((prevData) => ({ ...prevData, status: statusOptions }));
//   }, [statusOptions]);

//   useEffect(() => {
//     if (domainOptions)
//       setDropdownData((prevData) => ({ ...prevData, domain: domainOptions }));
//   }, [domainOptions]);

//   useEffect(() => {
//     const fetchEntities = async () => {
//       try {
//         const response = await axios.post(endpoints.serviceProviders, {
//           view_type: "view all",
//         });
//         const fetchedEntities = response.data.Data || [];
//         const formattedEntities = fetchedEntities.map((entity) => ({
//           ...entity,
//           workdays: entity.workdays ? entity.workdays.split(", ") : [], // Convert to array
//         }));
//         setEntities(formattedEntities);
//       } catch (error) {
//         console.error("Error fetching service providers:", error);
//       }
//     };

//     fetchEntities();
//   }, [endpoints.serviceProviders]);

//   const handleUpdate = async (entityId, updatedData) => {
//     const currentEntity = entities.find(
//       (entity) => entity.service_provider_id === entityId
//     );

//     // Log previous and updated values
//     console.log("Previous Values:", currentEntity);
//     console.log("Updated Values:", updatedData);

//     try {
//       const response = await axios.post(endpoints.updateServiceProvider, {
//         service_provider_id: entityId,
//         ...updatedData,
//       });

//       if (response.data.Status === "Success") {
//         setEntities((prevEntities) =>
//           prevEntities.map((entity) =>
//             entity.service_provider_id === entityId
//               ? { ...updatedData, service_provider_id: entityId }
//               : entity
//           )
//         );
//         resetForm();
//       } else {
//         console.error("Failed to update entity:", response.data);
//       }
//     } catch (error) {
//       console.error("Error updating entity:", error);
//     }
//   };

//   const handleFormSubmit = async (e) => {
//     e.preventDefault();
//     const { added_date, updated_date, ...formattedFormData } = {
//       ...formData,
//       vas_id: parseInt(formData.vas_id, 10),
//       shop_id: parseInt(formData.shop_id, 10),
//       status_id: parseInt(formData.status_id, 10),
//       domain_id: parseInt(formData.domain_id, 10),
//       workdays: selectedWorkdays.map((day) => day.toString()),
//     };

//     if (editMode) {
//       // Update entity
//       await handleUpdate(editingEntityId, formattedFormData);
//     } else {
//       // Add new entity
//       try {
//         const response = await axios.post(
//           "http://146.190.11.26:5055/service_provider_reg/v0.1",
//           formattedFormData
//         );

//         if (response.data.Status === "Success") {
//           const newEntity = {
//             service_provider_id: response.data.service_provider_id, // Assume the response includes the new entity ID
//             ...formattedFormData,
//             workdays: selectedWorkdays,
//           };

//           setEntities([...entities, newEntity]);
//           resetForm();
//         } else {
//           console.error("Failed to add new entity:", response.data);
//         }
//       } catch (error) {
//         console.error("Error saving data:", error);
//       }
//     }
//   };

//   const resetForm = () => {
//     setFormData({
//       service_provider_name: "",
//       vas_id: "",
//       shop_id: "",
//       status_id: "",
//       phone_extension: "",
//       phone_no: "",
//       domain_id: "",
//       worktime_from: "",
//       worktime_to: "",
//       workdays: [],
//     });
//     setSelectedWorkdays([]);
//     setEditMode(false);
//     setEditingEntityId(null);
//   };

//   const handleEdit = (entityId) => {
//     const entityToEdit = entities.find(
//       (entity) => entity.service_provider_id === entityId
//     );
//     if (entityToEdit) {
//       setFormData({
//         service_provider_name: entityToEdit.service_provider_name,
//         vas_id: entityToEdit.vas_id,
//         shop_id: entityToEdit.shop_id,
//         status_id: entityToEdit.status_id,
//         phone_extension: entityToEdit.phone_extension,
//         phone_no: entityToEdit.phone_no,
//         domain_id: entityToEdit.domain_id,
//         worktime_from: entityToEdit.worktime_from,
//         worktime_to: entityToEdit.worktime_to,
//         workdays: entityToEdit.workdays || [],
//       });
//       setSelectedWorkdays(entityToEdit.workdays || []);
//       setEditMode(true);
//       setEditingEntityId(entityId);
//     }
//   };

//   const handleDelete = async (entityId) => {
//     try {
//       // Find the entity to update
//       const entityToUpdate = entities.find(
//         (entity) => entity.service_provider_id === entityId
//       );

//       if (!entityToUpdate) {
//         console.error("Entity not found");
//         return;
//       }

//       // Prepare the data for the update
//       const updatedData = {
//         service_provider_id: entityId,
//         status_id: 3, // Set status_id to 3 to mark as deleted
//         vas_id: entityToUpdate.vas_id, // Include vas_id in the update request
//         service_provider_name: entityToUpdate.service_provider_name,
//         shop_id: entityToUpdate.shop_id,
//         phone_extension: entityToUpdate.phone_extension,
//         phone_no: entityToUpdate.phone_no,
//         domain_id: entityToUpdate.domain_id,
//         worktime_from: entityToUpdate.worktime_from,
//         worktime_to: entityToUpdate.worktime_to,
//         workdays: entityToUpdate.workdays,
//         // Exclude unnecessary fields like vas_name and status_name
//       };

//       // Make the request to update the entity
//       const response = await axios.post(
//         endpoints.updateServiceProvider,
//         updatedData
//       );

//       if (response.data.Status === "Success") {
//         // Update the local state to reflect the change
//         setEntities((prevEntities) =>
//           prevEntities.map((entity) =>
//             entity.service_provider_id === entityId
//               ? { ...entity, status_id: 3 }
//               : entity
//           )
//         );
//       } else {
//         console.error("Failed to delete entity:", response.data);
//       }
//     } catch (error) {
//       console.error("Error deleting entity:", error);
//     }
//   };

//   const formFields = [
//     { name: "service_provider_name", label: "Name", type: "text" },
//     { name: "vas_id", label: "VAS", type: "select", dropdownKey: "vas" },
//     { name: "shop_id", label: "Shop", type: "select", dropdownKey: "shop" },
//     {
//       name: "status_id",
//       label: "Status",
//       type: "select",
//       dropdownKey: "status",
//     },
//     { name: "phone_extension", label: "Phone Extension", type: "text" },
//     { name: "phone_no", label: "Phone No", type: "text" },
//     {
//       name: "domain_id",
//       label: "Domain",
//       type: "select",
//       dropdownKey: "domain",
//     },
//     { name: "worktime_from", label: "Work Time From", type: "time" },
//     { name: "worktime_to", label: "Work Time To", type: "time" },
//     { name: "workdays", label: "Work Days", type: "checkbox" },
//   ];

//   const columns = [
//     // { name: "service_provider_id", label: "ID" },
//     { name: "service_provider_name", label: "Name" },
//     { name: "vas_name", label: "VAS" },
//     { name: "shop_name", label: "Shop" },
//     { name: "status_name", label: "Status" },
//     { name: "phone_extension", label: "Phone Extension" },
//     { name: "phone_no", label: "Phone No" },
//     { name: "domain_name", label: "Domain" },
//     { name: "worktime_from", label: "Work Time From" },
//     { name: "worktime_to", label: "Work Time To" },
//     { name: "workdays", label: "Work Days" },
//   ];

//   const handleViewTableClick = () => {
//     setIsTableModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsTableModalOpen(false);
//   };

//   return (
//     <div className="service-providers">
//       <EntityManagement
//         entityName="Service Provider"
//         formFields={formFields}
//         formData={formData}
//         setFormData={setFormData}
//         handleFormSubmit={handleFormSubmit}
//         dropdownData={dropdownData}
//         showWorkdays={true}
//         selectedWorkdays={selectedWorkdays}
//         setSelectedWorkdays={setSelectedWorkdays}
//       />
//       <button onClick={handleViewTableClick}>View Table</button>
//       {isTableModalOpen && (
//         <div className="modal">
//           <div className="modal-content">
//             <span className="close" onClick={closeModal}>
//               &times;
//             </span>
//             <EntityTable
//               data={entities}
//               columns={columns}
//               onEdit={handleEdit}
//               onDelete={handleDelete}
//               keyField="service_provider_id" // Specify the keyField here
//             />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ServiceProviders;
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import EntityManagement from "../../components/formTemplates/EntityManagement";
import EntityTable from "../../components/templateComponents/EntityTable";
import useDropdownFetch from "../../components/apiFetch/useDropdownFetch";

const ServiceProviders = () => {
  const [formData, setFormData] = useState({
    service_provider_name: "",
    vas_id: "",
    shop_id: "",
    status_id: "",
    phone_extension: "",
    phone_no: "",
    domain_id: "",
    worktime_from: "",
    worktime_to: "",
    workdays: [],
  });

  const [entities, setEntities] = useState([]);
  const [selectedWorkdays, setSelectedWorkdays] = useState([]);
  const [dropdownData, setDropdownData] = useState({
    vas: [],
    shop: [],
    status: [],
    domain: [],
  });

  const [editMode, setEditMode] = useState(false);
  const [editingEntityId, setEditingEntityId] = useState(null);
  const [isTableModalOpen, setIsTableModalOpen] = useState(false);

  const firstFormFieldRef = useRef(null);

  const endpoints = {
    vas: "http://146.190.11.26:5055/view_vas/v0.1",
    shop: "http://146.190.11.26:5055/shop_view/v0.1",
    status: "http://146.190.11.26:5055/view_status/v0.1",
    domain: "http://146.190.11.26:5055/view_domain/v0.1",
    serviceProviders: "http://146.190.11.26:5055/view_service_provider/v0.1",
    updateServiceProvider:
      "http://146.190.11.26:5055/service_provider_update/v0.1",
  };

  const { options: vasOptions } = useDropdownFetch(endpoints.vas);
  const { options: shopOptions } = useDropdownFetch(endpoints.shop);
  const { options: statusOptions } = useDropdownFetch(endpoints.status);
  const { options: domainOptions } = useDropdownFetch(endpoints.domain);

  useEffect(() => {
    if (vasOptions)
      setDropdownData((prevData) => ({ ...prevData, vas: vasOptions }));
  }, [vasOptions]);

  useEffect(() => {
    if (shopOptions)
      setDropdownData((prevData) => ({ ...prevData, shop: shopOptions }));
  }, [shopOptions]);

  useEffect(() => {
    if (statusOptions)
      setDropdownData((prevData) => ({ ...prevData, status: statusOptions }));
  }, [statusOptions]);

  useEffect(() => {
    if (domainOptions)
      setDropdownData((prevData) => ({ ...prevData, domain: domainOptions }));
  }, [domainOptions]);

  useEffect(() => {
    const fetchEntities = async () => {
      try {
        const response = await axios.post(endpoints.serviceProviders, {
          view_type: "view all",
        });
        const fetchedEntities = response.data.Data || [];
        const formattedEntities = fetchedEntities.map((entity) => ({
          ...entity,
          workdays: entity.workdays ? entity.workdays.split(", ") : [],
        }));
        setEntities(formattedEntities);
      } catch (error) {
        console.error("Error fetching service providers:", error);
      }
    };

    fetchEntities();
  }, [endpoints.serviceProviders]);

  const handleUpdate = async (entityId, updatedData) => {
    const currentEntity = entities.find(
      (entity) => entity.service_provider_id === entityId
    );

    console.log("Previous Values:", currentEntity);
    console.log("Updated Values:", updatedData);

    try {
      const response = await axios.post(endpoints.updateServiceProvider, {
        service_provider_id: entityId,
        ...updatedData,
      });

      if (response.data.Status === "Success") {
        setEntities((prevEntities) =>
          prevEntities.map((entity) =>
            entity.service_provider_id === entityId
              ? { ...updatedData, service_provider_id: entityId }
              : entity
          )
        );
        resetForm();
        setIsTableModalOpen(false); // Close the modal on successful update
      } else {
        console.error("Failed to update entity:", response.data);
      }
    } catch (error) {
      console.error("Error updating entity:", error);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { added_date, updated_date, ...formattedFormData } = {
      ...formData,
      vas_id: parseInt(formData.vas_id, 10),
      shop_id: parseInt(formData.shop_id, 10),
      status_id: parseInt(formData.status_id, 10),
      domain_id: parseInt(formData.domain_id, 10),
      workdays: selectedWorkdays.map((day) => day.toString()),
    };

    if (editMode) {
      await handleUpdate(editingEntityId, formattedFormData);
    } else {
      try {
        const response = await axios.post(
          "http://146.190.11.26:5055/service_provider_reg/v0.1",
          formattedFormData
        );

        if (response.data.Status === "Success") {
          const newEntity = {
            service_provider_id: response.data.service_provider_id,
            ...formattedFormData,
            workdays: selectedWorkdays,
          };

          setEntities([...entities, newEntity]);
          resetForm();
        } else {
          console.error("Failed to add new entity:", response.data);
        }
      } catch (error) {
        console.error("Error saving data:", error);
      }
    }
  };

  const resetForm = () => {
    setFormData({
      service_provider_name: "",
      vas_id: "",
      shop_id: "",
      status_id: "",
      phone_extension: "",
      phone_no: "",
      domain_id: "",
      worktime_from: "",
      worktime_to: "",
      workdays: [],
    });
    setSelectedWorkdays([]);
    setEditMode(false);
    setEditingEntityId(null);
  };

  const handleEdit = (entityId) => {
    const entityToEdit = entities.find(
      (entity) => entity.service_provider_id === entityId
    );
    if (entityToEdit) {
      setFormData({
        service_provider_name: entityToEdit.service_provider_name,
        vas_id: entityToEdit.vas_id,
        shop_id: entityToEdit.shop_id,
        status_id: entityToEdit.status_id,
        phone_extension: entityToEdit.phone_extension,
        phone_no: entityToEdit.phone_no,
        domain_id: entityToEdit.domain_id,
        worktime_from: entityToEdit.worktime_from,
        worktime_to: entityToEdit.worktime_to,
        workdays: entityToEdit.workdays || [],
      });
      setSelectedWorkdays(entityToEdit.workdays || []);
      setEditMode(true);
      setEditingEntityId(entityId);
      if (firstFormFieldRef.current) {
        firstFormFieldRef.current.focus();
      }
      setIsTableModalOpen(false); // Close the modal when starting edit
    }
  };

  const handleDelete = async (entityId) => {
    try {
      const entityToUpdate = entities.find(
        (entity) => entity.service_provider_id === entityId
      );

      if (!entityToUpdate) {
        console.error("Entity not found");
        return;
      }

      const updatedData = {
        service_provider_id: entityId,
        status_id: 3,
        vas_id: entityToUpdate.vas_id,
        service_provider_name: entityToUpdate.service_provider_name,
        shop_id: entityToUpdate.shop_id,
        phone_extension: entityToUpdate.phone_extension,
        phone_no: entityToUpdate.phone_no,
        domain_id: entityToUpdate.domain_id,
        worktime_from: entityToUpdate.worktime_from,
        worktime_to: entityToUpdate.worktime_to,
        workdays: entityToUpdate.workdays,
      };

      const response = await axios.post(
        endpoints.updateServiceProvider,
        updatedData
      );

      if (response.data.Status === "Success") {
        setEntities((prevEntities) =>
          prevEntities.map((entity) =>
            entity.service_provider_id === entityId
              ? { ...entity, status_id: 3 }
              : entity
          )
        );
      } else {
        console.error("Failed to delete entity:", response.data);
      }
    } catch (error) {
      console.error("Error deleting entity:", error);
    }
  };

  const formFields = [
    { name: "service_provider_name", label: "Name", type: "text" },
    { name: "vas_id", label: "VAS", type: "select", dropdownKey: "vas" },
    { name: "shop_id", label: "Shop", type: "select", dropdownKey: "shop" },
    {
      name: "status_id",
      label: "Status",
      type: "select",
      dropdownKey: "status",
    },
    { name: "phone_extension", label: "Phone Extension", type: "text" },
    { name: "phone_no", label: "Phone No", type: "text" },
    {
      name: "domain_id",
      label: "Domain",
      type: "select",
      dropdownKey: "domain",
    },
    { name: "worktime_from", label: "Work Time From", type: "time" },
    { name: "worktime_to", label: "Work Time To", type: "time" },
    // { name: "workdays", label: "Work Days", type: "checkbox" },
  ];

  const columns = [
    { name: "service_provider_name", label: "Name" },
    { name: "vas_name", label: "VAS" },
    { name: "shop_name", label: "Shop" },
    { name: "status_name", label: "Status" },
    { name: "phone_extension", label: "Phone Extension" },
    { name: "phone_no", label: "Phone No" },
    { name: "domain_name", label: "Domain" },
    { name: "worktime_from", label: "Work Time From" },
    { name: "worktime_to", label: "Work Time To" },
    { name: "workdays", label: "Work Days" },
  ];

  const handleViewTableClick = () => {
    setIsTableModalOpen(true);
  };

  const closeModal = () => {
    setIsTableModalOpen(false);
  };

  return (
    <div className="service-providers">
      <EntityManagement
        entityName="Service Provider"
        formFields={formFields}
        formData={formData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        dropdownData={dropdownData}
        showWorkdays={true}
        selectedWorkdays={selectedWorkdays}
        setSelectedWorkdays={setSelectedWorkdays}
        handleViewTableClick={handleViewTableClick}
        firstFormFieldRef={firstFormFieldRef}
        onEdit={handleEdit}
      />
      {isTableModalOpen && (
        <div className="modal">
          <div className="modal-content">
            {/* <span className="close" onClick={closeModal}>
              &times;
            </span> */}
            <button onClick={() => setIsTableModalOpen(false)}>Close</button>
            <EntityTable
              data={entities}
              columns={columns}
              onEdit={handleEdit}
              onDelete={handleDelete}
              keyField="service_provider_id"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceProviders;
