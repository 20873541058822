import React, { useState } from 'react';
import Namespace from '../regForms/Namespace';
import InstanceConfigurationTable from '../regForms/InstanceConfigurationTable';

const BeaconConfiguration = ({ beacons = [], namespaces = [], establishments = [], campuses = [], buildings = [], prevForm, handleFormToggle }) => {
    const [activeConfigForm, setActiveConfigForm] = useState('namespace');

    const handleConfigToggle = (formName) => {
        setActiveConfigForm(formName);
    };

    const renderConfigForm = () => {
        switch (activeConfigForm) {
            case 'namespace':
                return <Namespace />;
            case 'instance':
                return <InstanceConfigurationTable />;
            default:
                return null;
        }
    };

    return (
        <div>
            <div className="form-toggler">
                <button onClick={() => handleConfigToggle('namespace')} className={activeConfigForm === 'namespace' ? 'active' : ''} disabled={activeConfigForm === 'namespace'}>Namespace</button>
                <button onClick={() => handleConfigToggle('instance')} className={activeConfigForm === 'instance' ? 'active' : ''} disabled={activeConfigForm === 'instance'}>Instance</button>
            </div>
            {renderConfigForm()}
            <div className='navigation-buttons'>
                {prevForm && <button type="button" onClick={() => handleFormToggle(prevForm)}>Previous</button>}
            </div>
        </div>
    );
};

export default BeaconConfiguration;
