import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import EntityManagement from "../../components/formTemplates/EntityManagement";
import EntityTable from "../../components/templateComponents/EntityTable";
import useDropdownFetch from "../../components/apiFetch/useDropdownFetch";

const Floors = () => {
  const [formData, setFormData] = useState({
    establishment_id: "",
    campusid: "",
    bldg_id: "",
    floor_no: "",
    floor_name: "",
    status_id: "",
  });

  const [entities, setEntities] = useState([]);
  const [dropdownData, setDropdownData] = useState({
    establishment_id: [],
    campusid: [],
    bldg_id: [],
    status_id: [],
  });

  const [editMode, setEditMode] = useState(false);
  const [editingEntityId, setEditingEntityId] = useState(null);
  const [isTableModalOpen, setIsTableModalOpen] = useState(false);

  const firstFormFieldRef = useRef(null);

  const endpoints = {
    establishment: "http://146.190.11.26:5055/est/v0.1",
    campus: "http://146.190.11.26:5055/campus/v0.1",
    building: "http://146.190.11.26:5055/building/v0.1",
    status: "http://146.190.11.26:5055/view_status/v0.1",
    floors: "http://146.190.11.26:5055/view_floor/v0.1",
    updateFloor: "http://146.190.11.26:5055/update_floor/v0.1",
    registerFloor: "http://146.190.11.26:5055/floor_reg/v0.1",
  };

  useEffect(() => {
    const fetchEstablishmentData = async () => {
      try {
        const response = await axios.post(endpoints.establishment, {
          area_id: 1,
          view_type: "view",
        });

        const formattedEstablishmentOptions = Object.entries(
          response.data.Data
        ).map(([value, label]) => ({
          value,
          label,
        }));

        setDropdownData((prevData) => ({
          ...prevData,
          establishment_id: formattedEstablishmentOptions,
        }));
      } catch (error) {
        console.error("Error fetching establishment data:", error);
      }
    };

    fetchEstablishmentData();
  }, [endpoints.establishment]);

  useEffect(() => {
    const fetchCampusData = async () => {
      if (!formData.establishment_id) return;

      try {
        const response = await axios.post(endpoints.campus, {
          est_id: formData.establishment_id,
          view_type: "view",
        });

        const formattedCampusOptions = Object.entries(response.data.Data).map(
          ([value, label]) => ({
            value,
            label,
          })
        );

        setDropdownData((prevData) => ({
          ...prevData,
          campusid: formattedCampusOptions,
        }));
      } catch (error) {
        console.error("Error fetching campus data:", error);
      }
    };

    fetchCampusData();
  }, [formData.establishment_id, endpoints.campus]);

  useEffect(() => {
    const fetchBuildingData = async () => {
      if (!formData.campusid) return;

      try {
        const response = await axios.post(endpoints.building, {
          campusid: formData.campusid,
          view_type: "view",
        });

        const formattedBuildingOptions = Object.entries(response.data.Data).map(
          ([value, label]) => ({
            value,
            label,
          })
        );

        setDropdownData((prevData) => ({
          ...prevData,
          bldg_id: formattedBuildingOptions,
        }));
      } catch (error) {
        console.error("Error fetching building data:", error);
      }
    };

    fetchBuildingData();
  }, [formData.campusid, endpoints.building]);

  const { options: statusOptions } = useDropdownFetch(endpoints.status);

  useEffect(() => {
    if (statusOptions) {
      setDropdownData((prevData) => ({
        ...prevData,
        status_id: statusOptions,
      }));
    }
  }, [statusOptions]);

  useEffect(() => {
    const fetchEntities = async () => {
      try {
        const response = await axios.post(endpoints.floors, {
          bldg_id: formData.bldg_id || 1,
          view_type: "view all",
        });

        const formattedFloorData = response.data.Data.map((floor) => ({
          floor_id: floor.bldg_fid,
          bldg_id: floor.bldg_id,
          floor_no: floor.bldg_floor,
          floor_name: floor.bldg_floorname,
          status_id: floor.status_id,
          status_name: floor.status_name,
        }));

        setEntities(formattedFloorData || []);
      } catch (error) {
        console.error("Error fetching floors:", error);
      }
    };

    fetchEntities();
  }, [formData.bldg_id, endpoints.floors]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formattedFormData = {
      floor_no: formData.floor_no,
      floor_name: formData.floor_name,
      establishment_id: parseInt(formData.establishment_id, 10),
      campusid: parseInt(formData.campusid, 10),
      bldg_id: parseInt(formData.bldg_id, 10),
      status_id: parseInt(formData.status_id, 10),
    };

    if (editMode) {
      await handleUpdate(editingEntityId, formattedFormData);
    } else {
      try {
        const response = await axios.post(
          endpoints.registerFloor,
          formattedFormData
        );
        if (response.data.Status === "Success") {
          setEntities([...entities, response.data.Data]);
          console.log("Success floor added");
          resetForm();
        }
      } catch (error) {
        console.error("Error adding floor:", error);
        alert(
          "An error occurred while adding the floor. Please check the console for more details."
        );
      }
    }
  };

  const handleUpdate = async (entityId, updatedData) => {
    try {
      const response = await axios.post(endpoints.updateFloor, {
        ...updatedData,
        floor_id: entityId,
      });

      if (response.data.Status === "Success") {
        setEntities((prevEntities) =>
          prevEntities.map((entity) =>
            entity.floor_id === entityId
              ? { ...entity, ...updatedData }
              : entity
          )
        );
        resetForm();
        setIsTableModalOpen(false);
      } else {
        console.error("Failed to update floor:", response.data);
      }
    } catch (error) {
      console.error("Error updating floor:", error);
    }
  };

  const resetForm = () => {
    setFormData({
      establishment_id: "",
      campusid: "",
      bldg_id: "",
      floor_no: "",
      floor_name: "",
      status_id: "",
    });
    setEditMode(false);
    setEditingEntityId(null);
  };

  const handleEdit = (entityId) => {
    const entityToEdit = entities.find(
      (entity) => entity.floor_id === entityId
    );
    if (entityToEdit) {
      setFormData({
        floor_no: entityToEdit.floor_no,
        floor_name: entityToEdit.floor_name,
        establishment_id: entityToEdit.establishment_id,
        campusid: entityToEdit.campusid,
        bldg_id: entityToEdit.bldg_id,
        status_id: entityToEdit.status_id,
      });
      setEditMode(true);
      setEditingEntityId(entityId);
      if (firstFormFieldRef.current) {
        firstFormFieldRef.current.focus();
      }
      setIsTableModalOpen(false);
    }
  };

  const handleDelete = async (entityId) => {
    try {
      const entityToUpdate = entities.find(
        (entity) => entity.floor_id === entityId
      );
      if (!entityToUpdate) {
        console.error("Entity not found");
        return;
      }

      const updatedData = {
        floor_id: entityId,
        status_id: 3,
        ...entityToUpdate,
      };

      const response = await axios.post(endpoints.updateFloor, updatedData);

      if (response.data.Status === "Success") {
        setEntities((prevEntities) =>
          prevEntities.map((entity) =>
            entity.floor_id === entityId ? { ...entity, status_id: 3 } : entity
          )
        );
      } else {
        console.error("Failed to delete entity:", response.data);
      }
    } catch (error) {
      console.error("Error deleting entity:", error);
    }
  };

  const formFields = [
    {
      name: "establishment_id",
      label: "Establishment",
      type: "select",
      dropdownKey: "establishment_id",
      required: true,
      placeholder: "Select Establishment",
    },
    {
      name: "campusid",
      label: "Campus",
      type: "select",
      dropdownKey: "campusid",
      required: true,
      placeholder: "Select Campus",
    },
    {
      name: "bldg_id",
      label: "Building",
      type: "select",
      dropdownKey: "bldg_id",
      required: true,
      placeholder: "Select Building",
    },
    {
      name: "floor_no",
      label: "Floor No",
      type: "number",
      required: true,
      placeholder: "Enter Floor Number",
    },
    {
      name: "floor_name",
      label: "Floor Name",
      type: "text",
      required: true,
      placeholder: "Enter Floor Name",
    },
    {
      name: "status_id",
      label: "Status",
      type: "select",
      dropdownKey: "status_id",
      required: true,
      placeholder: "Select Status",
    },
  ];

  const columns = [
    { name: "establishment_name", label: "Establishment" },
    { name: "campus_name", label: "Campus" },
    { name: "building_name", label: "Building" },
    { name: "floor_no", label: "Floor Number" },
    { name: "floor_name", label: "Floor Name" },
    { name: "status_name", label: "Status" },
  ];
  const handleViewTableClick = () => {
    setIsTableModalOpen(true);
  };

  return (
    <div>
      <EntityManagement
        entityName="Floor"
        formFields={formFields}
        formData={formData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        dropdownData={dropdownData}
        handleViewTableClick={handleViewTableClick}
        firstFormFieldRef={firstFormFieldRef}
        onEdit={handleEdit}
      />
      {isTableModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button onClick={() => setIsTableModalOpen(false)}>Close</button>
            <EntityTable
              data={entities}
              columns={columns}
              onEdit={handleEdit}
              onDelete={handleDelete}
              keyField="floor_id"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Floors;
