import React, { useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './components/commonView/Sidebar';
import TopNavbar from './components/commonView/TopNavbar';
import Administrator from './pages/Administrator/Administrator.jsx';
import Dashboard from './pages/Dashboard/Dashboard.jsx';
import Beacons from './pages/Beacons/Beacons.jsx';
import Building from './pages/Building/Building.jsx';
import WayternHubs from './pages/WayternHubs/WayternHubs.jsx';
import Merchants from './pages/Merchants/Merchants.jsx';
import Shops from './pages/Shops/Shops.jsx';
import ServiceProviders from './pages/ServiceProviders/ServiceProviders.jsx';
import SystemUsers from './pages/SystemUsers/SystemUsers.jsx';

const App = () =>  { 
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  console.log({isSidebarOpen});

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <BrowserRouter>
      <div className="App">
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
        <TopNavbar isSidebarOpen={isSidebarOpen} />
          <Routes>
            <Route path="/" element={<Administrator />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/beacons" element={<Beacons />} />
            <Route path="/building" element={<Building />} />
            <Route path="/wayternHubs" element={<WayternHubs/>}/>
            <Route path="/merchants" element={<Merchants />} />
            <Route path="/shops" element={<Shops />} />
            <Route path="/serviceProviders" element={<ServiceProviders />} />
            <Route path="/systemUsers" element={<SystemUsers />} />
          </Routes>
        </Sidebar>
      </div>
    </BrowserRouter>
  );
};

export default App;
