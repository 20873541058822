import React from 'react';
import RegistrationForm from '../formTemplates/RegistrationForm';

const BeaconType = ({ statuses = [], prevForm, nextForm, handleFormToggle }) => {
    const selectData = {
        status_id: statuses.map(status => ({ id: status.status_id, name: status.status_name })),
    };

    const formFields = [
        { name: 'bec_typ_name', label: 'Beacon Type Name', type: 'text', required: true },
        { name: 'status_id', label: 'Status', type: 'select', required: true, placeholder: 'Select Status' },
    ];

    return (
        <RegistrationForm
            formTitle="Beacon Type Registration"
            formFields={formFields}
            endpoint="/api/beaconTypes"
            selectData={selectData}
            prevForm={prevForm}
            nextForm={nextForm}
            handleFormToggle={handleFormToggle}
        />
    );
};

export default BeaconType;
