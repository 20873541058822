import React from 'react';
import EntityManagement from '../../components/formTemplates/EntityManagement';

const Merchants = () => {
    const entityName = "Merchant";
    const endpoints = {
        list: '/api/merchants',
        create: '/api/merchants',
        update: '/api/merchants',
        delete: '/api/merchants',
        dropdown: {
            status: '/api/statuses',
            domain: '/api/domains'
        }
    };

    const formFields = [
        { name: 'merchant_name', label: 'Merchant Name', type: 'text', required: true },
        { name: 'csc_id', label: 'CSC ID', type: 'text', required: true },
        { name: 'description', label: 'Description', type: 'text', required: true },
        { name: 'phone_extension', label: 'Phone Extension', type: 'text', required: true },
        { name: 'primary_phone', label: 'Primary Phone', type: 'text', required: true },
        { name: 'status_id', label: 'Status', type: 'select', required: true, dropdownKey: 'status' },
        { name: 'domain_id', label: 'Domain', type: 'select', required: true, dropdownKey: 'domain' }
    ];

    const tableColumns = [
        { field: 'merchant_id', label: 'ID' },
        { field: 'merchant_name', label: 'Merchant Name' },
        { field: 'csc_id', label: 'CSC ID' },
        { field: 'description', label: 'Description' },
        { field: 'phone_extension', label: 'Phone Extension' },
        { field: 'primary_phone', label: 'Primary Phone' },
        { field: 'status_name', label: 'Status' },
        { field: 'domain_name', label: 'Domain' } 
    ];

    return <EntityManagement entityName={entityName} endpoints={endpoints} formFields={formFields} tableColumns={tableColumns} showAttributes={false} />;
};

export default Merchants;
