import React from 'react';
import BeaconType from '../../components/regForms/BeaconType';
import BeaconRegistration from '../../components/regForms/BeaconRegistration';
import BeaconConfiguration from '../../components/regForms/BeaconConfiguration';
import CommonTemplateFormPage from '../../components/formTemplates/CommonTemplateFormPage';

const BeaconPage = () => {
  //Initial data fetchers
  const initialDataFetchers = [
    () => fetch('/api/beaconTypes').then(response => response.json()),
    () => fetch('/api/beacons').then(response => response.json()),
    () => fetch('/api/statuses').then(response => response.json()),
    () => fetch('/api/namespaces').then(response => response.json()),
  ];

  //Form components
  const formComponents = {
    beaconType: BeaconType,
    beaconRegistration: BeaconRegistration,
    beaconConfiguration: BeaconConfiguration,
  };

  //Form toggle buttons
  const formToggleButtons = [
    { name: 'beaconType', label: 'Beacon Type', formName: 'beaconType', active: true, disabled: false },
    { name: 'beaconRegistration', label: 'Beacon Registration', formName: 'beaconRegistration', active: false, disabled: false },
    { name: 'beaconConfiguration', label: 'Beacon Configuration', formName: 'beaconConfiguration', active: false, disabled: false },
  ];

  return (
    <CommonTemplateFormPage
      formComponents={formComponents}
      initialDataFetchers={initialDataFetchers}
      formToggleButtons={formToggleButtons}
    />
  );
};

export default BeaconPage;
