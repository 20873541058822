import React, { useState } from 'react';
import '../../assets/componentsCSS/RegistrationForm.css';

const RegistrationForm = ({ formTitle, formFields, endpoint, selectData, prevForm, nextForm, handleFormToggle }) => {
    const initialState = formFields.reduce((acc, field) => {
        acc[field.name] = field.type === 'file' ? null : '';
        return acc;
    }, {});

    const [formData, setFormData] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.files[0]
        }));
    };

    const handleAddNew = (field) => {
        const newValue = prompt(`Enter new value for ${field.label}:`);
        if (newValue) {
            selectData[field.name].push({ id: newValue, name: newValue });
            setFormData(prevState => ({
                ...prevState,
                [field.name]: newValue
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();

        Object.keys(formData).forEach(key => {
            formDataToSend.append(key, formData[key]);
        });

        fetch(endpoint, {
            method: 'POST',
            body: formDataToSend
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            // Handle successful response
        })
        .catch(error => {
            console.error('Error:', error);
            // Handle error
        });
    };

    return (
        <div className="registration-form">
            <h2>{formTitle}</h2>
            <form onSubmit={handleSubmit}>
                {formFields.map(field => (
                    <div className="form-group" key={field.name}>
                        <label htmlFor={field.name}>{field.label}:</label>
                        {field.type === 'select' ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <select
                                    id={field.name}
                                    name={field.name}
                                    value={formData[field.name]}
                                    onChange={handleChange}
                                    required={field.required}
                                >
                                    <option value="" disabled>{field.placeholder}</option>
                                    {selectData[field.name] && selectData[field.name].map(option => (
                                        <option key={option.id} value={option.id}>{option.name}</option>
                                    ))}
                                </select>
                                {field.allowAdd && (
                                    <button  className="add-new-button"  type="button" onClick={() => handleAddNew(field)} style={{ marginLeft: '10px' }}>
                                        Add New
                                    </button>
                                )}
                            </div>
                        ) : (
                            <input
                                id={field.name}
                                name={field.name}
                                type={field.type}
                                value={formData[field.name]}
                                onChange={field.type === 'file' ? handleFileChange : handleChange}
                                required={field.required}
                                placeholder={field.placeholder}
                            />
                        )}
                    </div>
                ))}
                 <div className='submit-button-container'>
                     <button type="submit">Register {formTitle.split(' ')[0]}</button>
                </div>
                <div className='navigation-buttons'>
                    {prevForm && <button type="button" onClick={() => handleFormToggle(prevForm)}>Previous</button>}
                    {nextForm && <button type="button" onClick={() => handleFormToggle(nextForm)}>Next</button>}
                </div>
            </form>
        </div>
    );
};

export default RegistrationForm;
