import React from 'react';
import EntityManagement from '../../components/formTemplates/EntityManagement';

const Shops = () => {
    const entityName = "Shop";

    const endpoints = {
        list: '/api/shops',
        create: '/api/shops',
        update: '/api/shops',
        delete: '/api/shops',
        dropdown: {
            domain: '/api/domains',
            merchant: '/api/merchants',
            status: '/api/statuses'
        }
    };

    const formFields = [
        { name: 'shop_name', label: 'Shop Name', type: 'text', required: true },
        { name: 'shop_logo', label: 'Shop Logo', type: 'file', accept: 'image/*' },
        { name: 'domain_id', label: 'Domain', type: 'select', required: true, dropdownKey: 'domain' },
        { name: 'merchant_id', label: 'Merchant', type: 'select', required: true, dropdownKey: 'merchant' },
        { name: 'status_id', label: 'Status', type: 'select', required: true, dropdownKey: 'status' },
        { name: 'phone_extension', label: 'Phone Extension', type: 'text', required: true },
        { name: 'phone_number', label: 'Phone Number', type: 'text', required: true },
       
    ];

    const tableColumns = [
        { field: 'shop_id', label: 'ID' },
        { field: 'shop_name', label: 'Shop Name' },
        { field: 'shop_logo', label: 'Shop Logo', render: (shop) => <img src={shop.shop_logo} alt="Shop Logo" className="shop-logo" /> },
        { field: 'domain_name', label: 'Domain' },
        { field: 'merchant_name', label: 'Merchant' },
        { field: 'phone_extension', label: 'Phone Extension' },
        { field: 'phone_number', label: 'Phone Number' },
        { field: 'workdays', label: 'Workdays', render: (shop) => shop.workdays.join(', ') },
        { field: 'worktime', label: 'Worktime', render: (shop) => `${shop.worktime_from} to ${shop.worktime_to}` },
        { field: 'status_name', label: 'Status' }
    ];

    return <EntityManagement entityName={entityName} endpoints={endpoints} formFields={formFields} tableColumns={tableColumns} showAttributes={true} showWorkdays={true} showWorktime={true} />;
};

export default Shops;
