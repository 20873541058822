import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import EntityManagement from "../../components/formTemplates/EntityManagement";
import EntityTable from "../../components/templateComponents/EntityTable";
import useDropdownFetch from "../../components/apiFetch/useDropdownFetch";

const endpoints = {
  building: "http://146.190.11.26:5055/view_building/v0.1",
  floor: "http://146.190.11.26:5055/view_floor/v0.1",
  section: "http://146.190.11.26:5055/view_section/v0.1",
  unitType: "http://146.190.11.26:5055/view_unittype/v0.1",
  status: "http://146.190.11.26:5055/view_status/v0.1",
  units: "http://146.190.11.26:5055/view_unit/v0.1",
  updateUnit: "http://146.190.11.26:5055/update_unit/v0.1",
  registerUnit: "http://146.190.11.26:5055/reg_unit/v0.1",
};

const Unit = () => {
  const [formData, setFormData] = useState({
    building_id: "",
    floor_id: "",
    section_id: "",
    unit_type_id: "",
    unit_name: "",
    sqft: "",
    status_id: "",
  });

  const [entities, setEntities] = useState([]);
  const [dropdownData, setDropdownData] = useState({
    building_id: [],
    floor_id: [],
    section_id: [],
    unit_type_id: [],
    status_id: [],
  });

  const [editMode, setEditMode] = useState(false);
  const [editingEntityId, setEditingEntityId] = useState(null);
  const [isTableModalOpen, setIsTableModalOpen] = useState(false);

  const firstFormFieldRef = useRef(null);

  useEffect(() => {
    const fetchBuildingData = async () => {
      try {
        const response = await axios.post(endpoints.building, {
          campusid: 1,
          view_type: "view",
        });

        const formattedBuildingOptions = Object.entries(response.data.Data).map(
          ([value, label]) => ({
            value,
            label,
          })
        );

        setDropdownData((prevData) => ({
          ...prevData,
          building_id: formattedBuildingOptions,
        }));
      } catch (error) {
        console.error("Error fetching building data:", error);
      }
    };

    fetchBuildingData();
  }, []);

  useEffect(() => {
    const fetchFloorData = async () => {
      if (!formData.building_id) return;

      try {
        const response = await axios.post(endpoints.floor, {
          building_id: formData.building_id,
          view_type: "view",
        });

        console.log("Floor API Response:", response.data);

        if (response.data && response.data.Data) {
          const formattedFloorOptions = Object.entries(response.data.Data).map(
            ([value, label]) => ({ value, label })
          );

          setDropdownData((prevData) => ({
            ...prevData,
            floor_id: formattedFloorOptions,
          }));
        } else {
          console.error("Unexpected response format:", response.data);
          setDropdownData((prevData) => ({
            ...prevData,
            floor_id: [],
          }));
        }
      } catch (error) {
        console.error("Error fetching floor data:", error.message || error);
      }
    };

    fetchFloorData();
  }, [formData.building_id]);

  useEffect(() => {
    const fetchSectionData = async () => {
      // if (!formData.floor_id) return;

      try {
        const response = await axios.post(endpoints.section, {
          floor_id: formData.floor_id,
          view_type: "view",
        });

        const formattedSectionOptions = Object.entries(response.data.Data).map(
          ([value, label]) => ({
            value,
            label,
          })
        );

        setDropdownData((prevData) => ({
          ...prevData,
          section_id: formattedSectionOptions,
        }));
      } catch (error) {
        console.error("Error fetching section data:", error);
      }
    };

    fetchSectionData();
  }, [formData.floor_id]);

  useEffect(() => {
    const fetchUnitTypeData = async () => {
      try {
        const response = await axios.post(endpoints.unitType, {
          view_type: "view",
        });

        if (response.data) {
          // Convert the response object to an array of { value, label } objects
          const formattedUnitTypeOptions = Object.entries(response.data).map(
            ([value, label]) => ({
              value,
              label,
            })
          );

          setDropdownData((prevData) => ({
            ...prevData,
            unit_type_id: formattedUnitTypeOptions,
          }));
        } else {
          console.error("Unexpected response format:", response.data);
          setDropdownData((prevData) => ({
            ...prevData,
            unit_type_id: [],
          }));
        }
      } catch (error) {
        console.error("Error fetching unit type data:", error);
      }
    };

    fetchUnitTypeData();
  }, []);

  const { options: statusOptions } = useDropdownFetch(endpoints.status);

  useEffect(() => {
    if (statusOptions) {
      setDropdownData((prevData) => ({
        ...prevData,
        status_id: statusOptions,
      }));
    }
  }, [statusOptions]);
  useEffect(() => {
    const fetchEntities = async () => {
      try {
        const response = await axios.post(endpoints.units, {
          view_type: "view all",
        });

        console.log("API Response:", response.data);

        if (
          response.data &&
          response.data.Data &&
          Array.isArray(response.data.Data)
        ) {
          const processedData = response.data.Data.map((unit) => ({
            ...unit,
            sqft: isNaN(unit.sqft) ? 0 : unit.sqft,
          }));

          const formattedUnitData = processedData.map((unit) => ({
            unit_id: unit.bldg_uid,
            building_id: unit.bldg_id,
            floor_id: unit.bldg_floor,
            section_id: unit.bldg_section,
            unit_type_id: unit.unittype_id,
            unit_name: unit.bldg_unitname,
            sqft: unit.sqft,
            status_id: unit.status_id,
            status_name: unit.status_name,
          }));

          setEntities(formattedUnitData);
        } else {
          console.error("Unexpected response format:", response.data);
          setEntities([]);
        }
      } catch (error) {
        console.error("Error fetching units:", error.message || error);
        setEntities([]);
      }
    };

    fetchEntities();
  }, [endpoints.units]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formattedFormData = {
      building_id: parseInt(formData.building_id, 10),
      floor_id: parseInt(formData.floor_id, 10),
      section_id: parseInt(formData.section_id, 10),
      unit_type_id: parseInt(formData.unit_type_id, 10),
      unit_name: formData.unit_name,
      sqft: formData.sqft,
      status_id: parseInt(formData.status_id, 10),
    };

    if (editMode) {
      await handleUpdate(editingEntityId, formattedFormData);
    } else {
      try {
        const response = await axios.post(
          endpoints.registerUnit,
          formattedFormData
        );
        if (response.data.Status === "Success") {
          setEntities([...entities, response.data.Data]);
          console.log("Success unit added");
          resetForm();
        }
      } catch (error) {
        console.error("Error adding unit:", error);
        alert(
          "An error occurred while adding the unit. Please check the console for more details."
        );
      }
    }
  };

  const handleUpdate = async (entityId, updatedData) => {
    try {
      const response = await axios.post(endpoints.updateUnit, {
        ...updatedData,
        unit_id: entityId,
      });

      if (response.data.Status === "Success") {
        setEntities((prevEntities) =>
          prevEntities.map((entity) =>
            entity.unit_id === entityId ? { ...entity, ...updatedData } : entity
          )
        );
        resetForm();
        setIsTableModalOpen(false);
      } else {
        console.error("Failed to update unit:", response.data);
      }
    } catch (error) {
      console.error("Error updating unit:", error);
    }
  };

  const resetForm = () => {
    setFormData({
      building_id: "",
      floor_id: "",
      section_id: "",
      unit_type_id: "",
      unit_name: "",
      sqft: "",
      status_id: "",
    });
    setEditMode(false);
    setEditingEntityId(null);
  };

  const handleEdit = (entityId) => {
    const entityToEdit = entities.find((entity) => entity.unit_id === entityId);
    if (entityToEdit) {
      setFormData({
        building_id: entityToEdit.building_id,
        floor_id: entityToEdit.floor_id,
        section_id: entityToEdit.section_id,
        unit_type_id: entityToEdit.unit_type_id,
        unit_name: entityToEdit.unit_name,
        sqft: entityToEdit.sqft,
        status_id: entityToEdit.status_id,
      });
      setEditMode(true);
      setEditingEntityId(entityId);
      if (firstFormFieldRef.current) {
        firstFormFieldRef.current.focus();
      }
      setIsTableModalOpen(false);
    }
  };

  const handleDelete = async (entityId) => {
    try {
      const entityToUpdate = entities.find(
        (entity) => entity.unit_id === entityId
      );
      if (!entityToUpdate) {
        console.error("Entity not found");
        return;
      }

      const updatedData = {
        unit_id: entityId,
        status_id: 3,
        ...entityToUpdate,
      };

      const response = await axios.post(endpoints.updateUnit, updatedData);

      if (response.data.Status === "Success") {
        setEntities((prevEntities) =>
          prevEntities.map((entity) =>
            entity.unit_id === entityId ? { ...entity, status_id: 3 } : entity
          )
        );
      } else {
        console.error("Failed to delete entity:", response.data);
      }
    } catch (error) {
      console.error("Error deleting entity:", error);
    }
  };

  const formFields = [
    {
      name: "building_id",
      label: "Building",
      type: "select",
      dropdownKey: "building_id",
      required: true,
      placeholder: "Select Building",
    },
    {
      name: "floor_id",
      label: "Floor",
      type: "select",
      dropdownKey: "floor_id",
      required: true,
      placeholder: "Select Floor",
    },
    {
      name: "section_id",
      label: "Section",
      type: "select",
      dropdownKey: "section_id",
      required: true,
      placeholder: "Select Section",
    },
    {
      name: "unit_type_id",
      label: "Unit Type",
      type: "select",
      dropdownKey: "unit_type_id",
      required: true,
      placeholder: "Select Unit Type",
    },
    {
      name: "unit_name",
      label: "Unit Name",
      type: "text",
      placeholder: "Enter Unit Name",
    },
    {
      name: "sqft",
      label: "Square Footage",
      type: "number",
      placeholder: "Enter Square Footage",
    },
    {
      name: "status_id",
      label: "Status",
      type: "select",
      dropdownKey: "status_id",
      required: true,
      placeholder: "Select Status",
    },
  ];

  const columns = [
    { name: "unit_id", label: "Unit ID" },
    { name: "building_id", label: "Building ID" },
    { name: "floor_id", label: "Floor" },
    { name: "section_id", label: "Section" },
    { name: "unit_name", label: "Unit Name" },
    { name: "unit_type_id", label: "Unit Type ID" },
    { name: "sqft", label: "Square Footage" },
    { name: "status_name", label: "Status" },
  ];

  const handleViewTableClick = () => {
    setIsTableModalOpen(true);
  };

  return (
    <div>
      <EntityManagement
        entityName="Unit"
        formFields={formFields}
        formData={formData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        dropdownData={dropdownData}
        handleViewTableClick={handleViewTableClick}
        firstFormFieldRef={firstFormFieldRef}
        onEdit={handleEdit}
      />
      {isTableModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button onClick={() => setIsTableModalOpen(false)}>Close</button>
            <EntityTable
              data={entities}
              columns={columns}
              onEdit={handleEdit}
              onDelete={handleDelete}
              keyField="unit_id"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Unit;
