import React from "react";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import QRCodeGenerator from "qrcode";

const EntityTable = ({
  data,
  columns,
  onEdit,
  onDelete,
  onGenerateQRCode,
  keyField,
  qrCodes,
}) => {
  const handleDownloadQRCode = (qrCodeValue, fileName) => {
    const canvas = document.createElement("canvas");
    QRCodeGenerator.toCanvas(
      canvas,
      qrCodeValue,
      { errorCorrectionLevel: "H" },
      (error) => {
        if (error) {
          console.error(error);
          return;
        }
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = fileName;
        link.click();
      }
    );
  };

  return (
    <div className="table-wrapper">
      <table>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.name}>{column.label}</th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row[keyField]}>
              {columns.map((column) => (
                <td key={column.name}>
                  {column.name === "bldg_qr_id" ? (
                    qrCodes[row[keyField]] ? (
                      <div>
                        <QRCode
                          value={qrCodes[row[keyField]]}
                          size={100}
                          id={`qr-code-${row[keyField]}`}
                        />
                        <button
                          onClick={() =>
                            handleDownloadQRCode(
                              qrCodes[row[keyField]],
                              `QRCode_${row[keyField]}.png`
                            )
                          }
                        >
                          Download QR Code
                        </button>
                      </div>
                    ) : (
                      <button onClick={() => onGenerateQRCode(row[keyField])}>
                        Generate QR Code
                      </button>
                    )
                  ) : (
                    row[column.name] || "N/A"
                  )}
                </td>
              ))}
              <td>
                <button onClick={() => onEdit(row[keyField])}>Edit</button>
                <button onClick={() => onDelete(row[keyField])}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

EntityTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onGenerateQRCode: PropTypes.func.isRequired,
  keyField: PropTypes.string.isRequired,
  qrCodes: PropTypes.object.isRequired,
};

export default EntityTable;
