import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import EntityManagement from "../../components/formTemplates/EntityManagement";
import EntityTable from "../../components/templateComponents/EntityTable";
import useDropdownFetch from "../../components/apiFetch/useDropdownFetch";

const Establishments = () => {
  const [formData, setFormData] = useState({
    est_name: "",
    csc_id: "",
    area_id: "",
    campusid: "",
    bldg_id: "",
    phone_extension: "",
    phone_no: "",
    status_id: "",
    logo: null,
  });

  const [entities, setEntities] = useState([]);
  const [dropdownData, setDropdownData] = useState({
    csc_id: [],
    area_id: [],
    campusid: [],
    bldg_id: [],
    status_id: [],
  });

  const [editMode, setEditMode] = useState(false);
  const [editingEntityId, setEditingEntityId] = useState(null);
  const [isTableModalOpen, setIsTableModalOpen] = useState(false);

  const firstFormFieldRef = useRef(null);

  const endpoints = {
    csc: "http://146.190.11.26:5055/city/v0.1",
    area: "http://146.190.11.26:5055/area/v0.1",
    status: "http://146.190.11.26:5055/view_status/v0.1",
    establishments: "http://146.190.11.26:5055/est/v0.1",
    updateEstablishment: "http://146.190.11.26:5055/est_update/v0.1",
  };

  const { options: statusOptions } = useDropdownFetch(endpoints.status);

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const [cityResponse] = await Promise.all([
          axios.post(endpoints.csc, {
            csc_id: 3,
            view_type: "view",
          }),
        ]);

        const formattedCityOptions = Object.entries(cityResponse.data.Data).map(
          ([value, label]) => ({ value, label })
        );

        setDropdownData((prevData) => ({
          ...prevData,
          csc_id: formattedCityOptions,
        }));
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };

    fetchDropdownData();
  }, [endpoints.csc]);

  useEffect(() => {
    if (statusOptions) {
      setDropdownData((prevData) => ({
        ...prevData,
        status_id: statusOptions,
      }));
    }
  }, [statusOptions]);

  useEffect(() => {
    const fetchAreas = async () => {
      try {
        if (formData.csc_id) {
          const areaResponse = await axios.post(endpoints.area, {
            csc_id: formData.csc_id,
            view_type: "view",
          });
          const formattedAreaOptions = Object.entries(
            areaResponse.data.Data
          ).map(([value, label]) => ({ value, label }));

          setDropdownData((prevData) => ({
            ...prevData,
            area_id: formattedAreaOptions,
          }));
        }
      } catch (error) {
        console.error("Error fetching areas:", error);
      }
    };

    fetchAreas();
  }, [formData.csc_id, endpoints.area]);

  useEffect(() => {
    const fetchEntities = async () => {
      try {
        const response = await axios.post(endpoints.establishments, {
          area_id: formData.area_id || 1,
          view_type: "view all",
        });
        setEntities(response.data.Data || []);
      } catch (error) {
        console.error("Error fetching establishments:", error);
      }
    };

    fetchEntities();
  }, [formData.area_id, endpoints.establishments]);

  const handleUpdate = async (entityId, updatedData) => {
    try {
      await axios.post(endpoints.updateEstablishment, {
        est_id: entityId,
        est_name: updatedData.est_name,
        csc_id: updatedData.csc_id,
        area_id: updatedData.area_id,
        status_id: updatedData.status_id,
        phone_no: updatedData.phone_no,
        phone_extension: updatedData.phone_extension,
        added_date: new Date().toISOString(),
      });

      setEntities((prevEntities) =>
        prevEntities.map((entity) =>
          entity.est_id === entityId
            ? { ...updatedData, est_id: entityId }
            : entity
        )
      );
      resetForm();
    } catch (error) {
      console.error("Error updating establishment:", error);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { logo, ...formattedFormData } = {
      ...formData,
      csc_id: parseInt(formData.csc_id, 10),
      area_id: parseInt(formData.area_id, 10),
      status_id: parseInt(formData.status_id, 10),
    };

    const formDataToSubmit = new FormData();
    for (const key in formattedFormData) {
      formDataToSubmit.append(key, formattedFormData[key]);
    }
    if (logo) formDataToSubmit.append("logo", logo);

    if (editMode) {
      await handleUpdate(editingEntityId, formattedFormData);
    } else {
      try {
        const response = await axios.post(
          endpoints.establishments,
          formDataToSubmit
        );
        setEntities([...entities, response.data]);
        resetForm();
      } catch (error) {
        console.error("Error adding establishment:", error);
      }
    }
  };

  const resetForm = () => {
    setFormData({
      est_name: "",
      csc_id: "",
      area_id: "",
      phone_extension: "",
      phone_no: "",
      status_id: "",
      logo: null,
    });
    setEditMode(false);
    setEditingEntityId(null);
  };

  const handleEdit = (entityId) => {
    const entityToEdit = entities.find((entity) => entity.est_id === entityId);
    if (entityToEdit) {
      setFormData({
        est_name: entityToEdit.est_name,
        csc_id: entityToEdit.csc_id,
        area_id: entityToEdit.area_id,
        phone_extension: entityToEdit.phone_extension,
        phone_no: entityToEdit.phone_no,
        status_id: entityToEdit.status_id,
        logo: null,
      });
      setEditMode(true);
      setEditingEntityId(entityId);
      if (firstFormFieldRef.current) {
        firstFormFieldRef.current.focus();
      }
      setIsTableModalOpen(false);
    }
  };

  const handleDelete = async (entityId) => {
    try {
      const entityToUpdate = entities.find(
        (entity) => entity.est_id === entityId
      );

      if (!entityToUpdate) {
        console.error("Entity not found");
        return;
      }

      const updatedData = {
        est_id: entityId,
        status_id: 3,
        est_name: entityToUpdate.est_name,
        csc_id: entityToUpdate.csc_id,
        area_id: entityToUpdate.area_id,
        phone_no: entityToUpdate.phone_no,
        phone_extension: entityToUpdate.phone_extension,
        added_date: entityToUpdate.added_date,
      };

      const response = await axios.post(
        endpoints.updateEstablishment,
        updatedData
      );

      if (response.data.Status === "Success") {
        setEntities((prevEntities) =>
          prevEntities.filter((entity) => entity.est_id !== entityId)
        );
      } else {
        console.error("Failed to delete establishment");
      }
    } catch (error) {
      console.error("Error deleting establishment:", error);
    }
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, logo: e.target.files[0] });
  };

  const handleCityChange = (e) => {
    const selectedCityId = e.target.value;
    setFormData((prevData) => ({ ...prevData, csc_id: selectedCityId }));
  };

  const formFields = [
    { name: "est_name", label: "Establishment Name", type: "text" },
    {
      name: "csc_id",
      label: "City",
      type: "select",
      dropdownKey: "csc_id",
      onChange: handleCityChange,
    },
    { name: "area_id", label: "Area", type: "select", dropdownKey: "area_id" },

    { name: "phone_extension", label: "Phone Extension", type: "text" },
    { name: "phone_no", label: "Phone Number", type: "text" },
    {
      name: "status_id",
      label: "Status",
      type: "select",
      dropdownKey: "status_id",
    },
    { name: "logo", label: "Logo", type: "file", onChange: handleFileChange },
  ];

  const columns = [
    { name: "est_name", label: "Name" },
    { name: "csc_name", label: "City" },
    { name: "area_name", label: "Area" },
    { name: "phone_extension", label: "Phone Extension" },
    { name: "phone_no", label: "Phone No" },
    { name: "status_name", label: "Status" },
    { name: "logo", label: "Logo" },
  ];

  const handleViewTableClick = () => {
    setIsTableModalOpen(true);
  };

  const closeModal = () => {
    setIsTableModalOpen(false);
  };

  return (
    <div className="establishments">
      <EntityManagement
        entityName="Establishment"
        formFields={formFields}
        formData={formData}
        onFileChange={handleFileChange}
        onCityChange={handleCityChange}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        dropdownData={dropdownData}
        handleViewTableClick={handleViewTableClick}
        firstFormFieldRef={firstFormFieldRef}
        onEdit={handleEdit}
      />
      {isTableModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button onClick={() => setIsTableModalOpen(false)}>Close</button>
            <EntityTable
              data={entities}
              columns={columns}
              onEdit={handleEdit}
              onDelete={handleDelete}
              keyField="est_id"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Establishments;
