
import React, { useState, useEffect } from 'react';
import '../../assets/componentsCSS/CommonTemplateFormPage.css';

const CommonTemplateFormPage = ({
    formComponents,
    initialDataFetchers,
    formToggleButtons,
}) => {
    const [formData, setFormData] = useState({});
    const [activeForm, setActiveForm] = useState(formToggleButtons.find(btn => btn.active).formName);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const fetchResults = await Promise.all(initialDataFetchers.map(fetcher => fetcher()));
            const fetchedData = Object.assign({}, ...fetchResults);
            setFormData(fetchedData);
        } catch (error) {
            console.error('Error fetching initial data:', error);
        }
    };

    const handleFormToggle = formName => {
        setActiveForm(formName);
    };

    return (
        <div>
            <div className="form-toggler">
                {formToggleButtons.map(button => (
                    <button
                        key={button.name}
                        onClick={() => handleFormToggle(button.formName)}
                        className={activeForm === button.formName ? 'active' : ''}
                        disabled={button.disabled}
                    >
                        {button.label}
                    </button>
                ))}
            </div>
            {activeForm && formComponents[activeForm] && (
                <div>
                    {React.createElement(formComponents[activeForm], {
                        ...formData,
                        handleFormToggle,
                        prevForm: formToggleButtons.find(btn => btn.formName === activeForm)?.prevForm,
                        nextForm: formToggleButtons.find(btn => btn.formName === activeForm)?.nextForm,
                    })}
                </div>
            )}
        </div>
    );
};

export default CommonTemplateFormPage;
