import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import RegistrationForm from '../formTemplates/RegistrationForm';
import '../../assets/componentsCSS/BeaconRegistrationForm.css';

const BeaconRegistration = ({ beaconTypes, statuses, prevForm, nextForm, handleFormToggle }) => {
    const formFields = [
        { name: 'mfd_id', label: 'Manufacturer ID', type: 'text', required: true },
        { name: 'protocol', label: 'Protocol', type: 'text', required: true },
        { name: 'bec_typ_id', label: 'Beacon Type', type: 'select', required: true, placeholder: 'Select Beacon Type' },
    ];

    const selectData = {
        bec_typ_id: beaconTypes ? beaconTypes.map(type => ({ id: type.bec_typ_id, name: type.bec_typ_name })) : [],
    };

    const [uploadedData, setUploadedData] = useState([]);
    const [isEditing, setIsEditing] = useState(null);
    const [editData, setEditData] = useState({});
    const [isEditingAll, setIsEditingAll] = useState(false);
    const [editAllData, setEditAllData] = useState([]);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
            const headers = worksheet[0];
            const rows = worksheet.slice(1).map((row) =>
                headers.reduce((obj, key, index) => {
                    obj[key] = row[index];
                    return obj;
                }, {})
            );
            setUploadedData(rows);
            setEditAllData(rows);
        };
        reader.readAsArrayBuffer(file);
    };

    const handleEditClick = (index) => {
        setIsEditing(index);
        setEditData(uploadedData[index]);
    };

    const handleEditAllClick = () => {
        setIsEditingAll(true);
    };

    const handleInputChange = (e, index = null) => {
        const { name, value } = e.target;
        if (index === null) {
            setEditData(prevState => ({
                ...prevState,
                [name]: value
            }));
        } else {
            const updatedEditAllData = [...editAllData];
            updatedEditAllData[index][name] = value;
            setEditAllData(updatedEditAllData);
        }
    };

    const handleSaveClick = (index) => {
        const updatedData = [...uploadedData];
        updatedData[index] = editData;
        setUploadedData(updatedData);
        setIsEditing(null);
    };

    const handleSaveAllClick = () => {
        setUploadedData(editAllData);
        setIsEditingAll(false);
    };

    const handleDeleteClick = (index) => {
        const updatedData = uploadedData.filter((_, i) => i !== index);
        setUploadedData(updatedData);
        setEditAllData(updatedData);
    };

    const handleDeleteAllClick = () => {
        setUploadedData([]);
        setEditAllData([]);
    };

   
    const handleRegisterAllBeacons = () => {
        uploadedData.forEach(data => {
            console.log("Registering data:", data);
        });
    };
    
    return (
        <div className="beacon-registration">
            <RegistrationForm
                formTitle="Beacon Registration"
                formFields={formFields}
                endpoint="/api/beacons"
                selectData={selectData}
                prevForm={prevForm}
                nextForm={nextForm}
                handleFormToggle={handleFormToggle}
            />
            <div className="form-footer">
                <div className="file-upload-section">
                    <label htmlFor="file-upload">Upload Excel File:</label>
                    <input type="file" id="file-upload" accept=".xlsx, .xls" onChange={handleFileUpload} />
                </div>
            </div>
            
            {uploadedData.length > 0 && (
                <div className="data-table">
                    <h3>Uploaded Data</h3>  
                    <div className='data-table-actions'>
                    {isEditingAll ? (
                            <button type='submit' onClick={handleSaveAllClick}>Save All</button>
                        ) : (
                            <button type='submit' onClick={handleEditAllClick}>Edit All</button>
                        )}
                        <button type='delete' onClick={handleDeleteAllClick}>Delete All</button>
                    </div>
                    
                    <table>
                        <thead>
                            <tr>
                                <th>Manufacturer ID</th>
                                <th>Beacon Type</th>
                                <th>Protocol</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {uploadedData.map((row, index) => (
                                <tr key={index}>
                                    <td>
                                        {isEditing === index ? (
                                            <input
                                                type="text"
                                                name="mfd_id"
                                                value={editData.mfd_id}
                                                onChange={handleInputChange}
                                            />
                                        ) : isEditingAll ? (
                                            <input
                                                type="text"
                                                name="mfd_id"
                                                value={editAllData[index].mfd_id}
                                                onChange={(e) => handleInputChange(e, index)}
                                            />
                                        ) : (
                                            row.mfd_id
                                        )}
                                    </td>
                                    <td>
                                        {isEditing === index ? (
                                            <input
                                                type="text"
                                                name="bec_typ_name"
                                                value={editData.bec_typ_name}
                                                onChange={handleInputChange}
                                            />
                                        ) : isEditingAll ? (
                                            <input
                                                type="text"
                                                name="bec_typ_name"
                                                value={editAllData[index].bec_typ_name}
                                                onChange={(e) => handleInputChange(e, index)}
                                            />
                                        ) : (
                                            row.bec_typ_name
                                        )}
                                    </td>
                                    <td>
                                        {isEditing === index ? (
                                            <input
                                                type="text"
                                                name="protocol"
                                                value={editData.protocol}
                                                onChange={handleInputChange}
                                            />
                                        ) : isEditingAll ? (
                                            <input
                                                type="text"
                                                name="protocol"
                                                value={editAllData[index].protocol}
                                                onChange={(e) => handleInputChange(e, index)}
                                            />
                                        ) : (
                                            row.protocol
                                        )}
                                    </td>
                                    <td>
                                        {isEditing === index ? (
                                            <button onClick={() => handleSaveClick(index)}>Save</button>
                                        ) : (
                                            <>
                                                <button onClick={() => handleEditClick(index)}>Edit</button>
                                                <button onClick={() => handleDeleteClick(index)}>Delete</button>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="submit-container">
                        <button type="submit" onClick={() => handleRegisterAllBeacons()}>Register All Beacons from Table</button>
                    </div>
                </div>
            )}
        </div>
    );
    };
    
    export default BeaconRegistration;
    