import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import EntityManagement from "../../components/formTemplates/EntityManagement";
import EntityTable from "../../components/templateComponents/EntityTable";
import useDropdownFetch from "../../components/apiFetch/useDropdownFetch";

const Campus = () => {
  const [formData, setFormData] = useState({
    campus_name: "",
    campus_address: "",
    campus_image: null,
    csc_id: "",
    area_id: "",
    establishid: "",
    status_id: "",
  });

  const [entities, setEntities] = useState([]);
  const [dropdownData, setDropdownData] = useState({
    csc_id: [],
    area_id: [],
    establishid: [],
    status_id: [],
  });

  const [editMode, setEditMode] = useState(false);
  const [editingEntityId, setEditingEntityId] = useState(null);
  const [isTableModalOpen, setIsTableModalOpen] = useState(false);

  const firstFormFieldRef = useRef(null);

  const endpoints = {
    csc: "http://146.190.11.26:5055/city/v0.1",
    area: "http://146.190.11.26:5055/area/v0.1",
    establishment: "http://146.190.11.26:5055/est/v0.1",
    status: "http://146.190.11.26:5055/view_status/v0.1",
    campuses: "http://146.190.11.26:5055/campus/v0.1",
    updateCampus: "http://146.190.11.26:5055/cmp_update/v0.1",
  };

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const [cityResponse] = await Promise.all([
          axios.post(endpoints.csc, {
            csc_id: 3,
            view_type: "view",
          }),
        ]);

        const formattedCityOptions = Object.entries(cityResponse.data.Data).map(
          ([value, label]) => ({ value, label })
        );

        setDropdownData((prevData) => ({
          ...prevData,
          csc_id: formattedCityOptions,
        }));
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };

    fetchDropdownData();
  }, [endpoints.csc]);
  useEffect(() => {
    const fetchAreas = async () => {
      try {
        if (formData.csc_id) {
          const areaResponse = await axios.post(endpoints.area, {
            csc_id: formData.csc_id,
            view_type: "view",
          });
          const formattedAreaOptions = Object.entries(
            areaResponse.data.Data
          ).map(([value, label]) => ({ value, label }));

          setDropdownData((prevData) => ({
            ...prevData,
            area_id: formattedAreaOptions,
          }));
        }
      } catch (error) {
        console.error("Error fetching areas:", error);
      }
    };

    fetchAreas();
  }, [formData.csc_id, endpoints.area]);

  const { options: statusOptions } = useDropdownFetch(endpoints.status);
  useEffect(() => {
    if (statusOptions) {
      setDropdownData((prevData) => ({
        ...prevData,
        status_id: statusOptions,
      }));
    }
  }, [statusOptions]);

  useEffect(() => {
    const fetchEstablishmentData = async () => {
      try {
        const response = await axios.post(endpoints.establishment, {
          area_id: 1,
          view_type: "view",
        });
        const formattedEstablishmentOptions = Object.entries(
          response.data.Data
        ).map(([value, label]) => ({ value, label }));
        setDropdownData((prevData) => ({
          ...prevData,
          establishid: formattedEstablishmentOptions,
        }));
      } catch (error) {
        console.error("Error fetching establishment data:", error);
      }
    };

    fetchEstablishmentData();
  }, [endpoints.establishment]);

  useEffect(() => {
    const fetchEntities = async () => {
      try {
        const response = await axios.post(endpoints.campuses, {
          est_id: formData.establishid || 1,
          view_type: "view all",
        });
        setEntities(response.data.Data || []);
      } catch (error) {
        console.error("Error fetching campuses:", error);
      }
    };

    fetchEntities();
  }, [formData.establishid, endpoints.campuses]);

  const handleUpdate = async (entityId, updatedData) => {
    try {
      await axios.post(endpoints.updateCampus, {
        campusid: entityId,
        campus_name: updatedData.campus_name,
        campus_address: updatedData.campus_address,
        campus_image: updatedData.campus_image || "No",
        est_id: updatedData.establishid,
        csc_id: updatedData.csc_id,
        area_id: updatedData.area_id,
        status_id: updatedData.status_id,
        added_date: new Date().toISOString(),
      });

      setEntities((prevEntities) =>
        prevEntities.map((entity) =>
          entity.campusid === entityId
            ? { ...updatedData, campusid: entityId }
            : entity
        )
      );
      resetForm();
    } catch (error) {
      console.error("Error updating campus:", error);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { campus_image, ...formattedFormData } = {
      ...formData,
      csc_id: parseInt(formData.csc_id, 10),
      area_id: parseInt(formData.area_id, 10),
      establishid: parseInt(formData.establishid, 10),
      status_id: parseInt(formData.status_id, 10),
    };

    const formDataToSubmit = new FormData();
    for (const key in formattedFormData) {
      formDataToSubmit.append(key, formattedFormData[key]);
    }
    if (campus_image) formDataToSubmit.append("campus_image", campus_image);

    if (editMode) {
      await handleUpdate(editingEntityId, formattedFormData);
    } else {
      try {
        const response = await axios.post(endpoints.campuses, formDataToSubmit);
        setEntities([...entities, response.data]);
        resetForm();
      } catch (error) {
        console.error("Error adding campus:", error);
      }
    }
  };

  const resetForm = () => {
    setFormData({
      campus_name: "",
      campus_address: "",
      campus_image: null,
      csc_id: "",
      area_id: "",
      establishid: "",
      status_id: "",
    });
    setEditMode(false);
    setEditingEntityId(null);
  };

  const handleEdit = (entityId) => {
    const entityToEdit = entities.find(
      (entity) => entity.campusid === entityId
    );
    if (entityToEdit) {
      setFormData({
        campus_name: entityToEdit.campus_name,
        campus_address: entityToEdit.campus_address,
        campus_image: null,
        csc_id: entityToEdit.csc_id,
        area_id: entityToEdit.area_id,
        establishid: entityToEdit.est_id,
        status_id: entityToEdit.status_id,
      });
      setEditMode(true);
      setEditingEntityId(entityId);
      if (firstFormFieldRef.current) {
        firstFormFieldRef.current.focus();
      }
      setIsTableModalOpen(false);
    }
  };

  const handleDelete = async (entityId) => {
    try {
      const entityToUpdate = entities.find(
        (entity) => entity.campusid === entityId
      );

      if (!entityToUpdate) {
        console.error("Entity not found");
        return;
      }

      const updatedData = {
        campusid: entityId,
        status_id: 3,
        campus_name: entityToUpdate.campus_name,
        campus_address: entityToUpdate.campus_address,
        campus_image: entityToUpdate.campus_image || "No",
        est_id: entityToUpdate.est_id,
        csc_id: entityToUpdate.csc_id,
        area_id: entityToUpdate.area_id,
        added_date: entityToUpdate.added_date,
      };

      const response = await axios.post(endpoints.updateCampus, updatedData);

      if (response.data.Status === "Success") {
        setEntities((prevEntities) =>
          prevEntities.map((entity) =>
            entity.campusid === entityId ? { ...entity, status_id: 3 } : entity
          )
        );
      } else {
        console.error("Failed to delete entity:", response.data);
      }
    } catch (error) {
      console.error("Error deleting campus:", error);
    }
  };

  const handleCityChange = (e) => {
    const selectedCityId = e.target.value;
    setFormData((prevData) => ({ ...prevData, csc_id: selectedCityId }));
  };
  const handleFileChange = (e) => {
    setFormData({ ...formData, logo: e.target.files[0] });
  };

  const formFields = [
    { name: "campus_name", label: "Campus Name", type: "text" },
    { name: "campus_address", label: "Campus Address", type: "text" },
    {
      name: "campus_image",
      label: "Campus Image",
      type: "file",
      onChange: handleFileChange,
    },
    {
      name: "csc_id",
      label: "City",
      type: "select",
      dropdownKey: "csc_id",
      onChange: handleCityChange,
    },
    { name: "area_id", label: "Area", type: "select", dropdownKey: "area_id" },
    {
      name: "establishid",
      label: "Establishment",
      type: "select",
      dropdownKey: "establishid",
    },
    {
      name: "status_id",
      label: "Status",
      type: "select",
      dropdownKey: "status_id",
    },
  ];

  const columns = [
    { name: "campus_name", label: "Campus Name" },
    { name: "campus_address", label: "Campus Address" },
    { name: "csc_name", label: "City" },
    { name: "area_name", label: "Area" },
    { name: "est_name", label: "Establishment" },
    { name: "status_name", label: "Status" },
    { name: "campus_image", label: "Campus Image" },
  ];

  const handleViewTableClick = () => {
    setIsTableModalOpen(true);
  };

  return (
    <div className="campus">
      <EntityManagement
        entityName="Campus"
        formFields={formFields}
        formData={formData}
        setFormData={setFormData}
        onFileChange={handleFileChange}
        onCityChange={handleCityChange}
        handleFormSubmit={handleFormSubmit}
        dropdownData={dropdownData}
        handleViewTableClick={handleViewTableClick}
        firstFormFieldRef={firstFormFieldRef}
        onEdit={handleEdit}
      />
      {isTableModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button onClick={() => setIsTableModalOpen(false)}>Close</button>
            <EntityTable
              data={entities}
              columns={columns}
              onEdit={handleEdit}
              onDelete={handleDelete}
              keyField="campusid"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Campus;
