import React, { useState } from 'react';
import birdLogo from '../../assets/images/Waytern-Logo.png';
import administratorIcon from '../../assets/images/Administrator.png';
import dashboardIcon from '../../assets/images/Dashboard.png';
import buildingIcon from '../../assets/images/Building.png';
import merchantsIcon from '../../assets/images/Merchant.png';
import beaconsIcon from '../../assets/images/Beacons.png';
import systemUsersIcon from '../../assets/images/System users.png';
import wayternHubsIcon from '../../assets/images/Waytern Hubs.png'
import shopsIcon from '../../assets/images/Shops.png';
import serviceProvidersIcon from '../../assets/images/Service Providers.png';
import { NavLink } from 'react-router-dom';
import '../../assets/componentsCSS/Sidebar.css';

const Sidebar = ({ children, toggleSidebar }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
        const container = document.querySelector('.container');
        container.classList.toggle('expanded');
    };

    const menuItem = [
        {
            path: "/",
            name: "Administrator",
            icon: administratorIcon
        },
        {
            path: "/dashboard",
            name: "Dashboard",
            icon: dashboardIcon
        },
        {
            path: "/beacons",
            name: "Beacons",
            icon: beaconsIcon
        },
        {
            path: "/building",
            name: "Building",
            icon: buildingIcon
        },
        {
            path: "/wayternHubs",
            name: "Waytern Hubs",
            icon: wayternHubsIcon
        },
        {
            path: "/merchants",
            name: "Merchants",
            icon: merchantsIcon
        },
        {
            path: "/shops",
            name: "Shops",
            icon: shopsIcon
        },
        {
            path: "/serviceProviders",
            name: "Service Providers",
            icon: serviceProvidersIcon
        },
        {
            path: "/systemUsers",
            name: "System Users",
            icon: systemUsersIcon
        }
    ];

    return (
        <div className='container'>
            <div className="sidebar" style={{ width: isOpen ? "200px" : "60px" }}>
                <div className="top_section">
                    <img src={birdLogo} alt="Logo" className="logo" style={{ display: isOpen ? "block" : "none" }} />
                    <div className="bars" 
                    onClick={
                        ()=> { 
                        toggle();
                        toggleSidebar();
                        }
                    } style={{ marginLeft: isOpen ? "-15px" : "3px", marginBottom: "100px", marginTop:'-10px' }}>
                     <div style={{ fontSize: '24px', cursor: 'pointer' }}>☰</div>
                   </div>

                </div>
                {
                    menuItem.map((item, index) => (
                        <React.Fragment key={index}>
                            <NavLink to={item.path} className="link" activeclassname="active">
                                <div className="icon">
                                    <img src={item.icon} alt={item.name}  />
                                    {/* <img src={item.icon}  style={{ width: '24px', height: '24px' }} /> */}
                                </div>
                                <div className="link_text" style={{ display: isOpen ? "block" : "none" }}>{item.name}</div>
                            </NavLink>
                            {/* Add horizontal line only after the first item */}
                            {index === 0 && <hr className="horizontal_line" style={{ display: isOpen ? "block" : "none" }} />}
                        </React.Fragment>
                    ))
                }
            </div>
            <main>{children}</main>
        </div>
    );
};

export default Sidebar;
