import React, { useState } from "react";
import EstablishmentRegistrationForm from "../../components/regForms/EstablishmentRegistrationForm";
import CampusRegistrationForm from "../../components/regForms/CampusRegistrationForm";
import BuildingRegistrationForm from "../../components/regForms/BuildingRegistrationForm";
import FloorRegistrationForm from "../../components/regForms/FloorRegistrationForm";
import UnitRegistrationForm from "../../components/regForms/UnitRegistrationForm";

const BuildingPage = () => {
  const [activeEntity, setActiveEntity] = useState("establishment");

  const formToggleButtons = [
    {
      name: "establishment",
      label: "Establishment",
      active: activeEntity === "establishment",
      onClick: () => setActiveEntity("establishment"),
    },
    {
      name: "campus",
      label: "Campus",
      active: activeEntity === "campus",
      onClick: () => setActiveEntity("campus"),
    },
    {
      name: "building",
      label: "Building",
      active: activeEntity === "building",
      onClick: () => setActiveEntity("building"),
    },
    {
      name: "floor",
      label: "Floor",
      active: activeEntity === "floor",
      onClick: () => setActiveEntity("floor"),
    },
    {
      name: "unit",
      label: "Unit",
      active: activeEntity === "unit",
      onClick: () => setActiveEntity("unit"),
    },
  ];

  const renderActiveForm = () => {
    switch (activeEntity) {
      case "establishment":
        return <EstablishmentRegistrationForm />;
      case "campus":
        return <CampusRegistrationForm />;
      case "building":
        return <BuildingRegistrationForm />;
      case "floor":
        return <FloorRegistrationForm />;
      case "unit":
        return <UnitRegistrationForm />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="form-toggler">
        {formToggleButtons.map((button) => (
          <button
            key={button.name}
            className={button.active ? "active" : ""}
            onClick={button.onClick}
          >
            {button.label}
          </button>
        ))}
      </div>
      {renderActiveForm()}
    </div>
  );
};

export default BuildingPage;
