import React from 'react';
import birdLogo from '../../assets/images/Waytern-Logo.png';
import '../../assets/componentsCSS/TopNavbar.css';

const TopNavbar = ({isSidebarOpen}) => {
    return (
        <div className={`top-navbar ${isSidebarOpen ? 'is-sidebar-open' : ''}`}>
            { !isSidebarOpen && <img src={birdLogo} alt="Logo" className="logo1" /> }
            <div className="logout-btn">Logout</div>
        </div>
    );
};

export default TopNavbar;
