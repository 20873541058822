import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import "../../assets/componentsCSS/EntityManagement.css";

const EntityManagement = ({
  entityName = "Entity",
  formFields = [],
  formData = {},
  setFormData,
  handleFormSubmit,
  dropdownData = {},
  showWorkdays = false,
  selectedWorkdays = [],
  setSelectedWorkdays,
  setDropdownData,
  tableData = [],
  handleViewTableClick,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTableModalOpen, setIsTableModalOpen] = useState(false);
  const [newOption, setNewOption] = useState("");
  const [currentField, setCurrentField] = useState("");
  const [workdaysDropdownOpen, setWorkdaysDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDropdownChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleWorkdaysChange = (e) => {
    const { value, checked } = e.target;
    setSelectedWorkdays((prev) =>
      checked ? [...prev, value] : prev.filter((day) => day !== value)
    );
  };

  const getDropdownOptions = (key) => {
    return Array.isArray(dropdownData[key]) ? dropdownData[key] : [];
  };

  const openModal = (fieldName) => {
    setCurrentField(fieldName);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setNewOption("");
  };

  const toggleWorkdaysDropdown = () => {
    setWorkdaysDropdownOpen((prev) => !prev);
  };

  const closeWorkdaysDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setWorkdaysDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeWorkdaysDropdown);
    return () => {
      document.removeEventListener("mousedown", closeWorkdaysDropdown);
    };
  }, []);

  const closeTableModal = () => {
    setIsTableModalOpen(false);
  };

  const handleAddNewOption = async () => {
    const endpoint = `/api/addNew/${currentField}`;
    try {
      const response = await axios.post(endpoint, { name: newOption });
      if (response.status === 200) {
        const result = response.data;
        setDropdownData((prev) => ({
          ...prev,
          [currentField]: [
            ...prev[currentField],
            { value: result.id, label: result.name },
          ],
        }));
        setFormData((prev) => ({ ...prev, [currentField]: result.id }));
        closeModal();
      }
    } catch (error) {
      console.error("Error adding new option:", error);
    }
  };

  return (
    <div className="entity-management">
      <div className="form-toggler">
        <button className="view-entity-button" onClick={handleViewTableClick}>
          View {entityName} Table <FontAwesomeIcon icon={faEye} />
        </button>
      </div>
      <form onSubmit={handleFormSubmit} className="entity-form">
        <h2>{entityName} Management</h2>
        {formFields.length === 0 ? (
          <p>No form fields available.</p>
        ) : (
          formFields.map((field) => (
            <div key={field.name} className="attribute-row">
              <label>{field.label}</label>
              {field.type === "select" ? (
                <div className="select-with-add">
                  <div className="dropdown-container">
                    <select
                      name={field.name}
                      value={formData[field.name] || ""}
                      onChange={(e) =>
                        handleDropdownChange(field.name, e.target.value)
                      }
                      className="attribute-input"
                    >
                      <option value="">Select {field.label}</option>
                      {getDropdownOptions(field.dropdownKey).map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {field.allowAdd && (
                      <button
                        type="button"
                        className="add-new-button"
                        onClick={() => openModal(field.name)}
                      >
                        Add New
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <input
                  type={field.type}
                  name={field.name}
                  value={formData[field.name] || ""}
                  onChange={handleInputChange}
                  className="attribute-input"
                />
              )}
            </div>
          ))
        )}
        {showWorkdays && (
          <div className="workdays" ref={dropdownRef}>
            <label className="workdays-label">Workdays:</label>
            <div className="workdays-dropdown">
              <div className="dropdown-header" onClick={toggleWorkdaysDropdown}>
                {selectedWorkdays.length
                  ? selectedWorkdays.join(", ")
                  : "Select Workdays"}
                <span className="dropdown-arrow">&#9662;</span>
              </div>
              {workdaysDropdownOpen && (
                <div className="dropdown-list">
                  {[
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ].map((day) => (
                    <label key={day} className="dropdown-item">
                      <input
                        type="checkbox"
                        value={day}
                        checked={selectedWorkdays.includes(day)}
                        onChange={handleWorkdaysChange}
                      />
                      {day}
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        <div className="submit-button-container">
          <button type="submit" className="entity-submit-button">
            Submit
          </button>
          <button type="button" className="entity-cancel-button">
            Cancel
          </button>
        </div>
      </form>

      <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="modal">
        <div className="modal-header">
          <button className="entity-cancel-button" onClick={closeModal}>
            Close
          </button>
        </div>
        <h2>Add New {currentField}</h2>
        <input
          type="text"
          value={newOption}
          onChange={(e) => setNewOption(e.target.value)}
          className="attribute-input"
        />
        <div className="submit-button-container">
          <button onClick={handleAddNewOption} className="entity-submit-button">
            Add
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={isTableModalOpen}
        onRequestClose={closeTableModal}
        className="modal"
      >
        <div className="modal-header">
          <button className="entity-cancel-button" onClick={closeTableModal}>
            Close
          </button>
        </div>
        <div className="table-container">
          <table className="entity-table">
            <thead>
              <tr>
                {formFields.map((field) => (
                  <th key={field.name}>{field.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  {formFields.map((field) => (
                    <td key={field.name}>{row[field.name]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};

EntityManagement.propTypes = {
  entityName: PropTypes.string,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      dropdownKey: PropTypes.string,
      allowAdd: PropTypes.bool,
    })
  ),
  formData: PropTypes.object,
  setFormData: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  dropdownData: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    )
  ),
  showWorkdays: PropTypes.bool,
  selectedWorkdays: PropTypes.arrayOf(PropTypes.string),
  setSelectedWorkdays: PropTypes.func,
  setDropdownData: PropTypes.func.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.object),
  handleViewTableClick: PropTypes.func.isRequired,
};

export default EntityManagement;
