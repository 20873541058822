import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import EntityManagement from "../../components/formTemplates/EntityManagement";
import EntityTable from "../../components/templateComponents/EntityTable";
import useDropdownFetch from "../../components/apiFetch/useDropdownFetch";

const Building = () => {
  const [formData, setFormData] = useState({
    bldg_code: "",
    bldg_no: "",
    bldg_name: "",
    est_id: "",
    campusid: "",
    area_id: "",
    csc_id: "",
    status_id: "",
    added_date: "",
  });

  const [entities, setEntities] = useState([]);
  const [dropdownData, setDropdownData] = useState({
    est_id: [],
    campusid: [],
    area_id: [],
    csc_id: [],
    status_id: [],
  });

  const [editMode, setEditMode] = useState(false);
  const [editingEntityId, setEditingEntityId] = useState(null);
  const [isTableModalOpen, setIsTableModalOpen] = useState(false);
  const [qrCodes, setQrCodes] = useState({});

  const firstFormFieldRef = useRef(null);

  const endpoints = {
    establishment: "http://146.190.11.26:5055/est/v0.1",
    campus: "http://146.190.11.26:5055/campus/v0.1",
    status: "http://146.190.11.26:5055/view_status/v0.1",
    buildings: "http://146.190.11.26:5055/view_building/v0.1",
    updateBuilding: "http://146.190.11.26:5055/update_building/v0.1",
    registerBuilding: "http://146.190.11.26:5055/bldg_reg/v0.1",
    csc: "http://146.190.11.26:5055/city/v0.1",
    area: "http://146.190.11.26:5055/area/v0.1",
  };

  useEffect(() => {
    const fetchEstablishmentData = async () => {
      try {
        const response = await axios.post(endpoints.establishment, {
          area_id: 1,
          view_type: "view",
        });

        const formattedEstablishmentOptions = Object.entries(
          response.data.Data
        ).map(([value, label]) => ({
          value,
          label,
        }));

        setDropdownData((prevData) => ({
          ...prevData,
          est_id: formattedEstablishmentOptions,
        }));
      } catch (error) {
        console.error("Error fetching establishment data:", error);
      }
    };

    fetchEstablishmentData();
  }, [endpoints.establishment]);

  useEffect(() => {
    const fetchCampusData = async () => {
      if (!formData.est_id) return;

      try {
        const response = await axios.post(endpoints.campus, {
          est_id: formData.est_id,
          view_type: "view",
        });

        const formattedCampusOptions = Object.entries(response.data.Data).map(
          ([value, label]) => ({
            value,
            label,
          })
        );

        setDropdownData((prevData) => ({
          ...prevData,
          campusid: formattedCampusOptions,
        }));
      } catch (error) {
        console.error("Error fetching campus data:", error);
      }
    };

    fetchCampusData();
  }, [formData.est_id, endpoints.campus]);

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const [cityResponse] = await Promise.all([
          axios.post(endpoints.csc, {
            csc_id: 3,
            view_type: "view",
          }),
        ]);

        const formattedCityOptions = Object.entries(cityResponse.data.Data).map(
          ([value, label]) => ({ value, label })
        );

        setDropdownData((prevData) => ({
          ...prevData,
          csc_id: formattedCityOptions,
        }));
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };

    fetchDropdownData();
  }, [endpoints.csc]);

  useEffect(() => {
    const fetchAreaData = async () => {
      if (!formData.csc_id) return;

      try {
        const response = await axios.post(endpoints.area, {
          csc_id: formData.csc_id,
          view_type: "view",
        });

        const formattedAreaOptions = Object.entries(response.data.Data).map(
          ([value, label]) => ({
            value,
            label,
          })
        );

        setDropdownData((prevData) => ({
          ...prevData,
          area_id: formattedAreaOptions,
        }));
      } catch (error) {
        console.error("Error fetching area data:", error);
      }
    };

    fetchAreaData();
  }, [formData.csc_id, endpoints.area]);

  const { options: statusOptions } = useDropdownFetch(endpoints.status);

  useEffect(() => {
    if (statusOptions) {
      setDropdownData((prevData) => ({
        ...prevData,
        status_id: statusOptions,
      }));
    }
  }, [statusOptions]);

  useEffect(() => {
    const fetchEntities = async () => {
      try {
        const response = await axios.post(endpoints.buildings, {
          campusid: formData.campusid || 1,
          view_type: "view all",
        });

        const buildings = response.data.Data || [];
        setEntities(buildings);

        // Generate QR codes for all entities
        const qrCodeData = {};
        buildings.forEach((building) => {
          if (building.bldg_qr_id) {
            qrCodeData[building.bldg_id] = building.bldg_qr_id;
          }
        });
        setQrCodes(qrCodeData);
      } catch (error) {
        console.error("Error fetching buildings:", error);
      }
    };

    fetchEntities();
  }, [formData.campusid, endpoints.buildings]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formattedFormData = {
      bldg_code: parseInt(formData.bldg_code, 10),
      bldg_no: parseInt(formData.bldg_no, 10),
      bldg_name: formData.bldg_name,
      est_id: parseInt(formData.est_id, 10),
      campusid: parseInt(formData.campusid, 10),
      area_id: parseInt(formData.area_id, 10),
      csc_id: parseInt(formData.csc_id, 10),
      status_id: parseInt(formData.status_id, 10),
    };

    if (editMode) {
      await handleUpdate(editingEntityId, formattedFormData);
    } else {
      try {
        const response = await axios.post(
          endpoints.registerBuilding,
          formattedFormData
        );
        if (response.data.Status === "Success") {
          setEntities([...entities, response.data.Data]);
          console.log("success building added");
          resetForm();
        }
      } catch (error) {
        console.error("Error adding building:", error);
        alert(
          "An error occurred while adding the building. Please check the console for more details."
        );
      }
    }
  };

  const handleUpdate = async (entityId, updatedData) => {
    try {
      const response = await axios.post(endpoints.updateBuilding, {
        ...updatedData,
        bldg_id: entityId,
      });

      if (response.data.Status === "Success") {
        setEntities((prevEntities) =>
          prevEntities.map((entity) =>
            entity.bldg_id === entityId ? { ...entity, ...updatedData } : entity
          )
        );
        resetForm();
        setIsTableModalOpen(false);
      } else {
        console.error("Failed to update building:", response.data);
      }
    } catch (error) {
      console.error("Error updating building:", error);
    }
  };

  const resetForm = () => {
    setFormData({
      bldg_code: "",
      bldg_no: "",
      bldg_name: "",
      est_id: "",
      campusid: "",
      area_id: "",
      csc_id: "",
      status_id: "",
      added_date: "",
    });
    setEditMode(false);
    setEditingEntityId(null);
  };

  const handleEdit = (entityId) => {
    const entityToEdit = entities.find((entity) => entity.bldg_id === entityId);
    if (entityToEdit) {
      setFormData({
        bldg_code: entityToEdit.bldg_code,
        bldg_no: entityToEdit.bldg_no,
        bldg_name: entityToEdit.bldg_name,
        est_id: entityToEdit.est_id,
        campusid: entityToEdit.campusid,
        area_id: entityToEdit.area_id,
        csc_id: entityToEdit.csc_id,
        status_id: entityToEdit.status_id,
        added_date: entityToEdit.added_date,
      });
      setEditMode(true);
      setEditingEntityId(entityId);
      if (firstFormFieldRef.current) {
        firstFormFieldRef.current.focus();
      }
      setIsTableModalOpen(false);
    }
  };

  const handleDelete = async (entityId) => {
    try {
      const entityToUpdate = entities.find(
        (entity) => entity.bldg_id === entityId
      );
      if (!entityToUpdate) {
        console.error("Entity not found");
        return;
      }

      const updatedData = {
        bldg_id: entityId,
        status_id: 3,
        ...entityToUpdate,
      };

      const response = await axios.post(endpoints.updateBuilding, updatedData);

      if (response.data.Status === "Success") {
        setEntities((prevEntities) =>
          prevEntities.map((entity) =>
            entity.bldg_id === entityId ? { ...entity, status_id: 3 } : entity
          )
        );
      } else {
        console.error("Failed to delete entity:", response.data);
      }
    } catch (error) {
      console.error("Error deleting entity:", error);
    }
  };

  const toggleTableModal = () => {
    setIsTableModalOpen(!isTableModalOpen);
  };

  const handleGenerateQRCode = (entityId) => {
    // Find the building details
    const building = entities.find((item) => item.bldg_id === entityId);

    if (building) {
      // Use the existing bldg_qr_id if available
      const qrCodeData = building.bldg_qr_id;

      if (qrCodeData) {
        setQrCodes((prevCodes) => ({
          ...prevCodes,
          [entityId]: qrCodeData,
        }));
      } else {
        console.error(`No QR code ID found for building with ID: ${entityId}`);
      }
    } else {
      console.error(`Building with ID: ${entityId} not found.`);
    }
  };
  const formFields = [
    { name: "bldg_code", label: "Building Code", type: "text", required: true },
    { name: "bldg_no", label: "Building Number", type: "text", required: true },
    { name: "bldg_name", label: "Building Name", type: "text", required: true },
    {
      name: "est_id",
      label: "Establishment",
      type: "select",
      dropdownKey: "est_id",
      required: true,
    },
    {
      name: "campusid",
      label: "Campus",
      type: "select",
      dropdownKey: "campusid",
      required: true,
    },
    {
      name: "csc_id",
      label: "City",
      type: "select",
      dropdownKey: "csc_id",
      required: true,
    },
    {
      name: "area_id",
      label: "Area",
      type: "select",
      dropdownKey: "area_id",
      required: true,
    },
    {
      name: "status_id",
      label: "Status",
      type: "select",
      dropdownKey: "status_id",
      required: true,
    },
  ];

  const columns = [
    { name: "bldg_code", label: "Building Code" },
    { name: "bldg_no", label: "Building Number" },
    { name: "bldg_name", label: "Building Name" },
    { name: "est_name", label: "Establishment" },
    { name: "campus_name", label: "Campus" },
    { name: "csc_name", label: "City" },
    { name: "area_name", label: "Area" },
    { name: "status_name", label: "Status" },
    { name: "bldg_qr_id", label: "QR" },
  ];

  const handleViewTableClick = () => {
    setIsTableModalOpen(true);
  };

  const closeModal = () => {
    setIsTableModalOpen(false);
  };
  return (
    <div className="building">
      <EntityManagement
        entityName="Building"
        formFields={formFields}
        formData={formData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        dropdownData={dropdownData}
        firstFormFieldRef={firstFormFieldRef}
        handleViewTableClick={handleViewTableClick}
        onEdit={handleEdit}
      />
      {isTableModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button onClick={() => setIsTableModalOpen(false)}>Close</button>
            <EntityTable
              data={entities}
              columns={columns}
              onEdit={handleEdit}
              onDelete={handleDelete}
              keyField="bldg_id"
              onGenerateQRCode={handleGenerateQRCode}
              qrCodes={qrCodes}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Building;
