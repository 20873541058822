import React, { useState, useEffect } from 'react';
import './WayternHubs.css'; 

const WayternHubs = () => {
    // State variables for selected values
    const [selectedEstablishment, setSelectedEstablishment] = useState('');
    const [selectedCampus, setSelectedCampus] = useState('');
    const [selectedBuilding, setSelectedBuilding] = useState('');
    const [establishments, setEstablishments] = useState([]);
    const [campuses, setCampuses] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [units, setUnits] = useState([]);
    const [selectedUnitId, setSelectedUnitId] = useState(null);
    const [editShopValue, setEditShopValue] = useState('');

    // Fetch data for dropdowns
    useEffect(() => {
        // Fetch data for establishments, campuses, and buildings
        const fetchData = async () => {
            try {
                // Fetch establishments data
                const establishmentsResponse = await fetch('/api/establishments');
                const establishmentsData = await establishmentsResponse.json();

                // Fetch campuses data
                const campusesResponse = await fetch('/api/campuses');
                const campusesData = await campusesResponse.json();

                // Fetch buildings data
                const buildingsResponse = await fetch('/api/buildings');
                const buildingsData = await buildingsResponse.json();

                // Update state with fetched data
                setEstablishments(establishmentsData);
                setCampuses(campusesData);
                setBuildings(buildingsData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // Fetch units data based on selected campus and building
    useEffect(() => {
        if (selectedCampus && selectedBuilding) {
            // Fetch units data based on selected campus and building
            const fetchUnits = async () => {
                try {
                    const unitsResponse = await fetch(`/api/units?campus_id=${selectedCampus}&building_id=${selectedBuilding}`);
                    const unitsData = await unitsResponse.json();
                    setUnits(unitsData);
                } catch (error) {
                    console.error('Error fetching units:', error);
                }
            };

            fetchUnits();
        }
    }, [selectedCampus, selectedBuilding]);

    // Handlers for dropdown selection
    const handleEstablishmentChange = (event) => {
        setSelectedEstablishment(event.target.value);
        // Reset campus and building selections when establishment changes
        setSelectedCampus('');
        setSelectedBuilding('');
    };

    const handleCampusChange = (event) => {
        setSelectedCampus(event.target.value);
        // Reset building selection when campus changes
        setSelectedBuilding('');
    };

    const handleBuildingChange = (event) => {
        setSelectedBuilding(event.target.value);
    };

    // Handler for editing shop
    const handleEditShop = (unitId, currentShopValue) => {
        setSelectedUnitId(unitId);
        setEditShopValue(currentShopValue);
    };

    const handleSaveShop = () => {
        // Perform save operation, update the shop value in backend or perform any other action
        console.log(`Saving shop value for unit ${selectedUnitId}: ${editShopValue}`);
        setSelectedUnitId(null);
    };

    return (
        <div className="style-container">
            <h2>Waytern Hubs</h2>
            <div className="dropdown-container">
                <div className="dropdown">
                    <label>Establishment:</label>
                    <select value={selectedEstablishment} onChange={handleEstablishmentChange}>
                        <option value="">Select Establishment</option>
                        {establishments.map(establishment => (
                            <option key={establishment.est_id} value={establishment.est_id}>{establishment.est_name}</option>
                        ))}
                    </select>
                </div>
                <div className="dropdown">
                    <label>Campus:</label>
                    <select value={selectedCampus} onChange={handleCampusChange}>
                        <option value="">Select Campus</option>
                        {campuses.filter(campus => campus.establishid === selectedEstablishment).map(campus => (
                            <option key={campus.campusid} value={campus.campusid}>{campus.campus_name}</option>
                        ))}
                    </select>
                </div>
                <div className="dropdown">
                    <label>Building:</label>
                    <select value={selectedBuilding} onChange={handleBuildingChange}>
                        <option value="">Select Building</option>
                        {buildings.filter(building => building.campusid === selectedCampus).map(building => (
                            <option key={building.bldg_id} value={building.bldg_id}>{building.bldg_name}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="table-container">
                
                <table>
                    <thead>
                        <tr>
                            <th>Unit Number</th>
                            <th>Floor</th>
                            <th>Section</th>
                            <th>Unit Type</th>
                            <th>Shop</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {units.map(unit => (
                            <tr key={unit.unit_id}>
                                <td>{unit.unit_number}</td>
                                <td>{unit.floor}</td>
                                <td>{unit.section}</td>
                                <td>{unit.unit_type}</td>
                                <td>
                                    {unit.unit_id === selectedUnitId ? (
                                        <div>
                                            <select
                                                value={editShopValue}
                                                onChange={(event) => setEditShopValue(event.target.value)}
                                            >
                                                <option value="Shop 1">Shop 1</option>
                                                <option value="Shop 2">Shop 2</option>
                                                <option value="Shop 3">Shop 3</option>
                                            </select>
                                            <button onClick={handleSaveShop}>Save</button>
                                        </div>
                                    ) : (
                                        <div>
                                            {unit.shop}
                                            <button onClick={() => handleEditShop(unit.unit_id, unit.shop)}>Edit</button>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default WayternHubs;
