import React from "react";
import EntityManagement from "../../components/formTemplates/EntityManagement";
const SystemUsers = () => {
  const entityName = "System User";
  const endpoints = {
    list: "/api/system_users",
    create: "/api/system_users",
    update: "/api/system_users",
    delete: "/api/system_users",
    dropdown: {
      roles: "/api/roles",
      status: "/api/statuses",
      shop: "/api/shops",
    },
  };
  const formFields = [
    { name: "username", label: "Username", type: "text", required: true },
    { name: "password", label: "Password", type: "password", required: true },
    { name: "first_name", label: "First Name", type: "text", required: true },
    { name: "last_name", label: "Last Name", type: "text", required: true },
    { name: "user_email", label: "Email", type: "email", required: true },
    { name: "user_contact", label: "Contact", type: "text", required: true },
    { name: "status_id", label: "Status ID", type: "number", required: true },

    {
      name: "merchant_id",
      label: "Merchant",
      type: "select",
      required: true,
      dropdownKey: "merchant",
    },
    {
      name: "role_id",
      label: "Role",
      type: "select",
      required: true,
      dropdownKey: "roles",
    },
    {
      name: "shift_id",
      label: "Shift",
      type: "select",
      required: true,
      dropdownKey: "shifts",
    },
    {
      name: "profile_id",
      label: "Profile",
      type: "select",
      required: true,
      dropdownKey: "profiles",
    },
    {
      name: "service_provider_id",
      label: "Service Provider",
      type: "select",
      required: true,
      dropdownKey: "serviceProviders",
    },
    {
      name: "domain_id",
      label: "Domain",
      type: "select",
      required: true,
      dropdownKey: "domains",
    },
  ];

  const tableColumns = [
    { field: "user_id", label: "ID" },
    { field: "username", label: "Username" },
    { field: "first_name", label: "First Name" },
    { field: "last_name", label: "Last Name" },
    { field: "user_email", label: "Email" },
    { field: "user_contact", label: "Contact" },
    { field: "status_id", label: "Status ID" },

    { field: "merchant_name", label: "Merchant" },
    { field: "role_name", label: "Role" },
    { field: "shift_name", label: "Shift" },
    { field: "profile_name", label: "Profile" },
    { field: "service_provider_name", label: "Service Provider" },
    { field: "domain_name", label: "Domain" },
    { field: "actions", label: "Actions" },
  ];

  return (
    <EntityManagement
      entityName={entityName}
      endpoints={endpoints}
      formFields={formFields}
      tableColumns={tableColumns}
      showWorkdays={true}
      showWorktime={true}
    />
  );
};

export default SystemUsers;
