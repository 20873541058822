import React, { useState, useEffect } from 'react';
import '../../assets/componentsCSS/InstanceConfigurationTable.css';

const InstanceConfigurationTable = () => {
    const [selectedEstablishment, setSelectedEstablishment] = useState('');
    const [selectedCampus, setSelectedCampus] = useState('');
    const [selectedBuilding, setSelectedBuilding] = useState('');
    const [establishments, setEstablishments] = useState([]);
    const [campuses, setCampuses] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [beacons, setBeacons] = useState([]);

    useEffect(() => {
        // Fetch establishments, campuses, buildings data
        const fetchData = async () => {
            try {
                const establishmentsResponse = await fetch('/api/establishments');
                const campusesResponse = await fetch('/api/campuses');
                const buildingsResponse = await fetch('/api/buildings');
                setEstablishments(await establishmentsResponse.json());
                setCampuses(await campusesResponse.json());
                setBuildings(await buildingsResponse.json());
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (selectedCampus && selectedBuilding) {
            // Fetch beacons data based on selected campus and building
            const fetchBeacons = async () => {
                try {
                    const response = await fetch(`/api/beacons?campus_id=${selectedCampus}&building_id=${selectedBuilding}`);
                    setBeacons(await response.json());
                } catch (error) {
                    console.error('Error fetching beacons:', error);
                }
            };

            fetchBeacons();
        }
    }, [selectedCampus, selectedBuilding]);

    const handleEstablishmentChange = (event) => {
        setSelectedEstablishment(event.target.value);
        setSelectedCampus('');
        setSelectedBuilding('');
    };

    const handleCampusChange = (event) => {
        setSelectedCampus(event.target.value);
        setSelectedBuilding('');
    };

    const handleBuildingChange = (event) => {
        setSelectedBuilding(event.target.value);
    };

    return (
        <div className="instance-container">
            <h2>Instance View</h2>
            <div className="dropdown-container">
                <div className="dropdown">
                    <label>Establishment:</label>
                    <select value={selectedEstablishment} onChange={handleEstablishmentChange}>
                        <option value="">Select Establishment</option>
                        {establishments.map(est => (
                            <option key={est.est_id} value={est.est_id}>{est.est_name}</option>
                        ))}
                    </select>
                </div>
                <div className="dropdown">
                    <label>Campus:</label>
                    <select value={selectedCampus} onChange={handleCampusChange}>
                        <option value="">Select Campus</option>
                        {campuses.filter(c => c.establishid === selectedEstablishment).map(c => (
                            <option key={c.campusid} value={c.campusid}>{c.campus_name}</option>
                        ))}
                    </select>
                </div>
                <div className="dropdown">
                    <label>Building:</label>
                    <select value={selectedBuilding} onChange={handleBuildingChange}>
                        <option value="">Select Building</option>
                        {buildings.filter(b => b.campusid === selectedCampus).map(b => (
                            <option key={b.bldg_id} value={b.bldg_id}>{b.bldg_name}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Beacon Type</th>
                            <th>Floor</th>
                            <th>X</th>
                            <th>Y</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {beacons.map(beacon => (
                            <tr key={beacon.bec_id}>
                                <td>{beacon.bec_typ_id}</td>
                                <td>{beacon.bec_floor_id}</td>
                                <td>{beacon.bex_pt_map_X}</td>
                                <td>{beacon.bex_pt_map_Y}</td>
                                <td>{beacon.status_id}</td>
                                <td>Actions</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default InstanceConfigurationTable;
